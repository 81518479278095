<template>
    <div>
        <div id="context-menu" class="hidden">
            <v-card>
                <v-list style="border-radius: 5px" nav dense>
                    <v-list-item-group>
                        <v-list-item
                            :disabled="item.disabled"
                            @click="handleItemClick(item)"
                            v-for="(item, i) in items"
                            :key="i"
                            :style="item.color ? { color: item.color } : {}"
                        >
                            <v-list-item-content>
                                <v-list-item-title v-text="item.text"></v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-icon>
                                <div class="d-flex align-items-center">
                                    <font-awesome-icon :icon="item.icon"></font-awesome-icon>
                                </div>
                            </v-list-item-icon>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-card>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
    },
    methods: {
        handleOutsideClick(e) {
            var insideClick = false;

            const contextEl = document.getElementById('context-menu');

            if (contextEl) {
                if (contextEl.contains(e.target)) {
                    insideClick = true;
                }
            }

            const isOutside = !insideClick;

            if (isOutside) {
                this.hideMenu();
            }
        },
        handleCustomContext(e) {
            const contextMenu = document.getElementById('context-menu');

            if (contextMenu) {
                contextMenu.style.left = e.pageX + 'px';
                contextMenu.style.top = e.pageY + 'px';

                contextMenu.classList.remove('hidden');

                window.addEventListener('resize', e => {
                    this.hideMenu();
                });
            }
        },
        hideMenu() {
            const contextMenu = document.getElementById('context-menu');
            if (contextMenu) {
                contextMenu.classList.add('hidden');
                this.$emit('closed');

                window.removeEventListener('resize', e => {
                    this.hideMenu();
                });
            }
        },
        handleItemClick(menuItem) {
            this.$emit('item-click', menuItem);
        },
    },
    mounted() {
        const contextMenu = document.getElementById('context-menu');
        if (contextMenu) {
            // Remove context-menu from the parent container
            contextMenu.remove();

            // Add context-menu directly to the body
            document.body.appendChild(contextMenu);
        }

        document.addEventListener('click', e => {
            // this.hideMenu();
            this.handleOutsideClick(e);
        });
    },
    beforeDestroy() {
        const contextMenu = document.getElementById('context-menu');

        // Remove context-menu from the parent container
        if (contextMenu) {
            contextMenu.remove();
        }
        document.removeEventListener('click', e => {
            // this.hideMenu();
            this.handleOutsideClick(e);
        });
    },
};
</script>
<style lang="scss">
#context-menu {
    // border: 2px solid black;
    width: 200px;
    border-radius: 5px;
    position: absolute;
    z-index: 1000;
    // height: 200px;
    top: 79px;
    left: 91px;
    background-color: #fff;
}
</style>
