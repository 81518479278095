<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="events"
            :server-items-length="totalEvents"
            :loading="loading"
            :options.sync="options"
            @click:row="openDetails"
            :footer-props="{
                itemsPerPageOptions: [5, 25, 50],
            }"
            loading-text="Getting events..."
        >
            <template v-slot:item.createDate="{ item }">
                <div>{{ $moment.utc(item.createDate).local().format('MM/DD/YYYY') }}</div>
            </template>

            <template v-slot:item.funeralHomeName="{ item }">
                <v-chip v-if="item.testService" label x-small color="#3782db" dark>TEST</v-chip>
                <div>{{ item.funeralHomeName }}</div>
            </template>

            <template v-slot:item.status="{ item }">
                <v-chip dark :color="tributeStatuses[item.status].color">{{
                    tributeStatuses[item.status].label
                }}</v-chip>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { debounceV2 } from '@/utilities/debounce.js';
import { tributeStatuses } from '@/constants';

export default {
    data() {
        return {
            events: [],
            totalEvents: 0,
            loading: true,
            options: {
                itemsPerPage: 25,
                page: 1,
            },
            tributeStatuses,
            headers: [
                // { text: 'Event Id', value: 'id' },
                { text: 'Status', value: 'status' },
                { text: 'Photos', value: 'photoCount' },
                { text: 'Create Date', value: 'createDate' },
                { text: 'Funeral Home Name', value: 'funeralHomeName' },
                { text: 'Title', value: 'title' },
                // { text: 'EventType', value: 'eventType' },
            ],
        };
    },
    props: {
        search: {
            type: String,
            default: '',
        },
    },
    watch: {
        search: debounceV2(function () {
            this.getTributeEvents();
        }, 500),
        options: {
            handler() {
                if (!this.loading) {
                    this.getTributeEvents();
                }
            },
        },
    },
    methods: {
        openDetails(item) {
            this.$router.push(`/services/${item.serviceSlug}/${item.id}`);
        },
        getSortByValue(value) {
            switch (value) {
                case 'title':
                    return 'Title';
                case 'id':
                    return 'Id';
                case 'createDate':
                    return 'CreateDate';
                case 'funeralHomeName':
                    return 'FuneralHomeName';
                default:
                    console.warn('No cases found in switch statement.');
                    return '';
            }
        },
        getTributeEvents() {
            this.loading = true;

            const { sortBy, sortDesc, page, itemsPerPage } = this.options;

            var params = {
                pageSize: itemsPerPage,
                pageNumber: page ? page - 1 : 1,
                sortBy: sortBy && sortBy[0] ? this.getSortByValue(sortBy[0]) : null || null,
                sortAsc: sortBy && sortBy[0] ? !sortDesc[0] : null || null,
                search: this.search,
            };

            this.axiosInstance
                .get(`admin-tools/get-tribute-admin-table`, { params: params })
                .then(res => {
                    this.events = res.data.events;
                    this.totalEvents = res.data.total;

                    console.log(this.events, 'events');
                })
                .catch(error => {
                    console.log(error, 'error');
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
    },
    async created() {
        await this.setAuthToken();
        this.createAxiosInstance();

        this.getTributeEvents();
    },
};
</script>
<style lang="scss"></style>
