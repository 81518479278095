<template>
    <div>
        <v-slide-y-transition>
            <v-card v-show="showProgress" class="progress-snackbar">
                <v-card-text>
                    <div>
                        <div class="d-flex justify-space-between align-center text-overline">
                            <span>Progress</span>
                            <span>{{ progress }}%</span>
                        </div>
                        <v-progress-linear
                            rounded
                            v-model="progress"
                            :color="progress == 100 ? 'success' : 'primary'"
                        ></v-progress-linear>
                    </div>
                </v-card-text>
            </v-card>
        </v-slide-y-transition>
    </div>
</template>
<script>
export default {
    data() {
        return {
            showProgress: false,
        };
    },
    watch: {
        progress(newVal) {
            if (newVal === 100) {
                setTimeout(() => {
                    this.showProgress = false;
                }, 1000);
            } else {
                this.showProgress = true;
            }
        },
    },
    props: {
        progress: {
            type: Number,
            default: 0,
        },
    },
};
</script>
<style lang="scss" scoped>
.progress-snackbar {
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
    z-index: 10;
}
</style>
