<template>
    <div>
        <dashboard-modal v-if="uppy" :uppy="uppy" :props="dashboardProps" :open="isOpen" />
    </div>
</template>
<script>
import Uppy from '@uppy/core';
import { DashboardModal } from '@uppy/vue';
import UppyAddUploaderPlugin from '@/utilities/uppy-add-uploader-plugin.js';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import { log } from '@/utilities/debug-helpers';
import { mapActions } from 'vuex';
export default {
    props: {
        sasEndpoint: {
            type: String,
            required: true,
        },
        updateEndpoint: {
            type: String,
            required: true,
        },
        itemId: {
            type: Number,
            required: false,
        },
        token: {
            type: String,
            required: true,
        },
        allowedFileTypes: {
            type: Array,
            required: false,
            default: function () {
                return ['image/*', '.pdf'];
            },
        },
    },
    data() {
        return {
            uppy: null,
            isOpen: false,
            tempFiles: [],
            imgUrl: '',
            // allowedTypes: ['image/*', '.pdf'],
            dashboardProps: {
                theme: 'light',
                onRequestCloseModal: this.handleClose,
                hideUploadButton: false,
                height: 450,
                showProgressDetails: true,
                browserBackButtonClose: true,
                closeModalOnClickOutside: true,
                proudlyDisplayPoweredByUppy: false,
                // note: '1MB file size limit',
            },
        };
    },
    components: {
        DashboardModal,
    },
    watch: {
        token() {
            if (this.token) {
                this.createAxiosInstance();
            }
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
        handleOpen() {
            this.isOpen = true;
        },
        handleClose() {
            this.isOpen = false;
            this.uppy.cancelAll();
        },
        async getUploadUrl(id, file) {
            return this.axiosInstance.get(this.sasEndpoint + '/' + id + `/${file.name}`);
        },
        async uploadToEndpoint(file, endpoint, onProgressUpdate) {
            return new Promise((resolve, reject) => {
                var reader = new FileReader();
                reader.readAsArrayBuffer(file.data);
                reader.addEventListener('error', error => {});
                reader.addEventListener('load', async () => {
                    const config = {
                        onUploadProgress: onProgressUpdate,
                    };
                    try {
                        await this.axios
                            .create({
                                headers: {
                                    'Content-Type': file.type,
                                    'x-ms-blob-type': 'BlockBlob',
                                },
                            })
                            .put(endpoint, reader.result, config)
                            .then(res => {});
                        resolve('done');
                    } catch (error) {
                        console.error(error);
                        reject(error);
                    }
                });
            });
        },
        async createSong(data) {
            return this.axiosInstance.post('/TributeVideoSong', data);
        },
        updatePlayableLink(id, fileName) {
            return this.axios
                .create({
                    headers: { Authorization: `Bearer ${this.token}`, 'Content-Type': 'application/json' },
                })
                .put(process.env.VUE_APP_API + `${updateEndpoint}/${id}`, JSON.stringify(fileName))
                .then(res => {})
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        cleanFileName(file) {
            const lastDot = file.name.lastIndexOf('.');
            let cleanedName = file.name.substring(0, lastDot);
            cleanedName = cleanedName.replaceAll('_', ' ');
            return cleanedName;
        },
        updateItem(id, body) {
            return this.axios
                .create({
                    headers: { Authorization: `Bearer ${this.token}`, 'Content-Type': 'application/json' },
                })
                .put(process.env.VUE_APP_API + '/' + this.updateEndpoint + '/' + id, body);
        },
        getVideoFileDuration(file) {
            return new Promise((resolve, reject) => {
                const src = URL.createObjectURL(file.data);

                const videoElement = document.createElement('video');
                videoElement.src = src;

                videoElement.addEventListener('loadeddata', () => {
                    let duration = Math.floor(videoElement.duration);
                    return resolve(duration);
                    // The duration variable now holds the duration (in seconds) of the audio clip
                });
                videoElement.addEventListener('error', () => {
                    return reject(error);
                });
            });
        },
        async uploadFiles() {
            return new Promise(async (resolve, reject) => {
                try {
                    // Tell dashboard the the upload has started
                    this.uppy.emit('upload-start', this.tempFiles);
                    for (let i = 0; i < this.tempFiles.length; i++) {
                        let SELECTED_FILE = this.tempFiles[i];

                        var duration = 0;

                        // // Get Sas info
                        let {
                            data: { sas },
                            data: { fileName },
                        } = await this.getUploadUrl(this.itemId, SELECTED_FILE);

                        // Upload to azure storage
                        await this.uploadToEndpoint(SELECTED_FILE, sas, ev => {
                            // Emit progress update so Uppy Dashboard shows progress bar
                            this.uppy.emit('upload-progress', SELECTED_FILE, {
                                uploader: this,
                                bytesUploaded: ev.loaded,
                                bytesTotal: ev.total,
                            });
                        });

                        let updatedItem;

                        if (this.allowedFileTypes.includes('.mp4')) {
                            duration = await this.getVideoFileDuration(SELECTED_FILE);

                            let data = {
                                fileName: fileName,
                                duration: duration,
                            };
                            //update db item w/ duration
                            updatedItem = await this.updateItem(this.itemId, data);
                        } else {
                            // Upload DB Item
                            updatedItem = await this.updateItem(this.itemId, JSON.stringify(fileName));
                        }

                        const uploadResp = {
                            status: 200,
                        };

                        this.uppy.emit('upload-success', SELECTED_FILE, uploadResp);
                        this.$emit('upload-success', updatedItem.data);
                    }
                    resolve('done');
                } catch (error) {
                    reject(error);
                }
            });
        },
        initUppy() {
            this.uppy = new Uppy({
                id: 'image-uploader',
                debug: true,
                autoProceed: false,
                // logger: Uppy.debugLogger,
                allowMultipleUploadBatches: false,
                restrictions: {
                    // maxFileSize: MAX_FILE_SIZE,
                    minNumberOfFiles: 1,
                    maxNumberOfFiles: 1,
                    allowedFileTypes: this.allowedFileTypes,
                },
            })
                .use(UppyAddUploaderPlugin, {
                    uploader: fileIds => {
                        this.uploadFiles()
                            .then(res => {
                                this.handleClose();
                                this.$emit('refresh');
                                this.showSnackbar({ message: 'Upload Complete' });
                            })
                            .catch(error => {
                                this.showSnackbar({ color: 'error', message: 'An error occurred during the upload' });
                            });
                    },
                })
                .on('file-added', file => {
                    this.tempFiles = [...this.tempFiles, file];
                })
                .on('file-removed', file => {
                    this.tempFiles = this.uppy.getFiles();
                })
                .on('upload-success', (file, response) => {});
        },
    },
    async created() {
        this.createAxiosInstance();
        this.initUppy();
    },
    beforeDestroy() {
        this.uppy.close();
    },
};
</script>
<style lang=""></style>
