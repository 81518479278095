<template>
    <div>
        <div
            @contextmenu.prevent="disabled ? null : handleCustomContext($event, item)"
            @click="disabled ? null : handleGalleryItemClick($event, item)"
            @dblclick="disabled ? null : handleGalleryItemDblClick($event, item)"
            @touchstart="disabled ? null : handleTouchStart($event, item)"
            @touchend="disabled ? null : handleTouchEnd($event, item)"
            @touchmove="disabled ? null : handleTouchMove($event, item)"
            v-intersect="
                (entries, observer, isIntersecting) => handleIntersect(entries, observer, isIntersecting, item, index)
            "
            class="gallery-img-group"
        >
            <div class="gallery-img-container">
                <img
                    v-if="(item.mediaType === 0 || item.mediaType == 2) && !showOverlayImg"
                    @error="handleImgError(item)"
                    :src="handleImgSrc(item.url)"
                    style="z-index: 1; aspect-ratio: 1"
                    :id="`photo-${item.id}`"
                    class="gallery-photo"
                />

                <video
                    v-if="item.mediaType == 1"
                    class="gallery-photo gallery-video"
                    :ref="`video-${index}`"
                    :key="item.id + '-' + item.refreshKey"
                    :src="item.url"
                    :poster="handleThumbnail(item.url)"
                    @error="handlePosterRefresh(item, index)"
                ></video>

                <font-awesome-icon
                    @click.stop="disabled ? null : $emit('toggle-mute')"
                    v-if="item.mediaType == 1"
                    class="mute-status-icon"
                    :icon="item.mute ? 'fa-regular fa-volume-xmark' : 'fa-regular fa-volume'"
                ></font-awesome-icon>

                <v-chip v-if="item.mediaType == 1" class="px-2 video-tag" x-small dark color="#ab0658">VIDEO</v-chip>

                <!-- START: delete btn -->
                <v-btn
                    v-if="!disabled"
                    @click.stop="disabled ? null : deleteStep1(item)"
                    :disabled="item.loading"
                    class="delete-action-btn"
                    color="error"
                    x-small
                    fab
                >
                    <font-awesome-icon style="font-size: 0.8rem" icon="fa-regular fa-trash-can"></font-awesome-icon>
                </v-btn>
                <!-- END: delete btn -->

                <!-- START: rotate button -->
                <v-btn
                    v-if="item.mediaType != 1 && !disabled"
                    @click.stop="disabled ? null : rotateHandler(item)"
                    :disabled="loading"
                    class="rotate-action-btn"
                    x-small
                    fab
                >
                    <font-awesome-icon style="font-size: 0.8rem" icon="fa-solid fa-rotate-right"></font-awesome-icon>
                </v-btn>

                <v-progress-circular
                    v-if="loading"
                    color="primary"
                    class="corner-loader"
                    v-model="progress"
                ></v-progress-circular>
                <!-- END: rotate button -->

                <div v-if="showSyncLabel" class="item-sync-overlay">
                    <v-chip v-if="item.isDeleted" small label color="error"> Deleted</v-chip>
                    <v-chip v-else-if="item.isAdded" small label color="success"> Added</v-chip>
                    <v-chip v-else-if="item.isReordered" small label color="info"> Reordered</v-chip>
                </div>

                <!-- <v-btn
                @click="editImage(item)"
                :disabled="item.loading"
                class="quick-action-btn"
                x-small
                fab
                ><font-awesome-icon style="font-size: 0.8rem" :icon="['far', 'pencil']"
            /></v-btn> -->
                <!-- <v-btn
                v-if="handleSelected(item) == 'selected'"
                color="primary"
                class="select-photo-btn"
                x-small
                fab
                @click="selectPhoto(item)"
                ><font-awesome-icon style="font-size: 1rem" :icon="['fas', 'check']"
            /></v-btn> -->
            </div>
            <div v-if="item.name" class="filename">
                <strong class="filename__base">{{ handleBase(item.name) }}</strong>
                <strong class="filename__extension">{{ handleExtension(item.name) }}</strong>
            </div>

            <custom-tooltip
                v-if="galleryType == 'out-of-sync' && item.changeRecords.length > 0"
                :tooltipProps="{ bottom: true }"
            >
                <template v-slot:activator>
                    <p class="text-caption text-truncate">
                        User: {{ item.changeRecords[0].userEmail ? item.changeRecords[0].userEmail : 'Unknown' }}
                    </p>
                </template>

                <template v-slot:content>
                    <span>{{ item.changeRecords[0].userEmail ? item.changeRecords[0].userEmail : 'Unknown' }}</span>
                </template>
            </custom-tooltip>

            <div v-if="devMode">
                <div class="age-score">
                    <span>Id: {{ item.id }}</span>
                    <span>Order: {{ item.order }}</span>
                    <span>Age: {{ item.age }}</span>
                    <span>Score: {{ item.qualityScore }}</span>
                    <span>Source: {{ uploadSources[item.uploadSource].label }}</span>
                    <span>Name: {{ item.uploadUserName }}</span>
                    <span>Rel: {{ item.uploadUserRelationship }}</span>
                </div>

                <!-- all keywords -->
                <div class="keywords">
                    <div class="top-keywords">
                        <span style="font-size: 0.8rem; display: block">Top Keywords:</span>
                        <v-chip class="mr-1" dark x-small v-if="item.event" color="#975DBE">{{ item.event }}</v-chip>
                        <v-chip class="mr-1" dark x-small v-if="item.place" color="#009688">{{ item.place }}</v-chip>
                        <v-chip class="mr-1" dark x-small v-if="item.hobby" color="#374550">{{ item.hobby }}</v-chip>
                    </div>
                    <span style="font-size: 0.8rem; display: block">All Keywords:</span>
                    <v-chip class="mr-1" v-for="kw in item.imageKeywords" x-small color="orange" dark>{{
                        kw.name
                    }}</v-chip>
                </div>
            </div>
        </div>
        <div class="text-center" style="width: 100%; min-height: 22px; font-size: 0.8rem">
            <span v-if="!disabled" class="hidden-text">
                <font-awesome-icon icon="fa-regular fa-arrow-left"></font-awesome-icon>
                Drag to re-arrange
                <font-awesome-icon icon="fa-regular fa-arrow-right"></font-awesome-icon>
            </span>
        </div>
    </div>
</template>
<script>
import { uploadSources } from '@/constants';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';

export default {
    data() {
        return {
            uploadSources,
            loading: false,
            touchTimeout: null,
            isScrolling: false,
            touchStartX: 0,
            touchStartY: 0,
            photoRotation: 0,
            showOverlayImg: false,
            currentRotation: 0,
            nextImage: '',
            progress: 0,
            posterReloadCount: 0,
        };
    },
    components: { CustomTooltip },
    props: {
        item: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            requried: true,
        },
        devMode: {
            type: Boolean,
            default: false,
        },
        eventId: {
            type: Number,
            required: true,
        },
        token: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        showSyncLabel: {
            type: Boolean,
            default: false,
        },
        maxIndex: {
            type: Number,
            required: true,
        },
        galleryType: {
            type: String,
            required: false,
        },
    },
    watch: {
        token() {
            this.createAxiosInstance();
        },
    },
    // computed: {
    //     hasChangedSinceRender() {
    //         if (this.item.changeRecords.length > 0) {
    //             return true;
    //         } else {
    //             return false;
    //         }
    //     },
    // },
    methods: {
        rotateHandler(item) {
            if (!this.disabled) {
                this.$emit('rotate', item);
            }
        },
        handleIntersect(entries, observer, isIntersecting, item, index) {
            if (!isIntersecting) {
                return;
            }

            if (index == 0) {
                this.$emit('gallery-top-intersect');
                return;
            }
            if (index == this.maxIndex) {
                this.$emit('gallery-bottom-intersect');
                return;
            }
        },
        handleImgError(item) {
            const el = document.getElementById(`photo-${item.id}`);

            if (el) {
                el.src = item.url;
            }
        },
        rotateImage(deg) {
            if (!this.disabled) {
                var photo = this.item;
                return new Promise(async (resolve, reject) => {
                    try {
                        this.loading = true;
                        this.currentRotation = deg;
                        const img = new Image();
                        img.src = photo.url;
                        img.crossOrigin = 'anonymous';

                        img.onload = async () => {
                            const canvas = this.createCanvas(img, deg);

                            const file = await this.convertToFile(canvas, photo.name);

                            const SAS_INFO = await this.getUploadUrl(photo.name);

                            await this.uploadToEndpoint(file, SAS_INFO.sas, ev => {
                                const percentage = (ev.loaded / ev.total) * 100;
                                this.progress = percentage;
                            });

                            const replacement = await this.replacePhoto(photo.id, SAS_INFO.fileName, deg, true);

                            try {
                                await this.preloadSourceReplace(photo, replacement.url);
                            } catch (err) {
                                console.log('Error preloading image', err);
                            }

                            this.loading = false;
                            this.progress = 0;
                            resolve();
                        };

                        img.onerror = () => {
                            reject(new Error('Image failed to load'));
                        };
                    } catch (err) {
                        reject(err);
                    }
                });
            }
        },
        createCanvas(img, deg) {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            if (deg % 180 === 0) {
                canvas.width = img.width;
                canvas.height = img.height;
            } else {
                canvas.width = img.height;
                canvas.height = img.width;
            }

            ctx.translate(canvas.width / 2, canvas.height / 2);
            ctx.rotate((deg * Math.PI) / 180);
            ctx.drawImage(img, -img.width / 2, -img.height / 2);

            return canvas;
        },
        async convertToFile(canvas, name) {
            return new Promise(resolve => {
                canvas.toBlob(
                    blob => {
                        const file = new File([blob], name);
                        resolve(file);
                    },
                    'image/jpeg',
                    0.9,
                );
            });
        },
        async getUploadUrl(name) {
            return this.axiosInstance
                .get(`/TributeVideoPhoto/sas/${this.eventId}/${name}`)
                .then(response => {
                    // log('==== getUploadUrl() called ====', 'silver');

                    return response.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        async uploadToEndpoint(file, endpoint, onProgressUpdate) {
            return new Promise((resolve, reject) => {
                var reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.addEventListener('error', error => {});
                reader.addEventListener('load', async () => {
                    const config = {
                        onUploadProgress: onProgressUpdate,
                    };
                    try {
                        this.axios
                            .create({
                                headers: {
                                    'Content-Type': 'image/jpg',
                                    'x-ms-blob-type': 'BlockBlob',
                                },
                            })
                            .put(endpoint, reader.result, config)
                            .then(response => {
                                resolve('done');
                            });
                    } catch (error) {
                        console.error(error);
                        reject(error);
                    }
                });
            });
        },
        replacePhoto(id, fileName) {
            if (!this.disabled) {
                let data = {
                    fileName: fileName,
                };

                return this.axios
                    .create({
                        headers: { Authorization: `Bearer ${this.token}`, 'Content-Type': 'application/json' },
                    })
                    .put(process.env.VUE_APP_API + `/TributeVideoPhoto/replace-photo/${id}?`, data)
                    .then(res => {
                        return res.data;
                    })
                    .catch(error => {
                        console.log(error, 'error');
                    });
            }
        },
        preloadSourceReplace(photo, newUrl) {
            return new Promise((resolve, reject) => {
                const tempImage = new Image(250, 250);

                tempImage.onload = () => {
                    this.resetRotation(photo);
                    photo.url = newUrl;
                    resolve();
                };

                // Start preloading the new image in the background
                tempImage.src = this.handleImgSrc(newUrl);

                tempImage.onerror = () => {
                    //On preload error, set image source to azure link instead of imageKit transformed image
                    photo.url = newUrl;
                    reject(new Error('Image could not be preloaded'));
                };
            });
        },
        resetRotation(photo) {
            photo.currentRotation = 0;
            const el = document.getElementById(`photo-${photo.id}`);
            if (el) {
                el.style.transition = 'none';
                el.style.transform = 'none';
            }
        },
        animateRotation(photo, deg, time, elementId = `#photo-${photo.id}`) {
            if (!this.disabled) {
                var el = null;
                el = document.querySelector(elementId);

                if (el) {
                    el.style.transition = `transform ${time}s ease-in-out`;
                    el.style.transform = `rotate(${deg}deg)`;
                }
            }
        },
        deleteStep1(item) {
            if (!this.disabled) {
                this.$emit('delete', item);
            }
        },
        handleTouchStart(evt, item) {
            if (!this.disabled) {
                this.isScrolling = false;
                this.touchStartX = evt.touches[0].clientX;
                this.touchStartY = evt.touches[0].clientY;
            }
        },
        handleTouchEnd(evt, item) {
            if (!this.disabled) {
                if (!this.isScrolling) {
                    this.handleGalleryItemClick(evt, item);
                }
            }
        },
        handleTouchMove(evt, item) {
            if (!this.disabled) {
                const touchEndX = evt.touches[0].clientX;
                const touchEndY = evt.touches[0].clientY;

                if (Math.abs(touchEndX - this.touchStartX) > 10 || Math.abs(touchEndY - this.touchStartY) > 10) {
                    this.isScrolling = true;
                }
            }
        },
        handleGalleryItemClick(evt, item) {
            if (!this.disabled) {
                let data = { evt: evt, item: item };
                this.$emit('item-click', data);
            }
        },
        handleGalleryItemDblClick(evt, item) {
            if (!this.disabled) {
                let data = { evt: evt, item: item };
                this.$emit('item-dblclick', data);
            }
        },
        handleCustomContext(evt, item) {
            if (!this.disabled) {
                let data = { evt: evt, item: item };
                this.$emit('custom-context', data);
            }
        },
        handleBase(filename) {
            var splitList = filename.split('.');
            splitList.splice([splitList.length - 1], 1);
            return splitList.join(' ');
        },
        handleExtension(filename) {
            var splitList = filename.split('.');
            return '.' + splitList[splitList.length - 1];
        },
        handleImgSrc(url, lowres = false) {
            // img kit docs: https://docs.imagekit.io/features/image-transformations

            //const imgKitBase = 'https://ik.imagekit.io/memoryshare/';

            if (url) {
                let imgPath = url;

                if (url.includes('/tribute-photos/')) {
                    imgPath = url.split('/tribute-photos/')[1];
                } else if (url.includes('/original-tribute-photos/')) {
                    imgPath = url.split('/original-tribute-photos/')[1];
                }
                if (lowres) {
                    return process.env.VUE_APP_IMG_KIT_BASE + 'tr:w-50,h-50/' + imgPath;
                } else {
                    return process.env.VUE_APP_IMG_KIT_BASE + 'tr:w-250,h-250/' + imgPath;
                }
            }

            return url;
        },
        handleThumbnail(url) {
            // img kit docs: https://docs.imagekit.io/features/image-transformations

            //const imgKitBase = 'https://ik.imagekit.io/memoryshare/';

            if (url) {
                let imgPath = url;
                if (url.includes('/tribute-photos/')) {
                    imgPath = url.split('/tribute-photos/')[1];
                } else if (url.includes('/original-tribute-photos/')) {
                    imgPath = url.split('/original-tribute-photos/')[1];
                }

                return process.env.VUE_APP_IMG_KIT_BASE + imgPath + '/ik-thumbnail.jpg';
            }

            return url;
        },
        handlePosterRefresh(item, index) {
            const videoEl = this.$refs[`video-${index}`];
            const maxAttempts = 15;

            if (this.posterReloadCount < maxAttempts) {
                setTimeout(() => {
                    try {
                        // console.log(`reloading poster| ${this.posterReloadCount}`);
                        videoEl.poster = this.handleThumbnail(item.url);
                        this.posterReloadCount++;
                    } catch (error) {
                        if (error.response.data.includes('asset is too large')) {
                            const videoEl = this.$refs[`video-${index}`];
                            videoEl.removeAttribute('poster');
                            videoEl.load();
                        } else {
                            videoEl.poster = this.handleThumbnail(item.url);
                        }
                    }
                }, 1000);
            }
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
    },
    async created() {
        this.createAxiosInstance();
        this.nextImage = this.item.url;
    },
};
</script>
<style lang="scss" scoped>
.item-sync-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5px;
}
.out-of-sync-slide-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.572);
}
.filename {
    display: flex;
    min-width: 0;
    font-size: 0.8rem;
}
.filename__base {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.filename__extension {
    flex-shrink: 0;
}

.hidden {
    opacity: 0;
}

.gallery-photo {
    border-radius: 6px;
    cursor: pointer;
    width: 100%;
    height: auto;
    max-width: 200px;
    max-height: 200px;
    border: 2px solid transparent;
}

.gallery-video {
    aspect-ratio: 1;
    object-fit: cover;
}
.gallery-img-container {
    position: relative;
    overflow: hidden;
    border-radius: 5px;

    .low-res {
        filter: blur(15px);
    }
}

.edit-close-btn {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 5;
}
.edit-rotate-btn {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 5;
}
.quick-action-btn {
    position: absolute;
    z-index: 2;
    top: 10px;
    right: 10px;
    opacity: 0;
    height: 20px !important;
    width: 20px !important;
}

.rotate-action-btn {
    position: absolute;
    z-index: 2;
    bottom: 10px;
    right: 10px;
    opacity: 0;
    height: 20px !important;
    width: 20px !important;
}
.delete-action-btn {
    position: absolute;
    z-index: 2;
    top: 10px;
    right: 10px;
    opacity: 0;
    height: 20px !important;
    width: 20px !important;
}

.corner-loader {
    position: absolute;
    z-index: 2;
    bottom: 10px;
    left: 10px;
    opacity: 0.8;
    height: 20px !important;
    width: 20px !important;
}

.quick-action-btn:hover {
    opacity: 1;
}

.rotate-action-btn:hover {
    opacity: 1;
}
.delete-action-btn:hover {
    opacity: 1;
}

.gallery-photo:hover ~ .quick-action-btn {
    opacity: 0.4;
}
.gallery-photo:hover ~ .rotate-action-btn {
    opacity: 0.4;
}
.gallery-photo:hover ~ .delete-action-btn {
    opacity: 0.4;
}

.delete-action {
    color: #ff5252 !important;
    transition: 0.4s;
}
.delete-action:hover {
    background-color: #ffebee;
    transition: 0.4s;
}

.select-photo-btn {
    position: absolute;
    top: 8px;
    left: 8px;
    height: 20px !important;
    width: 20px !important;
}

.keywords {
    height: 150px;
    overflow-y: auto;
    background-color: #f8f8f8;
    border-radius: 4px;
    margin-top: 5px;
    padding: 5px;
}
.age-score {
    // border: 1px solid;
    background-color: #f8f8f8;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 5px;
    padding: 5px;
    font-size: 0.9rem;
}
.gallery-img-group {
    border: 2px solid #d3d3d300;
    padding: 4px;
}

.gallery-img-group:hover {
    border: 2px dashed #d3d3d3;
    border-radius: 5px;
    background-color: #f8f8f8;
}

.mute-status-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    font-size: 2rem;
    color: white;
}

.sortable-selected {
    // border: 2px solid red;
    .gallery-img-group {
        border: 2px solid transparent !important;
        background-color: #1876f232 !important;
        border-radius: 5px;
    }
}
.video-tag {
    position: absolute;
    top: 10px;
    left: 10px;
}
.hidden-text {
    opacity: 0;
    transition-duration: 0.3s;
}
.grid-item:hover .hidden-text {
    opacity: 1;
}

.loading-test {
    border: 2px solid red !important;
}
</style>
