<template>
    <div>
        <branded-modal @close="cancelUpload" :eager="true" max-width="700px" v-model="displayModal">
            <template v-slot:title>
                <h2>Share Your Memories</h2>
            </template>

            <template v-slot:body>
                <div class="dashboard">
                    <div v-if="showDashboard">
                        <div style="margin-bottom: 1rem" v-if="tributeVideo.id">
                            <div class="d-flex justify-content-between">
                                <div class="d-flex">
                                    <v-avatar class="mr-4" v-if="tributeVideo.mainPhotoUrl" size="70">
                                        <v-img
                                            :src="handleMainImgSrc(tributeVideo.mainPhotoUrl)"
                                            :lazy-src="handleMainImgSrc(tributeVideo.mainPhotoUrl)"
                                            alt="Avatar"
                                        />
                                    </v-avatar>

                                    <h4>{{ tributeVideo.firstName }} {{ tributeVideo.lastName }}</h4>
                                </div>
                                <div>
                                    <p style="color: #999999" class="m-0">
                                        {{ $moment(tributeVideo.birthDate).format('YYYY') }} -
                                        {{ $moment(tributeVideo.deathDate).format('YYYY') }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <strong>Photos ({{ files.length }})</strong>
                        </div>
                        <div v-if="uploadStarted" class="mb-1">
                            <span style="font-size: 0.8rem">{{ progress }}% Complete</span>
                            <v-progress-linear
                                :color="progress == 100 ? 'success' : 'primary'"
                                rounded
                                v-model="progress"
                            ></v-progress-linear>
                        </div>
                        <!-- <perfect-scrollbar
                            style="max-height: 255px; height: 100%"
                            :options="{ suppressScrollX: true, wheelPropagation: false }"
                        > -->
                        <div style="max-height: 255px; overflow-y: auto">
                            <div class="grid">
                                <div class="grid-item" v-for="(item, index) in files" :key="index">
                                    <div :id="`upload-preview-${item.id}`" class="img-wrap">
                                        <v-btn
                                            v-if="!item.progress.uploadStarted"
                                            class="img-del-btn"
                                            x-small
                                            fab
                                            @click="removeFile(item)"
                                        >
                                            <font-awesome-icon icon="fa-regular fa-x"></font-awesome-icon>
                                        </v-btn>

                                        <div v-if="uploadStarted" class="progress-overlay">
                                            <v-progress-circular
                                                v-model="item.progress.percentage"
                                                size="50"
                                                :color="item.progress.percentage == 100 ? 'success' : 'primary'"
                                            >
                                            </v-progress-circular>
                                        </div>

                                        <video
                                            v-if="item.type.includes('video')"
                                            class="gallery-video"
                                            :src="item.preview"
                                        ></video>
                                        <img
                                            v-else
                                            class="img-main"
                                            style="height: 100px; max-width: 100%"
                                            :src="item.preview"
                                        />

                                        <div v-if="item.loading" class="preview-loading-overlay">
                                            <v-progress-circular size="50" indeterminate></v-progress-circular>
                                        </div>
                                    </div>

                                    <div v-if="item.name" class="filename">
                                        <strong class="filename__base">{{ handleBase(item.name) }}</strong>
                                        <strong class="filename__extension">{{ handleExtension(item.name) }}</strong>
                                    </div>
                                    <div v-if="item.size" style="font-size: 0.8rem; color: #999">
                                        <span>{{ formatBytes(item.size) }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- </perfect-scrollbar> -->
                        <v-divider></v-divider>
                    </div>

                    <div v-show="!uploadStarted" class="mt-3 dashboard-uploader-wrap">
                        <!-- START: Uploader -->

                        <photo-uploader-v2
                            id="uploader"
                            ref="uploader"
                            :uploaderId="'modal-branded'"
                            :eventId="eventId"
                            :dashboard="true"
                            @progress="progress => handleUploadProgress(progress)"
                            @current-files="files => handleCurrentFiles(files)"
                            @file-upload-started="file => handleFileUploadStarted(file)"
                            @file-upload-progress="data => handleItemProgress(data)"
                            @all-uploads-complete="handleAllComplete"
                            @close="cancelUpload"
                            @preview-ready="data => handlePreview(data)"
                            @upload-success-result="result => handleUploadSuccessResult"
                            @upload-cancelled="handleUploadCancelled()"
                        />
                        <!-- END: Uploader -->
                    </div>

                    <div class="d-flex justify-content-center" style="margin-top: 1rem" v-if="showDashboard">
                        <v-btn @click="cancelUpload" depressed>{{ uploadComplete ? 'Close' : 'Cancel' }}</v-btn>
                        <v-btn
                            v-if="!uploadStarted"
                            :disabled="uploadStarted"
                            class="ml-2"
                            dark
                            depressed
                            color="#0c3c60"
                            @click="initUpload"
                            >Submit Photos</v-btn
                        >
                    </div>
                </div>
            </template>
        </branded-modal>
    </div>
</template>
<script>
// import TributePhotoUploader from '@/components/ManageService/Tribute/TributePhotoUploader.vue';
import PhotoUploaderV2 from '@/components/ManageService/Tribute/PhotoUploaderV2.vue';

import { mapActions } from 'vuex';
import BrandedModal from '@/components/ui/BrandedModal.vue';

export default {
    data() {
        return {
            showDashboard: false,
            progress: 0,
            loading: false,
            files: [],
            displayModal: false,
            uploadStarted: false,
            uploadComplete: false,
        };
    },
    components: {
        // TributePhotoUploader,
        PhotoUploaderV2,
        BrandedModal,
    },
    props: {
        eventId: {
            type: Number,
            required: true,
        },
    },
    computed: {
        tributeVideo() {
            return this.$store.state.tributeVideo;
        },
    },
    watch: {
        displayModal(val) {
            this.$emit('modal-visible', val);
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        handleUploadCancelled() {
            this.uploadStarted = false;
        },
        handleUploadSuccessResult(result) {
            if (result) {
                this.$emit('upload-success-result', result);
            }
        },
        handleAllComplete() {
            this.$emit('refresh-photos');
            this.showSnackbar({ message: 'Upload Complete' });
            this.cancelUpload();
        },
        handleFileUploadStarted(file) {
            const targetEl = document.getElementById(`upload-preview-${file.id}`);
            if (targetEl) {
                targetEl.scrollIntoView({ behavior: 'smooth' });
            }
        },
        handlePreview(data) {
            let found = this.files.find(x => x.id == data.file.id);

            if (found) {
                found.preview = data.preview;
                found.loading = false;
            }
        },
        setUploaderFiles(files) {
            this.displayModal = true;
            this.$refs.uploader.addFiles([...files]);
        },
        handleUploadProgress(progress) {
            this.progress = progress;
            if (this.progress > 0) {
                this.uploadStarted = true;
                this.uploadComplete = false;
            }
        },
        handleCurrentFiles(files) {
            this.files = files;
            this.showDashboard = this.files.length > 0 ? true : false;
        },
        formatBytes(bytes) {
            const kilobytes = bytes / 1024;
            const megabytes = bytes / (1024 * 1024);
            const gigabytes = bytes / (1024 * 1024 * 1024);

            if (gigabytes >= 1) {
                return gigabytes.toFixed(2) + ' GB';
            } else if (megabytes >= 1) {
                return megabytes.toFixed(2) + ' MB';
            } else if (kilobytes >= 1) {
                return kilobytes.toFixed(2) + ' KB';
            } else {
                return bytes + ' bytes';
            }
        },
        handleBase(filename) {
            var splitList = filename.split('.');
            splitList.splice([splitList.length - 1], 1);
            return splitList.join(' ');
        },
        handleExtension(filename) {
            var splitList = filename.split('.');
            return '.' + splitList[splitList.length - 1];
        },
        getFileURL(file) {
            return URL.createObjectURL(file.data);
        },
        removeFile(file) {
            this.$refs.uploader.removeFile(file);
        },
        cancelUpload() {
            this.$emit('refresh-photos');
            this.displayModal = false;
            this.files = [];
            this.$refs.uploader.reset();
            this.showDashboard = false;
            this.uploadComplete = false;
            this.uploadStarted = false;
            this.progress = 0;
        },
        initUpload() {
            this.uploadStarted = true;
            this.$refs.uploader.initDragDropUpload();
        },
        handleItemProgress(data) {
            let found = this.files.find(x => x.id == data.file.id);

            if (found) {
                found.progress = data.progress;
            }
        },
        handleMainImgSrc(url) {
            if (url) {
                const path = url.split('/tribute-photos/')[1];
                return process.env.VUE_APP_IMG_KIT_BASE + 'tr:w-500,h-500,fo-face/' + path;
            }
        },
    },
};
</script>
<style lang="scss">
.dashboard {
    .preview-loading-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background-color: #fbfbfb;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .gallery-video {
        aspect-ratio: 1;
        object-fit: cover;
        max-width: 100%;
        border-radius: 5px;
    }
    .filename {
        display: flex;
        min-width: 0;
        font-size: 0.8rem;
        max-width: 123px;
    }
    .filename__base {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .filename__extension {
        flex-shrink: 0;
    }
    #uploader {
        border-radius: 5px;
        // height: 200px;

        div.uppy-DragDrop-inner {
            padding: 0;
            display: flex;
            flex-direction: column;
        }
    }
    .dashboard-uploader-wrap {
        background-color: #f8f8f8;
        padding: 10px;
        border-radius: 5px;
        // height: 200px;
    }
    .uppy-DragDrop--isDragDropSupported {
        background-color: #fcfcfc;
        // height: 200px;
        // padding: 0;
    }
    .uppy-DragDrop--isDraggingOver {
        background-color: #ccc;
    }

    .grid {
        // max-height: 285px;
        // overflow-y: auto;
        overflow-x: hidden;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 5px;
    }
    .grid-item {
        // border: 1px solid blue;
    }

    .img-del-btn {
        position: absolute;
        top: 3px;
        right: 3px;
        z-index: 1;
        width: 20px !important;
        height: 20px !important;
        opacity: 0;
    }

    .img-wrap {
        position: relative;
        display: flex;
        max-height: 100px;
        border: none;
        background-color: #fbfbfb;
        border-radius: 5px;

        .img-main {
            border: none;
            border-radius: 5px;
            width: 100%;
            object-fit: cover;
        }
        .progress-overlay {
            background-color: rgba(0, 0, 0, 0.496);
            border-radius: 5px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 3;
        }
    }
    .img-wrap:hover .img-del-btn {
        opacity: 0.4;
    }

    .img-del-btn:hover {
        opacity: 1 !important;
    }
}
</style>
