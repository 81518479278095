<template>
    <v-card>
        <v-card-title class="text-h5"> Super Admin Service Edit </v-card-title>

        <v-card-text v-if="loaded">
            <form class="super-container" @submit.prevent="handleFormSubmission">
                <v-text-field v-model="formData.id" label="Service ID" />
                <v-text-field v-model="formData.publicId" label="Public ID" />
                <v-text-field v-model="formData.funeralHomeId" label="Funeral Home ID" />
                <v-text-field v-model="formData.ownerId" label="Owner ID" />
                <v-text-field v-model="formData.firstName" label="First Name" />
                <v-text-field v-model="formData.lastName" label="Last Name" />

                <!-- <fieldset>
                    <label class="date-label">Birth Date</label>
                    <datetime
                        placeholder="-- --"
                        type="date"
                        v-model="formData.birthDate"
                        class="theme-orange"
                        zone="local"
                        value-zone="local"
                    ></datetime>
                </fieldset> -->
                <calendar label="Birth Date" v-model="formData.birthDate" />

                <!-- <fieldset>
                    <label class="date-label">Death Date</label>
                    <datetime
                        placeholder="-- --"
                        type="date"
                        v-model="formData.deathDate"
                        class="theme-orange"
                        zone="local"
                        value-zone="local"
                    ></datetime>
                </fieldset> -->
                <calendar label="Death Date" v-model="formData.deathDate" />

                <!-- <fieldset>
                    <label class="date-label">Create Date</label>
                    <datetime
                        placeholder="-- --"
                        type="date"
                        v-model="formData.createDate"
                        class="theme-orange"
                        zone="local"
                        value-zone="local"
                    ></datetime>
                </fieldset> -->
                <calendar disabled label="Create Date" v-model="formData.createDate" />

                <!-- <fieldset>
                    <label class="date-label">Next Event Date</label>
                    <datetime
                        placeholder="-- --"
                        type="date"
                        v-model="formData.nextEventDate"
                        class="theme-orange"
                        zone="local"
                        value-zone="local"
                    ></datetime>
                </fieldset> -->
                <calendar label="Next Event Date" v-model="formData.nextEventDate" />

                <!-- <fieldset>
                    <label class="date-label">Billing Date</label>
                    <datetime
                        placeholder="-- --"
                        type="date"
                        v-model="formData.billingDate"
                        class="theme-orange"
                        zone="local"
                        value-zone="local"
                    ></datetime>
                </fieldset> -->
                <calendar label="Billing Date" v-model="formData.billingDate" />

                <v-checkbox v-model="formData.test" label="Test Service" />
                <v-checkbox v-model="formData.private" label="Private" />
                <v-checkbox v-model="formData.billingDateLocked" label="Billing Locked" />

                <v-text-field v-model="formData.totalViews" label="Total Views" />
                <v-text-field v-model="formData.liveSecondsConsumed" label="Live Seconds Consumed" />
                <v-text-field v-model="formData.pin" label="Pin" />
                <v-text-field v-model="formData.slug" label="Slug" />
                <v-text-field v-model="formData.creatorEmail" label="Creator Email" />
                <v-text-field v-model="formData.overageStreamUnitsReported" label="Overage Stream Units Reported" />
                <v-text-field v-model="formData.overageViewingUnitsReported" label="Overage Viewing Units Reported" />
                <v-text-field v-model="formData.estimateMemoryShareCost" label="Estimate Memory Share Cost" />
                <v-text-field v-model="formData.pictureURL" label="Picture URL" />

                <v-select
                    :items="serviceItems"
                    label="Service State"
                    item-text="definition"
                    item-value="value"
                    v-model="formData.serviceState"
                ></v-select>

                <v-text-field v-model="formData.liveURL" label="Live URL" />
                <v-text-field v-model="formData.currentLiveEventId" label="Currenty Live Event ID" />
                <v-text-field v-model="formData.passareCaseUuid" label="passareCaseUuid" />

                <v-btn block color="#0C3C60" dark large type="submit">Save</v-btn>
            </form>
        </v-card-text>
    </v-card>
</template>

<script>
import { serviceStates } from '@/constants';
import Calendar from '@/components/ui/Calendar.vue';

export default {
    props: {
        service: {
            type: Object,
            required: true,
        },
    },
    components: { Calendar },
    data() {
        return {
            token: null,
            formData: {},
            loaded: false,
            serviceItems: [...serviceStates],
        };
    },
    methods: {
        /**
         * Veutify automatically takes text input and converst to string. This is not ideal
         * as a number needs to be a number type, not a string. This will go through and convert
         * any string that is a valid number into a number.
         */
        convertIntObj: function (obj) {
            {
                let res = {};

                Object.keys(obj).forEach(key => {
                    if (key === 'pin' || key.includes('Name')) {
                        res[key] = obj[key];
                    } else {
                        res[key] = !isNaN(parseFloat(obj[key])) && isFinite(obj[key]) ? parseInt(obj[key]) : obj[key];
                    }
                });

                // Check Date Stuff
                res = {
                    ...res,
                    billingDate: res.billingDate.length === 0 ? '0001-01-01T00:00:00' : res.billingDate,
                };

                return res;
            }
        },
        handleFormSubmission: function () {
            let result = this.convertIntObj(this.formData);
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .put(`${process.env.VUE_APP_API}/Services/admin-edit/${this.service.id}`, result)
                .then(response => {
                    this.$emit('close');
                })
                .catch(error => {});
        },
    },
    mounted() {
        this.$auth.getIdTokenClaims().then(async result => {
            // Get Api Token
            this.token = result.__raw;

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(`${process.env.VUE_APP_API}/Services/admin-edit/${this.service.id}`)
                .then(response => {
                    let payload = response.data;

                    // Clean Payload

                    payload = {
                        ...payload,
                        billingDate:
                            response.data.billingDate === '0001-01-01T00:00:00' ? '' : response.data.billingDate,
                    };

                    this.formData = payload;
                    this.loaded = true;
                })
                .catch(error => {});
        });
    },
};
</script>

<style lang="scss" scoped>
.super-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
}
</style>
