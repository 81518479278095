<template>
    <div class="upload-page-wrapper">
        <animated-waves-background
            v-if="tribute"
            :startColor="customStartColor"
            :endColor="customEndColor"
            class="animated-bg-comp"
        />

        <div class="logo-wrap" v-if="logo">
            <img style="max-width: 100%; max-height: 100%" :src="logo" />
        </div>
        <div
            @click="handleAppRedirect()"
            v-if="os == 'Android' || (os == 'iOS' && browser != 'Safari')"
            id="download-banner"
            class="download-banner p-2 elevation-4"
        >
            <div class="d-flex">
                <img
                    style="max-width: 50px; border-radius: 5px"
                    :src="os == 'iOS' ? msScannerIconSrc : googleScannerIconSrc"
                />
                <div class="d-flex flex-column ml-2">
                    <strong>Scanner App</strong>
                    <span style="font-size: 0.9rem; color: #999999">Download the App</span>
                </div>
            </div>
            <v-btn @click="handleAppRedirect()" rounded :color="os == 'iOS' ? '#ab0658' : '#ff530d'" dark depressed
                >Open</v-btn
            >
        </div>
        <v-container fluid class="hero">
            <v-layout justify-center align-center>
                <v-flex class="card-wrap">
                    <!-- xs12 sm8 -->
                    <div v-if="tribute" class="float-card elevation-12 m-4">
                        <v-row>
                            <v-col class="tribute-header">
                                <div id="tribute-avatar" class="d-flex align-center">
                                    <v-avatar class="mr-2" v-if="tributeVideo.mainPhotoUrl" size="70">
                                        <v-img
                                            :src="handleMainImgSrc(tributeVideo.mainPhotoUrl)"
                                            :lazy-src="handleMainImgSrc(tributeVideo.mainPhotoUrl)"
                                            alt="Avatar"
                                        />
                                    </v-avatar>
                                    <div style="display: flex; flex-direction: column; justify-content: center">
                                        <h3>Tribute Video</h3>
                                        <div class="d-flex">
                                            <p
                                                v-if="tributeVideo.firstName && tributeVideo.lastName"
                                                class="m-0"
                                                style="color: #999999"
                                            >
                                                {{ tributeVideo.firstName }} {{ tributeVideo.lastName }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="tribute-tabs">
                                    <v-tabs
                                        style="width: fit-content"
                                        color="#0f578b"
                                        class="mb-1"
                                        show-arrows
                                        center-active
                                        v-model="tabIndex"
                                    >
                                        <v-tab
                                            v-for="(tab, index) in tributeTabs"
                                            :key="index"
                                            :href="'#' + tab.value"
                                            >{{ tab.label }}</v-tab
                                        >
                                    </v-tabs>
                                </div>
                            </v-col>
                            <v-col v-if="formattedDate" cols="12">
                                <div class="text-center">
                                    <p>All photos must be uploaded by</p>
                                    <h2>{{ formattedDate }}</h2>
                                </div>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>

                        <v-tabs-items style="max-width: 100%; overflow: visible" v-model="tabIndex">
                            <v-tab-item value="gallery">
                                <floating-progress :progress="uploadProgress" />
                                <div class="d-flex flex-column align-items-center">
                                    <div class="dashboard-uploader-wrap">
                                        <photo-uploader-v2
                                            :disabled="isDeadlinePassed"
                                            class="uploader"
                                            @progress="val => (uploadProgress = val)"
                                            @loading="loading = true"
                                            @refresh-photos="loading = false"
                                            @upload-success-result="result => handleUploadSuccessResult(result)"
                                            ref="familyPhotoUploader"
                                            :tributeToken="token"
                                            :eventId="eventId"
                                            :uploaderId="'family'"
                                            :requiresConfirm="render != null"
                                        >
                                        </photo-uploader-v2>
                                    </div>
                                </div>
                                <div class="pt-4 d-flex justify-space-between align-end flex-wrap">
                                    <div>
                                        <strong
                                            ><span>
                                                All Slides ({{
                                                    tributeVideo.totalPhotos ? tributeVideo.totalPhotos : '0'
                                                }})
                                            </span></strong
                                        >
                                    </div>

                                    <v-menu left attach="" offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn v-bind="attrs" v-on="on" small depressed>
                                                <font-awesome-icon icon="fa-solid fa-ellipsis-vertical" />
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item
                                                class="action-list-item d-flex justify-start"
                                                v-for="(item, index) in actionItems"
                                                :key="index"
                                                @click="item.callback"
                                            >
                                                <!-- <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-list-item-title
                                                            v-on="on"
                                                            v-bind="attrs"
                                                            class="text-left d-flex align-items-center"
                                                        >
                                                            <font-awesome-icon
                                                                class="mr-3"
                                                                style="width: 1rem; height: 1rem"
                                                                :icon="item.icon"
                                                            ></font-awesome-icon>
                                                            {{ item.label }}
                                                        </v-list-item-title>
                                                    </template>
                                                    <span>{{ item.tooltip }}</span>
                                                </v-tooltip> -->

                                                <custom-tooltip :tooltipProps="{ top: true }">
                                                    <template v-slot:activator>
                                                        <v-list-item-title class="text-left d-flex align-items-center">
                                                            <font-awesome-icon
                                                                class="mr-3"
                                                                style="width: 1rem; height: 1rem"
                                                                :icon="item.icon"
                                                            ></font-awesome-icon>
                                                            {{ item.label }}
                                                        </v-list-item-title>
                                                    </template>

                                                    <template v-slot:content>
                                                        <span>{{ item.tooltip }}</span>
                                                    </template>
                                                </custom-tooltip>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </div>

                                <div class="d-flex flex-column align-items-center">
                                    <div v-if="event && service" class="gallery-section">
                                        <overlay-drag-drop
                                            v-if="!isDeadlinePassed"
                                            ref="overlayUploader"
                                            @files-changed="files => handleOverlayFiles(files)"
                                        ></overlay-drag-drop>

                                        <TributePhotoGallery
                                            ref="gallery"
                                            :event="event"
                                            :service="service"
                                            :tributeToken="token"
                                            :scrollable="true"
                                            :mobile="os == 'Android' || os == 'iOS'"
                                        />
                                    </div>
                                </div>
                            </v-tab-item>
                            <!--
                            <v-tab-item value="stories">
                                <tribute-stories
                                    v-if="tributeVideo.storyEventId && token"
                                    :tributeToken="token"
                                    :mobile="os == 'Android' || os == 'iOS'"
                                ></tribute-stories>
                            </v-tab-item> -->

                            <!-- <v-tab-item value="templates">
                                <tribute-templates
                                    v-if="event && service && token"
                                    ref="templates"
                                    :playlistRedirect="playlistRedirect"
                                    :parentTabIndex="tabIndex"
                                    :event="event"
                                    :service="service"
                                    :token="token"
                                />
                            </v-tab-item> -->
                            <!--
                            <v-tab-item value="music">
                                <music-by-genre v-if="event && token" :token="token" :event="event"></music-by-genre>
                            </v-tab-item> -->

                            <v-tab-item v-if="render" value="video">
                                <tribute-rendered-video-tab
                                    :render="render"
                                    :name="`${tributeVideo.firstName} ${tributeVideo.lastName}`"
                                />
                            </v-tab-item>
                        </v-tabs-items>
                    </div>
                </v-flex>
            </v-layout>
        </v-container>

        <v-dialog v-model="deletePhotoModal" max-width="350px">
            <v-card>
                <v-card-title> Delete Photo? </v-card-title>
                <v-card-text>This cannot be undone</v-card-text>
                <v-card-actions style="display: flex; justify-content: space-between">
                    <v-btn @click="cancelDelete" depressed>Cancel</v-btn>
                    <v-btn @click="deletePhoto(selectedPhoto)" color="error" depressed>Confirm</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog persistent v-model="unauthorizedError" max-width="600px">
            <v-card>
                <v-card-title>Error</v-card-title>
                <v-card-text>Unable to authorize access. Please visit the upload link to gain access.</v-card-text>
            </v-card>
        </v-dialog>

        <!-- Start: Photo Preview Modal -->
        <v-dialog v-model="previewModal" max-width="800">
            <v-card dark v-if="selectedItem != null" class="image-edit-card p-2">
                <v-btn
                    @click="previewModal = false"
                    fab
                    small
                    style="position: absolute; top: 10px; left: 10px; z-index: 5"
                    ><font-awesome-icon :icon="['far', 'x']"
                /></v-btn>
                <div class="image-edit-div">
                    <div v-if="selectedItem.mediaType == 1">
                        <video
                            controls
                            style="max-width: 100%; width: 100%; margin: 0; padding: 0"
                            :src="selectedItem.url"
                        ></video>
                    </div>
                </div>
            </v-card>
        </v-dialog>
        <!-- End: Photo Preview Modal -->

        <!-- START: Imgly editor -->
        <v-dialog eager v-model="imglyModal" fullscreen>
            <v-card dark>
                <div class="text-right pa-2">
                    <v-btn @click="imglyModal = false" dark text>Close</v-btn>
                </div>
                <div v-if="showImageError" class="image-error-div">
                    <p>An error occured while loading the image.</p>
                    <v-btn @click="handleDeleteEmit(imageToEdit)" color="error">Delete Image</v-btn>
                </div>
                <div>
                    <PhotoEditor
                        ref="photoEditor"
                        :photo="imageToEdit"
                        :visible="imglyModal"
                        :theme="imglyConfig.theme"
                        :layout="imglyConfig.layout"
                        :image-path="path"
                        :blank-image="blankImage"
                        :token="token"
                        :eventId="eventId"
                        @close="closeImglyModal"
                        @export-success="replacement => handleExport(replacement)"
                        @delete="handleDeleteEmit(imageToEdit)"
                        @revert-original="handleRevertEmit(imageToEdit)"
                        @move-start="handleMoveStart()"
                        @move-end="handleMoveEnd()"
                        @image-error="showImageError = true"
                    />
                </div>
            </v-card>
        </v-dialog>
        <!-- END: Imgly editor -->

        <v-dialog v-model="displaySplash" fullscreen transition="dialog-bottom-transition">
            <v-card class="d-flex flex-column justify-center">
                <div v-if="os == 'iOS'" style="gap: 20px" class="d-flex flex-column p-3">
                    <div class="text-center">
                        <img style="max-width: 100px; border-radius: 5px" :src="msScannerIconSrc" />
                    </div>
                    <p class="text-center">
                        For an enhanced, feature-rich experience, we strongly recommend using our
                        <strong>Scanner App</strong>. However, you're welcome to continue in your browser.
                    </p>
                    <v-btn @click="handleAppRedirect()" color="#ab0658" dark x-large>Open App</v-btn>
                    <v-btn @click="displaySplash = false" x-large>Continue in browser</v-btn>
                </div>
                <div v-else style="gap: 20px" class="d-flex flex-column p-3">
                    <div class="text-center">
                        <img style="max-width: 100px; border-radius: 5px" :src="googleScannerIconSrc" />
                    </div>
                    <p class="text-center">
                        For an enhanced, feature-rich experience, we strongly recommend using a Scanner App. However,
                        you're welcome to continue in your browser.
                    </p>
                    <v-btn @click="handleAppRedirect()" dark x-large>Open App</v-btn>
                    <v-btn @click="displaySplash = false" x-large>Continue in browser</v-btn>
                </div>
            </v-card>
        </v-dialog>

        <!-- START: Download All Modal-->
        <branded-modal @close="downloadAllModal = false" max-width="400px" v-model="downloadAllModal">
            <template v-slot:title>
                <div>Download Collection</div>
            </template>

            <template v-slot:body>
                <v-card-text> This will download all {{ tributeVideo.totalPhotos + 1 }} photos. </v-card-text>
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn depressed @click="downloadAllModal = false">Cancel</v-btn>
                    <v-btn depressed @click="initCollectionDownload" dark color="#0d3d60">Download</v-btn>
                </v-card-actions>
            </template>
        </branded-modal>
        <!-- END: Download All Modal-->

        <share-upload-modal
            v-if="tributeVideo.deeplink && service && tributeVideo"
            ref="shareModal"
            :deeplink="tributeVideo.deeplink"
            :tributeVideo="tributeVideo"
            :service="service"
            @submit-invites="contacts => handleInviteSubmit(contacts)"
            @invalidEmail="email => handleInvalidEmail(email)"
        ></share-upload-modal>
    </div>
</template>
<script>
import PhotoUploaderV2 from '@/components/ManageService/Tribute/PhotoUploaderV2.vue';
import logoIconSrc from '@/assets/images/logo_title.png';
import msScannerIconSrc from '@/assets/images/msScannerIcon.png';
import googleScannerIconSrc from '@/assets/images/GoogleScannerIcon.png';
import Branch from '@/utilities/branch.js';
import Cookies from 'js-cookie';
import moment from 'moment';
import draggable from 'vuedraggable';
import PhotoEditor from '@/components/ui/PhotoEditor.vue';
import { mapActions } from 'vuex';
import TributePhotoGallery from '@/components/ManageService/Tribute/TributePhotoGallery.vue';
import OverlayDragDrop from '@/components/ui/OverlayDragDrop.vue';
import BrandedModal from '@/components/ui/BrandedModal.vue';
import ShareUploadModal from '@/components/ManageService/Tribute/ShareUploadModal.vue';
import JSZip from 'jszip';
import TributeStories from '@/components/ManageService/Tribute/TributeStories.vue';
import TributeTemplates from '@/components/ManageService/Tribute/TributeTemplates.vue';
import MusicByGenre from '@/components/ManageService/Tribute/MusicByGenre.vue';
import AnimatedWavesBackground from '@/components/ui/AnimatedWavesBackground.vue';
import { getLighterShade, getDarkerShade } from '@/utilities/color.js';
import { setDictInLocalStorage, getDictFromLocalStorage } from '@/utilities/general';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';
import TributeRenderedVideoTab from './TributeRenderedVideoTab.vue';
import { tributeChangelogActions } from '../../constants';
import FloatingProgress from '@/components/ui/FloatingProgress.vue';

export default {
    metaInfo: {
        title: 'Tribute Upload',
        meta: [
            {
                name: 'robots',
                content: 'noindex',
            },
        ],
    },
    data() {
        return {
            testMobile: false,
            displaySplash: false,
            message: 'upload page',
            eventId: 0,
            token: '',
            photos: [],
            logoIconSrc,
            totalPhotos: 0,
            pageNumber: 0,
            pageSize: 24,
            loading: false,
            tribute: null,
            awaitingPhotoLoad: false,
            selectedPhoto: null,
            deletePhotoModal: false,
            os: null,
            browser: '',
            unauthorizedError: false,
            previewModal: false,
            formattedDate: null,
            dragging: false,
            imglyModal: false,
            imglyConfig: {
                theme: 'dark',
                layout: 'advanced',
                license: '{"owner":"Imgly Inc.","version":"2.4"}',
            },
            path: '',
            blankImage: false,
            showImageError: false,
            imageToEdit: null,
            selectedItem: null,
            msScannerIconSrc,
            googleScannerIconSrc,
            appId: '1665247224',
            event: null,
            service: null,
            playlistRedirect: false,
            downloadAllModal: false,
            downloadProgress: {},
            overallProgress: 0,
            tabIndex: 'gallery',
            customStartColor: null,
            customEndColor: null,
            logo: null,
            render: null,
            uploadProgress: 0,
            actionItems: [
                {
                    label: 'Download Slides',
                    icon: 'fa-regular fa-arrow-down-to-bracket',
                    callback: this.openDownloadModal,
                    tooltip: 'Download collection',
                },
                {
                    label: 'Share',
                    icon: 'fa-regular fa-share-from-square',
                    callback: this.initShareModal,
                    tooltip: 'Share uploader link',
                },
                {
                    label: 'Add Slide',
                    icon: 'fa-regular fa-plus',
                    callback: this.initBlankImage,
                    tooltip: 'Create a Title Slide',
                },
                {
                    label: 'Upload Media',
                    icon: 'fa-regular fa-cloud-arrow-up',
                    callback: this.openFileUploader,
                    tooltip: 'Upload media',
                },
            ],
            tributeTabs: [{ label: 'Slides', value: 'gallery' }],
            guidStorageName: 'ms-upload-guids',
        };
    },
    components: {
        PhotoUploaderV2,
        draggable,
        PhotoEditor,
        TributePhotoGallery,
        OverlayDragDrop,
        JSZip,
        BrandedModal,
        ShareUploadModal,
        TributeStories,
        TributeTemplates,
        MusicByGenre,
        AnimatedWavesBackground,
        CustomTooltip,
        TributeRenderedVideoTab,
        FloatingProgress,
    },
    watch: {
        loading() {
            if (!this.loading && this.awaitingPhotoLoad) {
                this.awaitingPhotoLoad = false;
                this.nextPagePhotos();
            }
        },
    },
    computed: {
        tributeVideo() {
            return this.$store.state.tributeVideo;
        },
        totalPages() {
            return Math.ceil(this.totalPhotos / this.pageSize);
        },
        isDeadlinePassed() {
            // if (this.tribute.deadline && this.tribute.deadline != '0001-01-01T00:00:00') {
            //     return new Date() > new Date(this.tributeDeadline);
            // }
            // return false;

            if (!this.tributeVideo.deadline || this.tributeVideo.deadline === '0001-01-01T00:00:00') {
                return false;
            }

            const deadline = new Date(this.tributeVideo.deadline);

            const now = new Date();

            return now > deadline;
        },

        // photos() {
        //     return this.$store.state.tributeVideo.photos;
        // },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        ...mapActions('tributeVideo', [
            'updateTributeVideo',
            'updateTributeVideoPhotos',
            'updateTributeVideoSelectedPhotos',
            'updateTributeVideoSelectedTemplates',
            'updateTributeVideoSelectedSongs',
            'resetTributeVideoState',
        ]),
        handleUploadSuccessResult(result) {
            if (result.guid) {
                this.addGuidToLocal(result.id, result.guid);
            }

            if (this.$refs.gallery) {
                this.$refs.gallery.onboardUploadHandler();
            }
        },
        async addGuidToLocal(id, guid) {
            try {
                const uploads = (await getDictFromLocalStorage(this.guidStorageName)) || {};

                if (uploads[id] !== guid) {
                    uploads[id] = guid;
                    setDictInLocalStorage(this.guidStorageName, uploads);
                }
            } catch (error) {
                console.log(error, 'Error adding GUID to local storage.');
            }
        },
        async getLocalUploads() {
            return getDictFromLocalStorage(this.guidStorageName) || {};
        },
        async isGuidInUploadList(id, guid) {
            try {
                const uploads = (await getDictFromLocalStorage(this.guidStorageName)) || {};
                return uploads[id] === guid;
            } catch (error) {
                console.log(error, 'Error checking for GUID in local storage.');
                return false;
            }
        },
        openFileUploader() {
            if (this.$refs.familyPhotoUploader) {
                this.$refs.familyPhotoUploader.openFileSelection();
            }
        },
        initShareModal() {
            this.$refs.shareModal.displayModal = true;
        },
        handleInvalidEmail(email) {
            this.showSnackbar({ message: `${email} is not a valid email`, color: 'error' });
        },
        handleInviteSubmit(contacts) {
            let message = `${this.service.funeralHomeName} has invited you to contribute to ${this.service.firstName} ${this.service.lastName}'s tribute video.`;

            let data = {
                emailList: contacts.emailList.join(','),
                phoneList: contacts.phoneList.join(','),
                url: contacts.url,
                subject: 'Tribute Invite',
                message: message,
                buttonText: 'Click Here',
            };
            if (this.$refs.shareModal) {
                this.$refs.shareModal.reset();
            }
            this.axiosInstance
                .post(`/TributeVideoPhoto/tribute-invite/${this.tributeVideo.id}`, data)
                .then(resp => {
                    this.showSnackbar({ message: 'Invitations sent' });
                })
                .catch(err => {
                    this.showSnackbar({ message: 'Error sending invitations', color: 'error' });
                });
        },
        openDownloadModal() {
            this.downloadAllModal = true;
        },
        async initCollectionDownload() {
            const photos = await this.getCollection(this.tributeVideo.id);
            await this.downloadImages(photos);
            this.downloadProgress = {};
            this.overallProgress = 0;
        },
        getCollection(id) {
            return this.axiosInstance
                .get(`/TributeVideoPhoto/get-collection-urls/${id}`)
                .then(resp => {
                    return resp.data;
                })
                .catch(err => {
                    this.showSnackbar({ message: 'Error getting total', color: 'error' });
                });
        },
        async downloadImages(photos) {
            try {
                this.loading = true;
                this.downloadAllModal = false;
                this.showCollectionDownloadProgress = true;

                const promises = photos.map(async image => {
                    const blob = await this.getImageData(image);

                    return { ...image, blob: blob };
                });

                const res = await Promise.all(promises);

                //Download multiple as zip folder
                if (photos.length > 1) {
                    const zip = new JSZip();

                    res.forEach((image, index) => {
                        zip.file(image.name, image.blob);
                    });

                    const zipFile = await zip.generateAsync({ type: 'blob' });

                    this.downloadBlob(zipFile, `${this.service.firstName} ${this.service.lastName} Tribute Photos`);
                    this.showSnackbar({ message: 'Photos downloaded' });
                } else {
                    this.downloadBlob(res[0].blob, res[0].name);
                    this.showSnackbar({ message: 'Photo downloaded' });
                }

                // this.loading = false;
            } catch (error) {
                console.log(error, 'zip error');
                this.showSnackbar({ message: 'Error downloading images', color: 'error' });
            }

            this.showCollectionDownloadProgress = false;
        },
        getImageData(photo, onProgressUpdate) {
            return new Promise((resolve, reject) => {
                this.loading = true;
                this.downloadProgress[photo.id] = 0;

                this.axios({
                    method: 'get',
                    url: photo.url,
                    responseType: 'blob',
                    onDownloadProgress: evt => {
                        const { loaded, total } = evt;
                        this.downloadProgress[photo.id] = Math.round((loaded / total) * 100);
                        this.calculateOverallProgress();
                    },
                })
                    // .then(resp => resp.blob())
                    .then(resp => {
                        resolve(resp.data);
                    })
                    .catch(error => {
                        console.log(error, 'error');
                        this.showSnackbar({ message: 'Error downloading image', color: 'error' });
                        this.loading = false;
                        reject();
                    });
            });
        },
        calculateOverallProgress() {
            const allProgress = Object.values(this.downloadProgress);
            const totalProgress = allProgress.reduce((acc, progress) => acc + progress, 0);
            this.overallProgress = Math.round(totalProgress / Object.keys(this.downloadProgress).length);
        },
        downloadBlob(file, name) {
            const blob = window.URL.createObjectURL(file);
            const anchor = document.createElement('a');

            anchor.style.display = 'none';
            anchor.href = blob;
            anchor.download = name;
            document.body.appendChild(anchor);
            anchor.click();
            anchor.remove();
            window.URL.revokeObjectURL(blob);
        },
        handleMainImgSrc(url) {
            if (url && url.includes('/tribute-photos/')) {
                const imgPath = url.split('/tribute-photos/')[1];
                return process.env.VUE_APP_IMG_KIT_BASE + 'tr:w-500,h-500,fo-face/' + imgPath;
            }
        },
        handleOverlayFiles(files) {
            this.$refs.familyPhotoUploader.addFiles([...files]);
        },
        initBlankImage() {
            this.$refs.gallery.initBlankImage();
        },
        handleVideoPosterSrc(url) {
            // img kit docs: https://docs.imagekit.io/features/image-transformations

            //const imgKitBase = 'https://ik.imagekit.io/memoryshare/';

            if (url) {
                const imgPath = url.split('/tribute-photos/')[1];

                return process.env.VUE_APP_IMG_KIT_BASE + imgPath + '/ik-thumbnail.jpg';
            }

            return url;
        },
        handlePosterRefresh(item, index) {
            if (item.refreshKey > 5) {
                return;
            }

            setTimeout(async () => {
                try {
                    const response = await this.axios(this.handleVideoPosterSrc(item.url));

                    const videoEl = this.$refs[`video-${index}`][0];
                    videoEl.poster = this.handleVideoPosterSrc(item.url);
                    item.refreshKey++;
                } catch (error) {
                    if (error.response.data.includes('asset is too large')) {
                        const videoEl = this.$refs[`video-${index}`][0];
                        videoEl.removeAttribute('poster');
                        videoEl.load();
                    } else {
                        this.handlePosterRefresh(item, index);
                    }
                }
            }, 1000);
        },
        checkBrowser() {
            const userAgent = navigator.userAgent;

            if (/MSIE|Trident/.test(userAgent)) {
                this.browser = 'Internet Explorer';
            } else if (/Edge/.test(userAgent)) {
                this.browser = 'Edge';
            } else if (/Firefox/.test(userAgent)) {
                this.browser = 'Firefox';
            } else if (/CriOS/.test(userAgent)) {
                this.browser = 'Chrome'; // Chrome on iOS
            } else if (/Chrome/.test(userAgent)) {
                this.browser = 'Chrome';
            } else if (/Safari/.test(userAgent)) {
                this.browser = 'Safari';
            } else {
                this.browser = 'Unknown';
            }
        },
        handleUppyOpen(ref) {
            if (ref != null) {
                this.$refs[ref].openFileSelection();
            }
        },
        handleExport(replacement = null) {
            this.imglyModal = false;
            this.imageEditModal = false;
            this.imageToEdit = null;
            this.path = '';

            if (replacement) {
                var found = this.photos.find(x => x.id == replacement.id);
                if (found) {
                    found.url = replacement.url;
                    found.loading = false;
                    found.originalPhoto = replacement.originalPhoto;
                }
            }
        },
        async quickOrder(id, order) {
            let found = this.photos.find(x => x.id == id);

            if (found.order == order) {
                this.showSnackbar({ message: `Photo already at ${order == 0 ? 'end' : 'start'}` });
                return;
            }

            await this.updateOrder(id, order);
            this.getPhotos(this.eventId);
            this.imageEditModal = false;
        },
        handleMoveStart() {
            this.closeImglyModal();
            this.imageEditModal = false;
            this.quickOrder(this.imageToEdit.id, this.totalPhotos - 1);
            this.imageToEdit = null;
        },
        handleMoveEnd() {
            this.closeImglyModal();
            this.imageEditModal = false;
            this.quickOrder(this.imageToEdit.id, 0);
            this.imageToEdit = null;
        },
        async handleRevertEmit(photo) {
            await this.revertPhoto(photo);
            this.path = this.imageToEdit.url;
            this.closeImglyModal();
            this.imageEditModal = false;
        },
        revertPhoto(photo) {
            if (photo.originalPhoto == null) {
                this.showSnackbar({ message: 'No photo to revert to' });
                return;
            }

            return this.axiosInstance.post(`/TributeVideoPhoto/revert-original-photo/${photo.id}`).then(resp => {
                this.revertConfirmModal = false;

                this.imageToEdit = null;
                this.imageToEdit = { ...resp.data, currentRotation: 0 };
                let found = this.photos.find(x => x.id == this.imageToEdit.id);
                if (found) {
                    let index = this.photos.indexOf(found);
                    this.photos.splice(index, 1, this.imageToEdit);
                }

                this.showSnackbar({ message: 'Original photo restored' });
            });
        },
        handleDeleteEmit(photo) {
            this.closeImglyModal();
            this.deletePhoto(photo);
        },
        closeImglyModal() {
            this.imglyModal = false;
            this.blankImage = false;
        },
        imglyEdit(item) {
            this.path = item.url;
            this.imglyModal = true;
            this.imageEditModal = false;
            this.imageToEdit = item;
        },
        async handleChange(e) {
            let targetEl = null;
            var movedEl = e.moved.element;

            const targetOrder = this.totalPhotos - 1 - e.moved.newIndex;

            await this.updateOrder(movedEl.id, targetOrder);

            this.updatePhotoStore(0, this.pageSize);
        },
        updateOrder(id, index) {
            return this.axiosInstance.put(`TributeVideoPhoto/update-order/${id}/${index}`);
        },
        handleImgSrc(item) {
            if (item.url) {
                // img kit docs: https://docs.imagekit.io/features/image-transformations

                const imgPath = item.url.split('/tribute-photos/')[1];

                // return item.url;
                return process.env.VUE_APP_IMG_KIT_BASE + 'tr:w-250,h-250/' + imgPath;
            }
        },
        handlePreview(item) {
            this.selectedItem = item;
            this.previewModal = true;
        },
        cancelDelete() {
            this.deletePhotoModal = false;
            this.selectedPhoto = null;
        },
        deletePhotoStep1(item) {
            this.selectedPhoto = item;
            this.deletePhotoModal = true;
        },
        deletePhoto(item) {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .delete(process.env.VUE_APP_API + `/TributeVideoPhoto/${item.id}`)
                .then(res => {})
                .catch(error => {
                    console.log(error, 'error');
                })
                .finally(() => {
                    this.deletePhotoModal = false;
                    this.imageEditModal = false;
                    this.imageToEdit = null;
                });
        },
        getTribute(slug) {
            return this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/TributeVideo/shared-upload-info/${slug}`)
                .then(resp => {
                    let tributeData = {
                        ...resp.data,
                        mainPhotoUrl: resp.data.photoUrl,
                        id: resp.data.tributeVideoId,
                    };
                    this.updateTributeVideo(tributeData);

                    if (resp.data.funeralHomeBrandColor) {
                        this.setAnimatedBackgroundColors(resp.data.funeralHomeBrandColor);
                    }

                    if (resp.data.funeralHomeLogo) {
                        this.logo = resp.data.funeralHomeLogo;
                    }

                    if (resp.data.render != null) {
                        this.render = resp.data.render;
                        this.tributeTabs.unshift({ label: 'Video', value: 'video' });
                        this.tabIndex = 'video';
                    }

                    return resp.data;
                })
                .catch(err => {
                    console.log(err, 'err');
                    if (err.response.status == 401) {
                        this.unauthorizedError = true;
                    }
                    // return err;
                });
        },
        setAnimatedBackgroundColors(baseColor) {
            const darkerShade = getDarkerShade(baseColor, 0.2);

            this.customStartColor = baseColor;
            this.customEndColor = darkerShade;
        },
        getLighterShade(hexColor, blendFactor) {
            if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i.test(hexColor)) {
                throw new Error('Invalid hex color');
            }

            if (blendFactor < 0 || blendFactor > 1) {
                throw new Error('Blend factor must be between 0 and 1');
            }

            // If it's a 3-character hex code, expand it to 6 characters
            if (hexColor.length === 4) {
                hexColor = '#' + hexColor[1] + hexColor[1] + hexColor[2] + hexColor[2] + hexColor[3] + hexColor[3];
            }

            const red = parseInt(hexColor.substring(1, 3), 16);
            const green = parseInt(hexColor.substring(3, 5), 16);
            const blue = parseInt(hexColor.substring(5, 7), 16);

            // Blend the color values with white
            const blendedRed = Math.round(red + (255 - red) * blendFactor)
                .toString(16)
                .padStart(2, '0');
            const blendedGreen = Math.round(green + (255 - green) * blendFactor)
                .toString(16)
                .padStart(2, '0');
            const blendedBlue = Math.round(blue + (255 - blue) * blendFactor)
                .toString(16)
                .padStart(2, '0');

            return `#${blendedRed}${blendedGreen}${blendedBlue}`;
        },
        getService(id) {
            return this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/Services/${id}`)
                .then(resp => {
                    return resp.data;
                })
                .catch(err => {
                    console.log(err, 'err');
                    if (err.response.status == 401) {
                        this.unauthorizedError = true;
                    }
                    // return err;
                });
        },
        getEvent(id) {
            return this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/Events/${id}`)
                .then(resp => {
                    return resp.data;
                })
                .catch(err => {
                    console.log(err, 'err');
                    if (err.response.status == 401) {
                        this.unauthorizedError = true;
                    }
                    // return err;
                });
        },

        createAxiosInstance() {
            return (this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            }));
        },
        getPhotos(id, params) {
            return this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/TributeVideoPhoto/event-photos/${id}`, { params: params })
                .then(response => {
                    return response.data;
                })
                .catch(error => {
                    [console.log(error, 'error')];
                });
        },
        nextPagePhotos() {
            this.pageNumber += 1;
            this.updatePhotoStore(this.pageNumber, this.pageSize);
        },
        async updatePhotoStore(pageNum, pageSize) {
            this.loading = true;

            const result = await this.getPhotos(this.eventId, {
                pageNumber: pageNum,
                pageSize: pageSize,
            });

            const newList = result.photos.map(img => {
                return { ...img, loading: false };
            });

            if (pageNum > 0) {
                const combinedList = this.photos.concat(newList);
                this.photos = combinedList;
            } else {
                this.photos = newList;
            }

            this.totalPhotos = result.total;
            this.loading = false;
        },
        initObserver() {
            let options = {
                root: null,
                rootMargin: '0px',
                treshold: 1.0,
            };

            this.observer = new IntersectionObserver(this.handleIntersect, options);
            let target = document.querySelector('#infinite-scroll-target');
            this.observer.observe(target);
        },
        handleIntersect(entries, observer) {
            let targetEntry = entries.find(x => x.target.id == 'infinite-scroll-target');
            if (targetEntry && targetEntry?.isIntersecting) {
                if (this.totalPhotos > this.photos.length) {
                    if (this.loading) {
                        this.awaitingPhotoLoad = true;
                    } else {
                        this.nextPagePhotos();
                    }
                }
            }
        },
        async getTestToken(id) {
            return this.axiosInstance.post(`services/token-test?serviceId=${id}`).then(resp => {
                return resp.data;
            });
        },
        initBranchSession() {
            return new Promise((resolve, reject) => {
                Branch.initSession((err, data) => {
                    if (err) {
                        reject(err);
                        return;
                    }
                    if (data) {
                        resolve(data.data_parsed);
                    } else {
                        resolve(null);
                    }
                });
            });
        },
        setCookie(cookieName, value) {
            Cookies.set(cookieName, value, {
                expires: 30,
            });
        },
        getCookie(cookieName) {
            const myCookie = Cookies.get(cookieName);

            if (myCookie) {
                return myCookie;
            } else {
                return null;
            }
        },
        deleteCookie(cookieName) {
            Cookies.remove(cookieName);
        },
        removeQueryParams() {
            let url = window.location.href.split('?')[0];
            window.history.replaceState({}, document.title, url);
        },
        handleAppRedirect() {
            if (this.os == 'iOS') {
                // const appId = '1665247224';
                const appStoreUrl = `https://apps.apple.com/app/id${this.appId}`;

                window.open(appStoreUrl, '_blank');
                //window.open('https://testflight.apple.com/join/BYbAjbOd', '_blank');
            } else if (this.os == 'Android') {
                window.open('https://play.google.com/store/search?q=google+photo+scan&c=apps&hl=en_US&gl=US', '_blank');
            }
        },
        addAppleSmartBanner() {
            const metaTag = document.createElement('meta');
            metaTag.name = 'apple-itunes-app';
            metaTag.content = `app-id=${this.appId}`;
            metaTag.id = 'apple-itunes-app-meta';
            document.getElementsByTagName('head')[0].appendChild(metaTag);
        },
        removeAppleSmartbanner() {
            const metaTag = document.getElementById('apple-itunes-app-meta');
            if (metaTag) {
                document.getElementsByTagName('head')[0].removeChild(metaTag);
            }
        },
        overrideOs() {
            const osOverride = this.$route.query.os.toLowerCase();
            let os = null;
            switch (osOverride) {
                case 'macos':
                    os = 'MacOS';
                    break;
                case 'ios':
                    os = 'iOS';
                    break;
                case 'windows':
                    os = 'Windows';
                    break;
                case 'android':
                    os = 'Android';
                    break;
                case 'linux':
                    os = 'Linux';
                    break;
                default:
                    os = null;
                    break;
            }

            return os;
        },
        getOs() {
            // https://stackoverflow.com/questions/38241480/detect-macos-ios-windows-android-and-linux-os-with-js
            var userAgent = window.navigator.userAgent,
                platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
                macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
                windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
                iosPlatforms = ['iPhone', 'iPad', 'iPod'],
                os = null;

            if (macosPlatforms.indexOf(platform) !== -1) {
                os = 'MacOS';
            } else if (iosPlatforms.indexOf(platform) !== -1) {
                os = 'iOS';
            } else if (windowsPlatforms.indexOf(platform) !== -1) {
                os = 'Windows';
            } else if (/Android/.test(userAgent)) {
                os = 'Android';
            } else if (/Linux/.test(platform)) {
                os = 'Linux';
            }

            return os;
        },
        getSelectedSongs(id) {
            this.axiosInstance
                .get(`TributeVideo/selected-songs/${id}`)
                .then(resp => {
                    if (resp.data) {
                        this.updateTributeVideoSelectedSongs(resp.data);
                    }
                })
                .catch(err => {
                    console.log(err, 'template json error');
                });
        },
        getSelectedTemplates(id) {
            this.axiosInstance
                .get(`TributeVideo/selected-templates/${id}`)
                .then(resp => {
                    if (resp.data) {
                        this.updateTributeVideoSelectedTemplates(resp.data.templates);
                    }
                })
                .catch(err => {
                    console.log(err, 'template json error');
                });
        },
    },

    async mounted() {
        this.checkBrowser();
        this.addAppleSmartBanner();
        // check branch session for token first
        try {
            const deepLinkData = await this.initBranchSession();

            if (deepLinkData.memoryshare_token) {
                const token = deepLinkData.memoryshare_token;
                const path = deepLinkData.$fallback_url.split('https://develop.memoryshare.com')[1];
                this.token = token;

                let data = {
                    token: token,
                    path: path,
                };
                // overwrite cookie
                const cookie = this.getCookie('ms-upload-session');
                if (cookie) {
                    this.deleteCookie('ms-upload-session');
                }
                this.setCookie('ms-upload-session', JSON.stringify(data));

                //removes branch qury params after cookie is saved
                this.removeQueryParams();
            } else {
                console.log('no data found');
            }
        } catch (error) {
            console.log('Error initializing branch session', error);
        }

        //If no branch redirect, check for cookie

        if (!this.token) {
            const cookie = this.getCookie('ms-upload-session');
            if (cookie) {
                const parsed = JSON.parse(cookie);
                if (parsed.path == this.$route.path) {
                    this.token = parsed.token;
                }
            }
        }

        if (this.$route.query.os) {
            this.os = this.overrideOs();
        }
        if (!this.os) {
            this.os = this.getOs();
        }

        if (this.os == 'Android' || this.os == 'iOS') {
            this.displaySplash = true;
        }

        if (this.token) {
            this.createAxiosInstance();

            this.tribute = await this.getTribute(this.$route.params.slug);

            if (this.tribute) {
                this.eventId = this.tribute.eventId;

                this.event = await this.getEvent(this.eventId);
                this.service = await this.getService(this.tribute.serviceId);

                this.getSelectedSongs(this.tribute.tributeVideoId);
                this.getSelectedTemplates(this.tribute.tributeVideoId);

                if (this.tribute.deadline && this.tribute.deadline != '0001-01-01T00:00:00') {
                    let fDate = moment(this.tribute.deadline).format('MMMM Do YYYY');
                    let fTime = moment(this.tribute.deadline).format('LT');
                    this.formattedDate = `${fDate} ${fTime}`;
                }

                this.updatePhotoStore(this.pageNumber, this.pageSize);
            }

            // this.getTribute(this.$route.params.slug).then(tribute => {
            //     this.tribute = tribute;

            //     if (this.tribute) {
            //         this.eventId = this.tribute.eventId;

            //         if (this.tribute.deadline && this.tribute.deadline != '0001-01-01T00:00:00') {
            //             let fDate = moment(this.tribute.deadline).format('MMMM Do');
            //             let fTime = moment(this.tribute.deadline).format('LT');
            //             this.formattedDate = `${fTime} ${fDate}`;
            //         }

            //         this.updatePhotoStore(this.pageNumber, this.pageSize);

            //         // TODO: bring this back if not using gallery component
            //         // this.initObserver();
            //     }
            // });
        }
    },
    beforeDestroy() {
        this.removeAppleSmartbanner();
    },
};
</script>
<style lang="scss">
// .blue-background {
//     background: url('../../assets/images/animated-background-blue.svg') no-repeat 0 -16rem;
// }
.upload-page-wrapper {
    background-color: #ffffff;
    position: relative;
    max-width: 100vw;
    height: 100vh;
    overflow-x: hidden;

    .action-list-item {
        cursor: pointer;
    }

    .logo-wrap {
        border: none;
        max-width: 100px;
        max-height: 100px;
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 3;
        object-fit: contain;
        padding: 10px;
    }

    .action-list-item:hover {
        background-color: #f8f8f8;
    }

    .dashboard-uploader-wrap {
        background-color: #f8f8f8;
        padding: 10px;
        border-radius: 5px;
        width: 100%;
        max-width: 900px;
        display: flex;
        justify-content: center;
    }

    .gallery-section {
        width: 100%;
        // max-width: 1200px;
        // padding: 0 44px;
    }

    .gallery-video {
        aspect-ratio: 1;
        object-fit: cover;
        max-width: 100%;
        border-radius: 5px;
    }

    .download-banner {
        position: sticky;
        top: 0;
        z-index: 20;
        background-color: white;
        // color: white;
        display: none;
        justify-content: space-between;
        align-items: center;
    }
    .hero {
        min-width: 100%;
        // background: linear-gradient(180deg, #0d3d60 40%, #ffffff 40%);
        // border: 1px solid green;
        // background-color: #ffffff;
        padding: 60px 0;

        background-size: cover;
        background-position: center;
        position: relative;
    }

    .card-wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0 120px;
    }
    .float-card {
        border-radius: 4px;
        padding: 12px;
        background-color: white;
    }
    .card-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // border-radius: 40px;
        border-radius: 4px;
    }

    .gallery-content {
        margin-top: 20px;
        max-width: 80%;
    }
    .delete-action-btn {
        position: absolute;
        z-index: 2;
        top: 10px;
        right: 10px;
        opacity: 0;
        height: 20px !important;
        width: 20px !important;
    }

    .family-gallery-photo {
        // border-radius: 10px;
        border-radius: 4px;
        cursor: pointer;
        width: 100%;
        height: auto;
        max-width: 300px;
        max-height: 300px;
    }

    .family-gallery-photo:hover ~ .delete-action-btn {
        opacity: 0.4;
        // border: 2px solid red;
    }
    .delete-action-btn:hover {
        opacity: 1;
    }

    .photo-wrap {
        display: flex;
        justify-content: center;
        position: relative;
    }
    .family-uploader {
        border-radius: 40px;

        #drop-zone {
            border-radius: 40px;
            height: 150px;
            border: 2px dashed #dbe2e8;
        }
        #drop-zone div {
            border-radius: 40px;
        }
        .uppy-DragDrop--isDragDropSupported {
            border-radius: 40px;
        }
        .uppy-DragDrop-container {
            border-radius: 40px !important;
        }
    }

    .animated-bg-comp {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 0;
        min-width: 100%;
        max-width: 100%;
        overflow: hidden;
    }
}

@media only screen and (max-width: 1024px) {
    /* Styles for mobile devices and iPads */
    .upload-page-wrapper {
        .download-banner {
            display: flex;
        }
        .hero {
            padding-bottom: 0;
        }

        // .float-card {
        //     padding: 12px;
        // }

        .family-gallery-photo {
            max-width: 400px;
            max-height: 400px;
        }

        .card-wrap {
            padding: 0 40px;
        }
    }
}

@media only screen and (max-width: 510px) {
    .upload-page-wrapper {
        .gallery-section {
            padding: 0 16px;
        }

        .card-wrap {
            padding: 0;
        }
    }
}
</style>
