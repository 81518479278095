<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="renders"
            :options.sync="options"
            :loading="loading"
            :footer-props="{
                itemsPerPageOptions: [5, 25, 50],
            }"
            loading-text="Getting Renders..."
        >
            <!-- :search="search" -->
            <!-- <template v-slot:item.blobs="{ item }">
                        <a :href="item.blobs" :title="item.blobs">{{ item.blobs | truncate(10, '...') }}</a>
                    </template> -->
            <template v-slot:item.eventTitle="{ item }">
                <a :href="'/services/' + item.serviceSlug" :title="item.eventTitle">{{ item.eventTitle }}</a>
            </template>

            <template v-slot:item.engineType="{ item }">
                <v-chip dark :color="renderEngineTypes[item.engineType].color">
                    {{ renderEngineTypes[item.engineType].label }}
                </v-chip>
            </template>
            <template v-slot:item.renderSpeed="{ item }">
                <v-chip dark :color="renderSpeeds[item.renderSpeed].color">
                    {{ renderSpeeds[item.renderSpeed].label }}
                </v-chip>
            </template>
            <template v-slot:item.videoQueType="{ item }">
                <div v-if="item.hevc">
                    <v-badge color="#0d3d60" overlap>
                        <v-chip dark :color="renderTypes[item.videoQueType].color">
                            {{ renderTypes[item.videoQueType].label }}
                        </v-chip>
                        <template v-slot:badge>
                            <span>HEVC</span>
                        </template>
                    </v-badge>
                </div>
                <div v-else>
                    <v-chip dark :color="renderTypes[item.videoQueType].color">
                        {{ renderTypes[item.videoQueType].label }}
                    </v-chip>
                </div>
            </template>
            <template v-slot:item.videoQueStatus="{ item }">
                <span v-if="item.videoQueStatus >= 0">
                    <div class="text-center" v-if="item.videoQueStatus === 5 || item.videoQueStatus === 7">
                        <v-chip class="mt-5" dark :color="queStatusEnum[item.videoQueStatus].color">
                            {{ queStatusEnum[item.videoQueStatus].label }}
                        </v-chip>

                        <v-progress-linear class="mt-2 mb-2" rounded indeterminate></v-progress-linear>
                    </div>
                    <div v-else>
                        <v-chip dark :color="queStatusEnum[item.videoQueStatus].color">
                            {{ queStatusEnum[item.videoQueStatus].label }}
                        </v-chip>
                    </div>
                </span>
            </template>
            <template v-slot:item.createDate="{ item }">
                <span style="font-size: 0.8rem; opacity: 0.7">{{ item.createDate | dateFilter() }}</span>
                <br />
                <span style="font-size: 0.8rem; opacity: 0.7">{{ item.createDate | timeFilter() }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
                <div class="action-list">
                    <custom-tooltip :tooltipProps="{ bottom: true }">
                        <template v-slot:activator>
                            <span>
                                <font-awesome-icon
                                    icon="fa-regular fa-gear"
                                    @click="edit(item)"
                                    style="font-size: 1rem"
                                />
                            </span>
                        </template>

                        <template v-slot:content>
                            <span>Edit</span>
                        </template>
                    </custom-tooltip>

                    <custom-tooltip :tooltipProps="{ bottom: true }">
                        <template v-slot:activator>
                            <span @click="openDeleteModal(item.id)">
                                <font-awesome-icon icon="fa-regular fa-trash-can" style="font-size: 1rem" />
                            </span>
                        </template>

                        <template v-slot:content>
                            <span>Delete</span>
                        </template>
                    </custom-tooltip>

                    <v-snackbar v-model="snackbar" :timeout="2000">
                        {{ message }}
                    </v-snackbar>
                    <v-dialog v-model="deleteModal" :retain-focus="false" persistent max-width="290">
                        <v-card>
                            <v-card-title class="headline"> Are you sure you want to delete this? </v-card-title>
                            <v-card-text>{{ deleteMessage }}</v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="gdarken-1" text @click="closeDeleteModal()"> cancel </v-btn>
                                <v-btn color="red darken-1" text @click="deleteRender()"> Delete </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </div>
            </template>
        </v-data-table>

        <v-dialog v-model="createOrEditModal" persistent max-width="720">
            <v-card>
                <v-card-title class="headline pl-8">
                    <span>
                        {{ editing ? 'Edit Render' : 'Create Render' }}
                    </span>
                </v-card-title>
                <div>
                    <v-form class="form-container pt-0 pb-0 pl-8 pr-8" ref="form" lazy-validation>
                        <span>ID: {{ selectedRender.id }}</span>
                        <v-divider></v-divider>
                        <div v-if="selectedRender.blobs">
                            <div v-for="(item, index) in selectedRender.expandedBlobs" :key="index">
                                <div class="d-flex justify-space-between align-center">
                                    <div style="width: 85%">
                                        <v-text-field v-if="item.editing" v-model="item.tempUrl"></v-text-field>
                                        <a v-else :href="item.url" target="_blank">{{ item.url }}</a>
                                    </div>

                                    <div class="ml-5 d-flex" style="gap: 12px">
                                        <v-btn
                                            small
                                            depressed
                                            @click="saveBlobEdit(item)"
                                            v-if="item.editing"
                                            color="primary"
                                            >Save</v-btn
                                        >
                                        <v-btn small @click="item.editing = !item.editing" depressed>{{
                                            item.editing ? 'Cancel' : 'Edit'
                                        }}</v-btn>
                                    </div>
                                </div>

                                <v-progress-circular
                                    v-if="currentlyDownloading.includes(item)"
                                    size="15"
                                    indeterminate
                                    color="green"
                                ></v-progress-circular>

                                <v-divider v-if="index != selectedRender.expandedBlobs.length - 1"></v-divider>
                            </div>
                        </div>
                        <v-divider v-if="selectedRender.blobs"></v-divider>

                        <v-row class="mt-0">
                            <v-col class="d-flex" style="gap: 12px" cols="12" md="6">
                                <v-text-field v-if="customVM" v-model="tempVMName" label="VM Name"></v-text-field>
                                <v-select
                                    v-else
                                    v-model="selectedRender.vmName"
                                    :items="vmList"
                                    item-text="name"
                                    item-value="name"
                                    label="VM Name"
                                ></v-select>
                                <v-checkbox v-model="customVM" label="Other"></v-checkbox>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-select
                                    v-model="selectedRender.renderSpeed"
                                    :items="renderSpeeds"
                                    item-text="label"
                                    item-value="value"
                                    label="Speed"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-select
                                    v-model="selectedRender.videoQueType"
                                    :items="videoQuoteType"
                                    item-text="description"
                                    item-value="id"
                                    label="Video Queue Type"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-select
                                    v-model="selectedRender.videoQueStatus"
                                    :items="videoQueStatus"
                                    item-text="description"
                                    item-value="id"
                                    label="Video Queue Status"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field label="Start" v-model="selectedRender.start"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field label="Stop" v-model="selectedRender.stop"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-select
                                    :items="boolSelectItems"
                                    item-text="label"
                                    item-value="value"
                                    label="Has Slate"
                                    v-model="selectedRender.hasSlate"
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-form>
                </div>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="gdarken-1" text @click="cancelEdit()"> cancel </v-btn>
                    <v-btn :disabled="loading" :loading="loading" color="blue darken-1" text @click="editRender()">
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { setLocalSettings, getLocalSettings } from '@/utilities/general';
import VmMasterList from '@/components/Misc/VmMasterList.vue';
import axios from 'axios';
import moment from 'moment';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';
import { renderEngineTypes, renderSpeeds, renderTypes, queStatusEnum, boolSelectItems } from '@/constants';

export default {
    metaInfo: {
        title: 'Renders',
    },
    components: { VmMasterList, CustomTooltip },
    data() {
        return {
            renderEngineTypes,
            renderSpeeds,
            renderTypes,
            queStatusEnum,
            boolSelectItems,
            currentlyDownloading: [],
            currentOffset: null,
            fileDownloading: false,
            message: '',
            token: '',
            renders: [],
            videoQuoteType: [],
            videoQueStatus: [],
            vmList: [],
            loading: true,
            snackbar: false,
            // search: '',
            selectedRender: {},
            selectedIndex: -1,
            editing: false,
            customVM: false,
            createOrEditModal: false,
            deleteModal: false,
            deleteMessage: '',
            tempVMName: '',

            headers: [
                {
                    text: 'Render Machine',
                    value: 'vmName',
                },
                {
                    text: 'Funeral Home',
                    value: 'funeralHomeName',
                },
                {
                    text: 'Event',
                    value: 'eventTitle',
                },
                {
                    text: 'Upload Date',
                    value: 'createDate',
                },
                {
                    text: 'Engine Type',
                    value: 'engineType',
                },
                {
                    text: 'RenderSpeed',
                    value: 'renderSpeed',
                },
                {
                    text: 'Render Type',
                    value: 'videoQueType',
                },
                {
                    text: 'Status',
                    value: 'videoQueStatus',
                },
                // {
                //     text: 'Completed',
                //     value: 'completed',
                // },
                {
                    text: 'Actions',
                    value: 'actions',
                    width: '20%',
                    align: 'center',
                },
            ],
            options: {
                itemsPerPage: 25,
            },
            tableLoading: true,
        };
    },
    props: {
        engineTypes: {
            type: Array,
            default: () => [],
        },
        compressionRerenders: {
            type: Boolean,
            default: false,
        },
    },
    filters: {
        truncate: function (text, length, suffix) {
            if (text && text.length > length) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        },
        dateFilter: function (value) {
            const offset = moment(new Date()).utcOffset();
            return moment.utc(value).utcOffset(offset).format('l');
        },
        timeFilter: function (value) {
            const offset = moment(new Date()).utcOffset();
            return moment.utc(value).utcOffset(offset).format('LT');
        },
    },
    watch: {
        customVM() {
            if (this.customVM) this.tempVMName = this.selectedRender.vmName;
        },
        createOrEditModal(newVal) {
            if (!newVal) {
                this.tempVMName = '';
            } else {
                if (this.selectedRender) {
                    this.tempVMName = this.selectedRender.vmName;
                }
            }
        },
    },
    async mounted() {
        // const settings = await getLocalSettings();
        // this.search = settings.rendersTable.search || '';

        // if (settings) {
        //     this.options = {
        //         ...this.options,
        //         itemsPerPage: settings?.rendersTable.pageSize || 25,
        //     };
        // }

        if (this.$auth.role.includes('SuperAdmin')) {
            this.$auth.getIdTokenClaims().then(result => {
                this.token = result.__raw;
                this.getRenders();
                this.getVideoQuoteType();
                this.getVideoQueStatus();
                this.getVMList();
            });
        }
    },
    // watch: {
    //     options: {
    //         handler() {
    //             if (!this.loading) {
    //                 setLocalSettings('rendersTable', {
    //                     search: this.search,
    //                     page: this.options.page,
    //                     pageSize: this.options.itemsPerPage,
    //                 });

    //                 this.getRenders();
    //             }
    //         },
    //     },
    //     deep: true,
    // },
    sockets: {
        NotifyLive(data) {
            if (this.$route.name === 'Renders') {
                var renderInQ = this.renders.find(r => r.eventId == data.id);

                if (renderInQ) {
                    this.getRenders();
                } else if (data.eventStatus == 4) {
                    this.getRenders();
                }
            }
        },
        NotifyRenderStatus(data) {
            this.getRenders();
        },
    },
    methods: {
        cancelEdit() {
            this.createOrEditModal = false;
            this.customVM = false;
        },
        saveBlobEdit(item) {
            item.url = item.tempUrl;
            item.editing = false;
        },
        getVMList() {
            this.axios
                .create({
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                })
                .get(process.env.VUE_APP_API + '/render/vm-list')
                .then(response => {
                    this.vmList = response.data;
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        downloadRender(item) {
            this.currentlyDownloading.push(item);
            axios({
                url: item,
                method: 'GET',
                responseType: 'blob',
                onDownloadProgress: progressEvent => {
                    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    if (percentCompleted === 100) {
                        let filteredDownloads = this.currentlyDownloading.filter(download => download != item);
                        this.currentlyDownloading = filteredDownloads;
                    }
                },
            }).then(response => {
                var file = window.URL.createObjectURL(new Blob([response.data], { type: 'text/plain' }));
                var docUrl = document.createElement('a');

                docUrl.href = file;
                docUrl.setAttribute('download', `${item}.mp4`);
                document.body.appendChild(docUrl);
                docUrl.dispatchEvent(new MouseEvent('click', {}));
            });
        },
        getVideoQueStatus() {
            this.axios
                .create({
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                })
                .get(process.env.VUE_APP_API + `/dropdown/videoquestatus`)
                .then(response => {
                    if (response.data) {
                        this.videoQueStatus = response.data;
                    }
                })
                .catch(error => {
                    // this.errorLoading = true;
                });
        },
        getVideoQuoteType() {
            this.axios
                .create({
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                })
                .get(process.env.VUE_APP_API + `/dropdown/videoquetype`)
                .then(response => {
                    if (response.data) {
                        this.videoQuoteType = response.data;
                    }
                })
                .catch(error => {});
        },
        getRenders() {
            this.loading = true;

            let renderEndpoint = process.env.VUE_APP_API + `/render/v2`;

            if (this.engineTypes.length > 0) {
                var queryString = '';

                for (let i = 0; i < this.engineTypes.length; i++) {
                    queryString += `${i == 0 ? '' : '&'}engineTypes=${this.engineTypes[i]}`;
                }

                if (this.compressionRerenders) {
                    queryString += `&compressionRerenders=true`;
                }

                renderEndpoint += '?' + queryString;
            }

            this.axios
                .create({
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                })
                .get(renderEndpoint)
                .then(response => {
                    if (response.data) {
                        this.renders = response.data;
                        this.loading = false;
                    }
                })
                .catch(error => {
                    // this.errorLoading = true;
                })
                .then(() => {
                    this.loading = false;
                });
        },
        editRender() {
            if (this.customVM) this.selectedRender.vmName = this.tempVMName;

            let data = {
                blobs: this.selectedRender.expandedBlobs.map(blob => blob.url).join(','),
                videoQueType: this.selectedRender.videoQueType,
                videoQueStatus: this.selectedRender.videoQueStatus,
                vmName: this.selectedRender.vmName,
                renderSpeed: this.selectedRender.renderSpeed,
                start: this.selectedRender.start,
                stop: this.selectedRender.stop,
                hasSlate: this.selectedRender.hasSlate,
            };

            this.loading = true;
            this.axios
                .create({
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                })
                .put(process.env.VUE_APP_API + `/Render/${this.selectedRender.id}`, data)
                .then(response => {
                    this.createOrEditModal = false;
                    this.message = 'Render edited';

                    this.getRenders();
                })
                .catch(error => {
                    this.message = 'Error editing owner';
                })
                .then(() => {
                    this.snackbar = true;
                    this.loading = false;
                });
        },
        edit(item) {
            const vm = this.vmList.find(v => v.name == item.vmName);

            if (vm) {
                this.selectedRender.vmName == vm.name;
                this.customVM = false;
            } else {
                this.selectedRender.vmName == item.name;
                this.customVM = true;
            }

            const expandedBlobs = item.blobs.split(',').map(blob => {
                return { url: blob, tempUrl: blob, editing: false };
            });
            this.selectedRender = { ...item, expandedBlobs };

            this.editing = true;
            this.createOrEditModal = true;
            //this.selectedRender = this.selectedRender;
        },
        deleteRender() {
            this.deleteMessage = '';
            this.axios
                .create({
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                })
                .delete(process.env.VUE_APP_API + `/Render/${this.selectedId}`)
                .then(result => {
                    this.message = `${this.renders[this.selectedIndex].id} was deleted`;
                    this.getRenders();
                })
                .catch(error => {
                    this.message = `Error deleting ${this.renders[this.selectedIndex].id}. Please try again`;
                })
                .then(() => {
                    this.snackbar = true;
                });

            this.deleteModal = false;
        },
        openDeleteModal(item) {
            this.deleteMessage = `Are you sure you want to delete this?`;

            this.selectedIndex = this.renders.findIndex(i => i.id === item);
            this.deleteModal = true;
            this.selectedId = item;
        },
        closeDeleteModal() {
            this.deleteModal = false;
        },
    },
};
</script>
<style>
/* .vm-list {
    min-width: 350px;
}
@media only screen and (max-width: 768px) {
    .vm-list {
        width: 100%;
    }
} */
</style>
