<template>
    <div>
        <v-data-table
            v-model="selectedSongs"
            :headers="headers"
            :items="songs"
            :server-items-length="totalSongs"
            :loading="loading"
            :options.sync="options"
            :show-select="
                $route.name == 'EditServices' ||
                $route.name == 'TributeUploadPage' ||
                this.$route.name == 'TributeFamilyPage'
            "
            item-key="id"
            :footer-props="{
                itemsPerPageOptions: [5, 25, 50],
            }"
            loading-text="Getting songs..."
            :item-class="handleRowClass"
            @click:row="handleRowClick"
            single-expand
            :expanded.sync="expanded"
        >
            <!-- @click:row="handleRowClick" -->
            <!-- single-expand -->
            <!-- :expanded.sync="expanded" -->
            <template v-if="$route.name != 'EditServices'" v-slot:header.musicGenres="{ header }">
                <v-menu :close-on-content-click="false" :offset-y="true" :disabled="loading" @input="handleMenuToggle">
                    <template v-slot:activator="{ on: menu, attrs }">
                        <custom-tooltip :tooltipProps="{ bottom: true }">
                            <template v-slot:activator>
                                <span v-bind="attrs" v-on="{ ...menu }">
                                    <div v-if="selectedGenres.length > 0" style="font-size: 0.6rem">
                                        filters ({{ selectedGenres.length }})
                                    </div>
                                    {{ header.text }}

                                    <font-awesome-icon icon="fa-solid fa-chevron-down" />
                                </span>
                            </template>

                            <template v-slot:content>
                                <span>Select Filters</span>
                            </template>
                        </custom-tooltip>
                    </template>

                    <div class="px-1" style="background-color: white">
                        <v-autocomplete
                            style="max-width: 250px"
                            label="Genres"
                            :items="genres"
                            v-model="selectedGenres"
                            multiple
                            :disabled="loading"
                            item-text="name"
                            item-value="id"
                            :menu-props="{ top: true }"
                        >
                            <template v-slot:selection="{ item }">
                                <div>
                                    <v-chip
                                        close
                                        @click:close="removeFromList(item, selectedGenres)"
                                        dark
                                        small
                                        label
                                        color="#ff530d"
                                    >
                                        {{ item.name }}
                                    </v-chip>
                                </div>
                            </template>
                        </v-autocomplete>
                    </div>
                </v-menu>
            </template>

            <template v-if="$route.name != 'EditServices'" v-slot:header.musicKeywords="{ header }">
                <v-menu :close-on-content-click="false" :offset-y="true" :disabled="loading" @input="handleMenuToggle">
                    <template v-slot:activator="{ on: menu, attrs }">
                        <custom-tooltip :tooltipProps="{ bottom: true }">
                            <template v-slot:activator>
                                <span v-bind="attrs" v-on="{ ...menu }">
                                    <div v-if="selectedKeywords.length > 0" style="font-size: 0.6rem">
                                        filters ({{ selectedKeywords.length }})
                                    </div>
                                    {{ header.text }}

                                    <font-awesome-icon icon="fa-solid fa-chevron-down" />
                                </span>
                            </template>

                            <template v-slot:content>
                                <span>Select Filters</span>
                            </template>
                        </custom-tooltip>
                    </template>

                    <div class="px-1" style="background-color: white">
                        <v-autocomplete
                            style="max-width: 250px"
                            label="Keywords"
                            :items="keywords"
                            v-model="selectedKeywords"
                            multiple
                            :disabled="loading"
                            item-text="name"
                            item-value="id"
                            :menu-props="{ top: true }"
                        >
                            <template v-slot:selection="{ item }">
                                <div>
                                    <v-chip
                                        close
                                        @click:close="removeFromList(item, selectedKeywords)"
                                        dark
                                        small
                                        label
                                        color="#ff530d"
                                    >
                                        {{ item.name }}
                                    </v-chip>
                                </div>
                            </template>
                        </v-autocomplete>
                    </div>
                </v-menu>
            </template>

            <template v-if="$route.name != 'EditServices'" v-slot:header.uploadType="{ header }">
                <v-menu :close-on-content-click="false" :offset-y="true" :disabled="loading" @input="handleMenuToggle">
                    <template v-slot:activator="{ on: menu, attrs }">
                        <custom-tooltip :tooltipProps="{ bottom: true }">
                            <template v-slot:activator>
                                <span v-bind="attrs" v-on="{ ...menu }">
                                    <div v-if="selectedUserTypes.length > 0" style="font-size: 0.6rem">
                                        filters ({{ selectedUserTypes.length }})
                                    </div>
                                    {{ header.text }}

                                    <font-awesome-icon icon="fa-solid fa-chevron-down" />
                                </span>
                            </template>

                            <template v-slot:content>
                                <span>Select Filters</span>
                            </template>
                        </custom-tooltip>
                    </template>

                    <div class="px-1" style="background-color: white">
                        <v-autocomplete
                            style="max-width: 250px"
                            label="User Types"
                            :items="userTypes"
                            v-model="selectedUserTypes"
                            multiple
                            :disabled="loading"
                            item-text="label"
                            item-value="value"
                            :menu-props="{ top: true }"
                        >
                            <template v-slot:selection="{ item }">
                                <div>
                                    <v-chip
                                        close
                                        @click:close="removeFromList(item, selectedUserTypes)"
                                        dark
                                        small
                                        label
                                        color="#ff530d"
                                    >
                                        {{ item.label }}
                                    </v-chip>
                                </div>
                            </template>
                        </v-autocomplete>

                        <v-text-field label="Funeral Home Id" v-model="filterFhId" clearable></v-text-field>
                    </div>
                </v-menu>
            </template>

            <template v-slot:item.title="{ item }">
                <div v-if="item.userFuneralHomeId == funeralHomeId && $route.name == 'EditServices'">
                    <v-chip dark color="#005069" x-small>Custom Upload</v-chip>
                </div>
                <div v-if="item.staffFavorite">
                    <v-chip dark color="#ff530d" x-small>
                        <span> Staff Favorite </span>
                    </v-chip>
                </div>
                <span>{{ item.title }}</span>
            </template>

            <template v-slot:item.createDate="{ item }">
                <div v-if="item.createDate && item.createDate != '0001-01-01T00:00:00'">
                    {{ item.createDate | moment('l') }}
                </div>
            </template>
            <template v-slot:item.musicGenres="{ item }">
                <div>
                    <span v-for="(g, gIndex) in item.musicGenres" :key="gIndex">
                        {{ g.name }}{{ gIndex == item.musicGenres.length - 1 ? '' : ', ' }}
                    </span>
                </div>
            </template>
            <template v-slot:item.musicKeywords="{ item }">
                <div>
                    <span v-for="(k, kIndex) in item.musicKeywords" :key="kIndex">
                        {{ k.name }}{{ kIndex == item.musicKeywords.length - 1 ? '' : ', ' }}
                    </span>
                </div>
            </template>

            <template v-slot:item.artwork="{ item }">
                <div>
                    <v-img
                        class="my-1"
                        style="border-radius: 5px"
                        width="60px"
                        aspect-ratio="1"
                        v-if="item.artwork"
                        :src="item.artwork"
                        alt="Album Art"
                    />
                    <div v-else class="my-1 table-img-placeholder d-flex flex-column justify-center align-items-center">
                        <span>No</span>
                        <span>Image</span>
                    </div>
                </div>
            </template>

            <template v-slot:item.duration="{ item }">
                <div>
                    {{ item.duration | durationTimeStamp() }}
                </div>
            </template>
            <template v-slot:item.licenseConfidence="{ item }">
                <div>
                    <v-chip dark :color="licenseConfidences[item.licenseConfidence].color">{{
                        licenseConfidences[item.licenseConfidence].label
                    }}</v-chip>
                </div>
            </template>

            <template v-slot:item.uploadType="{ item }">
                <!-- <div>
                    {{ item.userFuneralHomeId }} | {{ item.userType }}
                </div> -->

                <v-chip dark small v-if="item.userType != null" :color="userTypes[item.userType].color">
                    {{ userTypes[item.userType].label }}
                    <span v-if="item.userFuneralHomeId" class="mx-1">|</span>
                    <span v-if="item.userFuneralHomeId">{{ item.userFuneralHomeId }}</span>
                </v-chip>
            </template>

            <template v-slot:item.playableLink="{ item }">
                <div style="display: flex; justify-content: center; gap: 10px" v-if="item.playableLink">
                    <!-- <tribute-music-player
                    
                        :id="'audio-' + item.id"
                        class="audioPlayer"
                        :ref="'audio-' + item.id"
                        @playing="audio => playingAudio(item, audio)"
                        :playableLink="item.playableLink"
                    /> -->

                    <font-awesome-icon
                        v-if="isPlaying && selectedAudioId == item.id"
                        class="pointer player-icon"
                        icon="fa-regular fa-circle-stop"
                        @click.stop="handlePlayerClick(item)"
                        style="font-size: 1.4rem"
                    />
                    <font-awesome-icon
                        v-else
                        class="pointer player-icon"
                        @click.stop="handlePlayerClick(item)"
                        icon="fa-regular fa-circle-play"
                        style="font-size: 1.4rem"
                    />
                </div>
            </template>

            <template v-slot:item.data-table-select="{ item, isSelected }">
                <div @click.stop="toggleSelected(item)">
                    <font-awesome-icon
                        class="pa-1"
                        style="color: #91a6b6"
                        :icon="isSelected ? 'fa-solid fa-circle' : 'fa-regular fa-circle'"
                    ></font-awesome-icon>
                </div>
            </template>

            <template v-slot:header.data-table-select="{ item, isSelected }">
                <div>
                    <!-- <v-btn v-if="selectedSongs.length > 0" @click="">Clear</v-btn> -->
                </div>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
                <td style="cursor: default" class="expanded-item" :colspan="headers.length">
                    <div style="position: relative">
                        <div
                            v-if="item.userFuneralHomeId == funeralHomeId && $route.name == 'EditServices'"
                            style="position: absolute; top: 10px; right: 0"
                        >
                            <v-btn outlined color @click="limitedSongEdit(item)" small>Edit</v-btn>
                        </div>
                        <audio-player
                            v-if="selectedAudio"
                            :source="selectedAudio.playableLink"
                            :selectedAudio="selectedAudio"
                            scrubber-only
                            play-on-mount
                            @playing="val => (isPlaying = val)"
                            ref="playerScrubber"
                        />
                    </div>
                </td>
            </template>
        </v-data-table>

        <!-- START: Edit Modal -->
        <v-dialog @click:outside="closeEditModal" v-model="editModal" max-width="500px">
            <v-card class="p-3">
                <div class="p-3">
                    <div class="d-flex mb-4">
                        <div class="mr-3" style="width: 100px; position: relative">
                            <div
                                v-if="selectedSong.artwork != null && selectedSong.artwork != ''"
                                style="position: absolute; padding-left: 9px; padding-top: 4px; z-index: 5"
                            >
                                <v-hover v-slot="{ hover }">
                                    <v-badge
                                        small
                                        :color="hover ? 'error' : 'secondary'"
                                        class="delete-btn"
                                        @click.native="deleteImage(selectedSong.id)"
                                    >
                                        <template v-slot:badge>
                                            <font-awesome-icon icon="fa-regular fa-x" />
                                        </template>
                                    </v-badge>
                                </v-hover>
                            </div>
                            <v-img
                                eager
                                id="artwork"
                                @click="imgUploadInit"
                                v-if="selectedSong.artwork != null && selectedSong.artwork != ''"
                                aspect-ratio="1"
                                style="max-width: 100%; cursor: pointer; border-radius: 5px"
                                :lazy-src="selectedSong.artwork"
                                :src="selectedSong.artwork"
                            ></v-img>
                            <div @click="imgUploadInit" class="no-image" v-if="!selectedSong.artwork">
                                <!-- <v-btn small @click="imgUploadInit">Upload</v-btn> -->
                                <span>No</span>
                                <span>Image</span>
                            </div>
                            <media-uploader
                                ref="imgUpload"
                                :token="token"
                                :sasEndpoint="'TributeVideoSong/artwork/sas'"
                                :updateEndpoint="'TributeVideoSong/artwork'"
                                :itemId="selectedSong.id"
                                @refresh="handlePhotoImageRefresh()"
                            />
                        </div>

                        <div
                            style="display: flex; flex-grow: 1; flex-direction: column; justify-content: space-between"
                        >
                            <audio
                                ref="audio-modal"
                                class="rounded-pill"
                                style="width: 100%"
                                controls
                                :src="selectedSong.playableLink"
                            ></audio>
                            <div class="mt-4 d-flex justify-space-between align-center">
                                <v-btn @click="$router.push(`/song/${selectedSong.id}`)" depressed small
                                    >Analytics</v-btn
                                >
                                <v-btn color="#ff530d" @click="toggleStaffFavorite(selectedSong)" x-small fab text>
                                    <font-awesome-icon
                                        style="font-size: 1rem"
                                        :icon="selectedSong.staffFavorite ? 'fa-solid fa-heart' : 'fa-regular fa-heart'"
                                    ></font-awesome-icon>
                                </v-btn>
                            </div>
                        </div>
                    </div>

                    <v-text-field label="Title" v-model="selectedSong.title" />

                    <v-text-field label="Artist" v-model="selectedSong.artist" />
                    <v-text-field label="Album" v-model="selectedSong.album" />

                    <v-autocomplete
                        attach
                        ref="keywordAuto"
                        label="Keywords"
                        v-model="tempKeywords"
                        :items="keywords"
                        item-text="name"
                        return-object
                        multiple
                        :menu-props="{ maxHeight: '200px', closeOnClick: true, top: true }"
                    >
                        <template v-slot:selection="{ item }">
                            <v-chip close @click:close="removeFromList(item, tempKeywords)" color="#ff530d" dark>{{
                                item.name
                            }}</v-chip>
                        </template>

                        <template v-slot:prepend-item>
                            <div class="p-2">
                                <v-btn dark @click="newKeyword" block>Add +</v-btn>
                            </div>
                        </template>
                    </v-autocomplete>

                    <v-autocomplete
                        ref="genreAuto"
                        attach
                        label="Genres"
                        :items="genres"
                        v-model="tempGenres"
                        item-text="name"
                        return-object
                        multiple
                        :menu-props="{ maxHeight: '200px', closeOnClick: true, top: true }"
                    >
                        <template v-slot:selection="{ item }">
                            <v-chip close @click:close="removeFromList(item, tempGenres)" color="#ff530d" dark>{{
                                item.name
                            }}</v-chip>
                        </template>
                        <template v-slot:prepend-item>
                            <div class="p-2">
                                <v-btn @click="newGenre" block dark>Add +</v-btn>
                            </div>
                        </template>
                    </v-autocomplete>

                    <v-autocomplete
                        attach
                        label="License Confidence"
                        v-model="selectedSong.licenseConfidence"
                        :items="licenseConfidences"
                        item-text="label"
                        item-value="value"
                    ></v-autocomplete>

                    <v-text-field hide-details readonly label="License File" v-model="selectedSong.licenseFile">
                    </v-text-field>

                    <div class="d-flex justify-space-between mt-2">
                        <div style="gap: 5px" class="d-flex">
                            <custom-tooltip :tooltipProps="{ bottom: true }">
                                <template v-slot:activator>
                                    <v-btn
                                        x-small
                                        @click.stop="handleLicenseUpload"
                                        color="primary"
                                        depressed
                                        class="pa-2 text-field-btn"
                                    >
                                        <font-awesome-icon icon="fa-regular fa-file-arrow-up"></font-awesome-icon>
                                    </v-btn>
                                </template>

                                <template v-slot:content>
                                    <span>Upload License</span>
                                </template>
                            </custom-tooltip>

                            <custom-tooltip :tooltipProps="{ bottom: true }">
                                <template v-slot:activator>
                                    <v-btn x-small depressed @click="copyToClipboard(selectedSong.licenseFile)">
                                        <font-awesome-icon
                                            icon="fa-regular fa-clone"
                                            style="font-size: 0.7rem"
                                        ></font-awesome-icon>
                                    </v-btn>
                                </template>

                                <template v-slot:content>
                                    <span>Copy Link</span>
                                </template>
                            </custom-tooltip>
                        </div>

                        <custom-tooltip :tooltipProps="{ bottom: true }">
                            <template v-slot:activator>
                                <v-btn x-small depressed color="error" @click="licenseDeleteModal = true">
                                    <font-awesome-icon
                                        icon="fa-regular fa-trash-can"
                                        style="font-size: 0.7rem"
                                    ></font-awesome-icon>
                                </v-btn>
                            </template>

                            <template v-slot:content>
                                <span>Delete License</span>
                            </template>
                        </custom-tooltip>
                    </div>

                    <media-uploader
                        ref="licenseUpload"
                        :token="token"
                        :sasEndpoint="'TributeVideoSong/song-license/sas'"
                        :updateEndpoint="'TributeVideoSong/song-license'"
                        :itemId="selectedSong.id"
                        @refresh="handlePhotoLicenseRefresh()"
                    />
                    <v-text-field label="Source" v-model="selectedSong.source"></v-text-field>

                    <v-text-field disabled label="Playable Link" v-model="selectedSong.playableLink" />

                    <v-text-field disabled label="Upload User" :value="formattedName"></v-text-field>

                    <!-- {{ selectedSong }} -->

                    <div class="pt-3" style="display: flex; justify-content: space-between">
                        <!-- <v-btn color="error" @click="deleteStep1(selectedSong)">Delete</v-btn> -->

                        <v-btn depressed class="bg-danger text-white" @click="deleteStep1(selectedSong)">
                            <v-icon left>mdi-trash-can-outline</v-icon>
                            Delete
                        </v-btn>

                        <div class="d-flex">
                            <v-btn depressed class="mr-3" @click="cancelEdit">Cancel</v-btn>
                            <v-btn
                                depressed
                                @click="updateSong(selectedSong)"
                                :disabled="loading"
                                :loading="loading"
                                :dark="!loading"
                                class="text-white bg-primary"
                                >Save</v-btn
                            >
                        </div>
                    </div>
                </div>
            </v-card>
        </v-dialog>
        <!-- END: Edit Modal -->

        <!-- START: New Genre Modal -->

        <v-dialog max-width="400px" v-model="newGenreModal">
            <v-card class="p-4" title="test">
                <v-card-title class="pl-0 pt-1">New Genre</v-card-title>
                <v-text-field label="Name" v-model="newGenreName"></v-text-field>
                <div class="text-right">
                    <v-btn @click="submitGenre" class="bg-primary text-white">Create</v-btn>
                </div>
            </v-card>
        </v-dialog>
        <!-- END: New Genre Modal -->

        <!-- START: New Keyword Modal -->
        <v-dialog max-width="400px" v-model="newKeywordModal">
            <v-card class="p-4">
                <v-card-title class="pl-0 pt-1">New Keyword</v-card-title>
                <v-text-field label="Name" v-model="newKeywordName"></v-text-field>
                <div class="text-right">
                    <v-btn @click="submitKeyword" class="bg-primary text-white">Create</v-btn>
                </div>
            </v-card>
        </v-dialog>
        <!-- END: New Keyword Modal -->

        <!-- START: Delete Modal -->
        <v-dialog v-model="deleteModal" max-width="450px">
            <v-card class="p-3">
                <v-card-title>Delete Song?</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <div style="display: flex; justify-content: space-between">
                                <span><strong>Id</strong></span>
                                <span>{{ selectedSong.id }}</span>
                            </div>
                            <div style="display: flex; justify-content: space-between">
                                <span><strong>Title</strong></span>
                                <span>{{ selectedSong.title }}</span>
                            </div>
                            <div style="display: flex; justify-content: space-between">
                                <span><strong>Album</strong></span>
                                <span>{{ selectedSong.album }}</span>
                            </div>
                            <div style="display: flex; justify-content: space-between">
                                <span><strong>Artist</strong></span>
                                <span>{{ selectedSong.artist }}</span>
                            </div>
                            <div>
                                <v-divider></v-divider>
                                <strong>Genres</strong>
                                <div>
                                    <v-chip
                                        class="mr-1"
                                        color="#ff530d"
                                        small
                                        dark
                                        v-for="g in selectedSong.musicGenres"
                                        v-bind:key="g.id"
                                        >{{ g.name }}</v-chip
                                    >
                                </div>
                            </div>
                            <v-divider></v-divider>
                            <div>
                                <strong>Keywords</strong>
                                <div>
                                    <v-chip
                                        class="mr-1"
                                        color="#ff530d"
                                        small
                                        dark
                                        v-for="k in selectedSong.musicKeywords"
                                        v-bind:key="k.id"
                                        >{{ k.name }}</v-chip
                                    >
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
                <div style="display: flex; justify-content: space-between" class="p-3">
                    <v-btn @click="cancelDelete">Cancel</v-btn>
                    <v-btn color="error" @click="confirmDelete(selectedSong.id)">Delete</v-btn>
                </div>
            </v-card>
        </v-dialog>
        <!-- END: Delete Modal -->

        <!-- START: Genre Manage Modal -->
        <v-dialog v-model="genreManageModal" max-width="500">
            <v-card>
                <v-card-title
                    ><div class="d-flex w-100 justify-content-between align-items-center">
                        <div>Genres</div>
                        <v-btn prepend-icon="mdi-plus" class="bg-primary text-white" @click="newGenre()"
                            >New Genre</v-btn
                        >
                    </div>
                </v-card-title>

                <KeywordGenreTable
                    type="genre"
                    :items="genres"
                    @delete="item => deleteGenre1(item)"
                    v-model="genreManageModal"
                    @edit="(currentGenre, newGenreName) => updateGenre(currentGenre, newGenreName)"
                    @item-image-updated="item => updateItemImage(genres, item)"
                />
            </v-card>
        </v-dialog>

        <v-dialog v-model="confirmGenreDeleteModal" max-width="450">
            <v-card>
                <v-card-title>Delete '{{ selectedGenre.name }}' genre?</v-card-title>
                <v-card-text> This will not be able to be deleted if other songs are using this keyword</v-card-text>
                <div style="display: flex; justify-content: space-between" class="p-3">
                    <v-btn depressed @click="confirmGenreDeleteModal = false">Cancel</v-btn>
                    <v-btn depressed color="error" @click="confirmDeleteGenre(selectedGenre)">Delete</v-btn>
                </div>
            </v-card>
        </v-dialog>

        <!-- END: Genre Manage Modal -->

        <!-- START: Keywords Manage Modal -->
        <v-dialog v-model="keywordManageModal" max-width="500">
            <v-card>
                <v-card-title
                    ><div class="d-flex w-100 justify-content-between align-items-center">
                        <div>Keywords</div>
                        <v-btn prepend-icon="mdi-plus" class="bg-primary text-white" @click="newKeyword()"
                            >New Keyword</v-btn
                        >
                    </div>
                </v-card-title>

                <KeywordGenreTable
                    type="keyword"
                    :items="keywords"
                    @delete="item => deleteKeyword1(item)"
                    v-model="keywordManageModal"
                    @edit="(currentKeyword, newKeywordName) => updateKeyword(currentKeyword, newKeywordName)"
                />
            </v-card>
        </v-dialog>

        <v-dialog v-model="confirmKeywordDeleteModal" max-width="450">
            <v-card>
                <v-card-title>Delete '{{ selectedKeyword.name }}' keyword?</v-card-title>
                <v-card-text> This will not be able to be deleted if other songs are using this keyword</v-card-text>
                <div style="display: flex; justify-content: space-between" class="p-3">
                    <v-btn depressed @click="confirmKeywordDeleteModal = false">Cancel</v-btn>
                    <v-btn depressed color="error" @click="confirmDeleteKeyword(selectedKeyword)">Delete</v-btn>
                </div>
            </v-card>
        </v-dialog>
        <!-- END: Keywords Manage Modal -->

        <v-dialog v-model="licenseDeleteModal" max-width="450">
            <v-card>
                <v-card-title>Delete license for {{ selectedSong.title }}?</v-card-title>
                <v-card-text> This cannot be undone, confirm to continue.</v-card-text>
                <div style="display: flex; justify-content: space-between" class="p-3">
                    <v-btn depressed @click="licenseDeleteModal = false">Cancel</v-btn>
                    <v-btn depressed color="error" @click="deleteLicense(selectedSong.id)">Delete</v-btn>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="limitedEditModal" max-width="450">
            <v-card class="p-3">
                <v-text-field label="Title" v-model="selectedSong.title"></v-text-field>
                <v-text-field label="Artist" v-model="selectedSong.artist"></v-text-field>
                <v-text-field label="Album" v-model="selectedSong.album"></v-text-field>
                <div class="d-flex justify-space-between">
                    <v-btn depressed @click="limitedEditModal = false">Cancel</v-btn>
                    <v-btn @click="submitLimitedEdit" depressed color="primary">Submit</v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { debounceV2 } from '@/utilities/debounce.js';
import TributeMusicPlayer from '@/components/ManageService/Tribute/TributeMusicPlayer.vue';
import MediaUploader from '@/components/ui/MediaUploader.vue';
import { mapActions } from 'vuex';
import moment from 'moment';
import KeywordGenreTable from './KeywordGenreTable.vue';
import AudioPlayer from '@/components/ui/AudioPlayer.vue';
import { licenseConfidences, userTypes } from '@/constants';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';

export default {
    metaInfo: {
        title: 'Tribute',
    },
    data() {
        return {
            licenseConfidences,
            userTypes,
            licenseDeleteModal: false,
            selectedAudio: null,
            songs: [],
            keywords: [],
            totalSongs: 0,
            genres: [],
            totalGenres: [],
            keywords: [],
            totalKeywords: [],
            loading: true,
            deleteModal: false,
            editModal: false,
            newGenreModal: false,
            newKeywordModal: false,
            newGenreName: '',
            newKeywordName: '',
            selectedSong: {},
            tempGenres: [],
            tempKeywords: [],
            genreManageModal: false,
            confirmGenreDeleteModal: false,
            confirmKeywordDeleteModal: false,
            keywordManageModal: false,
            selectedGenre: {},
            selectedKeyword: {},
            filterMenuOpen: false,
            selectedGenres: [],
            genreParamString: '',
            selectedKeywords: [],
            keywordParamString: '',
            filterFhId: '',
            expanded: [],
            options: {
                itemsPerPage: 25,
                page: 1,
            },
            selectedAudio: null,
            selectedUserTypes: [],
            userTypeParamString: '',
            // selectedSongs: [],
            limitedEditModal: false,
            // featuredOnly: false,
            headers: [
                // { text: 'Id', value: 'id' },
                { text: 'Selected', value: 'selected', sortable: false },
                { text: 'Audio', value: 'playableLink', sortable: false },
                { text: 'Title', value: 'title' },
                { text: 'Artist', value: 'artist' },
                { text: 'Album', value: 'album' },
                { text: 'Genres', value: 'musicGenres', sortable: false },
                { text: 'Keywords', value: 'musicKeywords', sortable: false },
                // { text: 'Create Date', value: 'createDate' },
                { text: 'Duration', value: 'duration' },
                { text: 'Audio', value: 'playableLink', align: 'center', sortable: false },
                // { text: 'Actions', value: 'actions', align: 'center' },
            ],
            isPlaying: false,
        };
    },
    components: {
        TributeMusicPlayer,
        MediaUploader,
        KeywordGenreTable,
        AudioPlayer,
        CustomTooltip,
    },
    props: {
        search: {
            type: String,
            default: '',
        },
        funeralHomeId: {
            type: Number,
            required: false,
        },
        globalSongsOnly: {
            type: Boolean,
            default: false,
        },
        userSongsOnly: {
            type: Boolean,
            default: false,
        },
        featuredOnly: {
            type: Boolean,
            default: false,
        },
        filteredGenres: {
            type: Array,
            required: false,
        },
        token: {
            type: String,
            required: true,
        },
    },
    watch: {
        options: {
            handler() {
                if (!this.loading) {
                    this.getSongs();
                }
            },
        },
        search: debounceV2(function () {
            this.getSongs();
        }, 500),
        featuredOnly() {
            this.getSongs();
        },
        filteredGenres() {
            this.selectedGenres = [...this.filteredGenres];
            this.getSongs();
        },
        token() {
            this.createAxiosInstance();
            this.getGenres();
            this.getKeywords();
            this.getSongs();
        },
        funeralHomeId() {
            this.getSongs();
        },
    },
    computed: {
        selectedSongs: {
            get() {
                return this.$store.state.tributeVideo.selectedSongs;
            },
            set(value) {},
        },
        tributeVideo: {
            get() {
                return this.$store.state.tributeVideo;
            },
        },
        formattedName() {
            return this.selectedSong.userFirstName + ' ' + this.selectedSong.userLastName;
        },

        selectedAudioId() {
            return this.selectedAudio ? this.selectedAudio.id : 0;
        },
    },
    filters: {
        durationTimeStamp(val) {
            var minutes = Math.floor(val / 60);
            var seconds = val - minutes * 60;

            function str_pad_left(string, pad, length) {
                return (new Array(length + 1).join(pad) + string).slice(-length);
            }
            const finalTime = str_pad_left(minutes, '0', 2) + ':' + str_pad_left(seconds, '0', 2);
            return finalTime;
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        ...mapActions('tributeVideo', ['updateTributeVideoSelectedSongs']),
        handlePlayerClick(item) {
            this.selectedAudio = item;
            this.expanded = [item];

            const scrubber = this.$refs.playerScrubber;
            if (scrubber) {
                scrubber.togglePlaying();
            }
        },
        updateItemImage(arr, item) {
            const found = arr.find(x => x.id == item.id);
            const index = arr.indexOf(found);
            arr[index].image = item.image;
        },
        closeEditModal() {
            this.editModal = false;
            this.selectedSong = {};
        },
        toggleStaffFavorite(song) {
            var id = song.id;
            var val = !song.staffFavorite;

            this.axiosInstance.post(`/TributeVideoSong/toggle-staff-favorite/${id}/${val}`).then(resp => {
                song.staffFavorite = resp.data.staffFavorite;
                this.getSongs();
            });
        },
        submitLimitedEdit() {
            let data = {
                title: this.selectedSong.title,
                artist: this.selectedSong.artist,
                album: this.selectedSong.album,
            };

            this.axiosInstance
                .put(`/TributeVideoSong/${this.selectedSong.id}`, data)
                .then(resp => {
                    this.showSnackbar({ message: 'Song updated' });
                    this.limitedEditModal = false;
                    this.selectedSong = {};
                })
                .catch(err => {
                    console.log(err, 'error');
                    this.showSnackbar({ message: 'Error uploading song', color: 'error' });
                });
        },
        limitedSongEdit(item) {
            this.selectedSong = item;
            this.limitedEditModal = true;
        },
        copyToClipboard(val) {
            navigator.clipboard.writeText(val);
            this.showSnackbar({ message: 'Copied to Clipboard' });
        },
        handleLicenseUpload() {
            this.$refs.licenseUpload.handleOpen();
        },
        toggleSelected(item) {
            const found = this.selectedSongs.find(x => x.id == item.id);
            var listClone = [...this.selectedSongs];
            if (found) {
                const indexToRemove = listClone.indexOf(found);
                listClone.splice(indexToRemove, 1);
                for (let i = indexToRemove; i < listClone.length; i++) {
                    listClone[i].order = i;
                }
            } else {
                listClone.push({ ...item, order: this.selectedSongs.length });
            }
            this.updateTributeVideoSelectedSongs(listClone);

            const selectedIds = listClone.map(obj => obj.id);
            this.updateSelectedSongs(this.tributeVideo.id, selectedIds);
            this.$emit('refresh-sync');
        },
        updateSelectedSongs(id, ids) {
            let data = {
                selectedSongs: ids,
            };

            this.axiosInstance
                .post(`TributeVideo/selected-songs/${id}`, data)
                .then(resp => {})
                .catch(err => {
                    console.log(err, 'template json error');
                });
        },
        checkExpanded(item) {
            let found = this.expanded.find(x => x.id == item.id);
            if (found) {
                return true;
            }
            return false;
        },
        handleRowClass(item) {
            if (
                this.$route.name == 'EditServices' ||
                this.$route.name == 'TributeUploadPage' ||
                this.$route.name == 'TributeFamilyPage'
            ) {
                const foundExp = this.expanded.find(x => x.id == item.id);
                if (foundExp) {
                    return 'service-music-row-expanded';
                }

                const foundSel = this.selectedSongs.find(x => x.id == item.id);
                if (foundSel) {
                    return 'service-music-row-selected';
                }
                return 'service-music-row';
            }
        },
        checkSelectedSong(item) {
            const found = this.selectedSongs.find(x => x.id == item.id);
            if (found) {
                return true;
            } else {
                return false;
            }
        },
        imgUploadInit() {
            this.$refs.imgUpload.handleOpen();
        },
        deleteImage(id) {
            const fileName = '';
            this.axios
                .create({
                    headers: { Authorization: `Bearer ${this.token}`, 'Content-Type': 'application/json' },
                })
                .put(process.env.VUE_APP_API + '/TributeVideoSong/artwork/' + id, JSON.stringify(fileName))
                .then(res => {
                    this.selectedSong.artwork = null;
                    this.getSongs();
                });
        },
        deleteLicense(id) {
            const fileName = '';
            this.axios
                .create({
                    headers: { Authorization: `Bearer ${this.token}`, 'Content-Type': 'application/json' },
                })
                .put(process.env.VUE_APP_API + '/TributeVideoSong/song-license/' + id, JSON.stringify(fileName))
                .then(res => {
                    this.selectedSong.licenseFile = null;
                    this.licenseDeleteModal = false;
                    this.showSnackbar({ message: 'License deleted' });
                    this.getSongs();
                });
        },
        async handlePhotoImageRefresh() {
            await this.getSongs();

            // When the artwork for a song is updated, update the selected song's artwork.
            // Do not update anything else as there might be unsaved changes to the selected song (title, artist, etc.)
            if (this.selectedSong && this.selectedSong.id) {
                const updatedSong = this.songs.find(song => song.id === this.selectedSong.id);

                if (updatedSong && updatedSong.artwork) {
                    this.selectedSong.artwork = updatedSong.artwork;
                }
            }
        },
        async handlePhotoLicenseRefresh() {
            await this.getSongs();

            // When the artwork for a song is updated, update the selected song's artwork.
            // Do not update anything else as there might be unsaved changes to the selected song (title, artist, etc.)
            if (this.selectedSong && this.selectedSong.id) {
                const updatedSong = this.songs.find(song => song.id === this.selectedSong.id);

                if (updatedSong) {
                    this.selectedSong.licenseFile = updatedSong.licenseFile;
                }
            }
        },
        generateParamString(selectedItems, paramName) {
            let string = selectedItems.map(item => `&${paramName}=${item}`).join('');
            this[`${paramName}ParamString`] = string;
        },
        handleMenuToggle() {
            if (this.filterMenuOpen === false) {
                this.filterMenuOpen = true;
            } else if (this.filterMenuOpen === true) {
                this.getSongs();
                this.filterMenuOpen = false;
            }
        },

        confirmDeleteKeyword() {
            this.axiosInstance
                .delete(`/MusicKeyword/${this.selectedKeyword.id}`)
                .then(res => {
                    this.confirmKeywordDeleteModal = false;
                    this.selectedKeyword = {};
                    this.getKeywords();
                })
                .catch(error => {
                    this.showSnackbar({ color: 'red', message: error.response.data.Message });
                });
        },
        deleteKeyword1(item) {
            this.confirmKeywordDeleteModal = true;
            this.selectedKeyword = item;
        },
        confirmDeleteGenre() {
            this.axiosInstance
                .delete(`/MusicGenre/${this.selectedGenre.id}`)
                .then(res => {
                    this.confirmGenreDeleteModal = false;
                    this.selectedGenre = {};
                    this.getGenres();
                })
                .catch(error => {
                    this.showSnackbar({ color: 'red', message: error.response.data.Message });
                });
        },
        updateGenre(currentGenre, newGenreName) {
            // Update everything instantly on the frontend

            // Find currentGenre in genres and update it
            const found = this.genres.find(x => x.id == currentGenre.id);
            const index = this.genres.indexOf(found);
            this.genres[index].name = newGenreName;

            // Also update all songs with the new genre name
            this.songs.forEach(song => {
                const found = song.musicGenres.find(x => x.id == currentGenre.id);
                const index = song.musicGenres.indexOf(found);

                if (song.musicGenres[index] && song.musicGenres[index].name) {
                    song.musicGenres[index].name = newGenreName;
                }
            });

            // Update the backend
            let data = {
                name: newGenreName,
            };

            this.axiosInstance
                .put(`/MusicGenre/${currentGenre.id}`, data)
                .then(res => {
                    this.getGenres();
                })
                .catch(error => {
                    this.showSnackbar({ color: 'red', message: error.response.data.Message });
                });
        },
        updateKeyword(currentKeyword, newKeywordName) {
            // Find currentGenre in genres and update it
            const found = this.keywords.find(x => x.id == currentKeyword.id);
            const index = this.keywords.indexOf(found);
            this.keywords[index].name = newKeywordName;

            // Also update all songs with the new genre name
            this.songs.forEach(song => {
                const found = song.musicKeywords.find(x => x.id == currentKeyword.id);
                const index = song.musicKeywords.indexOf(found);

                if (song.musicKeywords[index] && song.musicKeywords[index].name) {
                    song.musicKeywords[index].name = newKeywordName;
                }
            });

            // Update the backend
            let data = {
                name: newKeywordName,
            };

            this.axiosInstance
                .put(`/MusicKeyword/${currentKeyword.id}`, data)
                .then(res => {
                    this.getKeywords();
                })
                .catch(error => {
                    this.showSnackbar({ color: 'red', message: error.response.data.Message });
                });
        },
        deleteGenre1(item) {
            this.confirmGenreDeleteModal = true;
            this.selectedGenre = item;
        },
        removeFromList(item, array) {
            const found = array.find(x => x.id == item.id);
            const index = array.indexOf(found);
            array.splice(index, 1);
        },
        submitGenre() {
            let data = {
                name: this.newGenreName,
            };

            this.axiosInstance
                .post('/MusicGenre', data)
                .then(res => {
                    this.getGenres();
                    this.tempGenres.push(res.data);
                    this.newGenreModal = false;
                    this.newGenreName = '';
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        submitKeyword() {
            let data = {
                name: this.newKeywordName,
            };

            this.axiosInstance
                .post('/MusicKeyword', data)
                .then(res => {
                    this.getKeywords();
                    this.tempKeywords.push(res.data);
                    this.newKeywordModal = false;
                    this.newKeywordName = '';
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        newKeyword() {
            this.newKeywordModal = true;
        },
        newGenre() {
            this.newGenreModal = true;
        },
        addKeyword(songId, genreId) {
            return this.axiosInstance.put(`/TributeVideoSong/keyword/add/${songId}/${genreId}`);
        },
        removeKeyword(songId, genreId) {
            return this.axiosInstance.put(`/TributeVideoSong/keyword/remove/${songId}/${genreId}`);
        },
        addGenre(songId, genreId) {
            return this.axiosInstance.put(`/TributeVideoSong/genre/add/${songId}/${genreId}`);
        },
        removeGenre(songId, genreId) {
            return this.axiosInstance.put(`/TributeVideoSong/genre/remove/${songId}/${genreId}`);
        },
        updateInfo(item) {
            let data = {
                title: item.title,
                artist: item.artist,
                album: item.album,
                source: item.source,
                licenseConfidence: item.licenseConfidence,
            };

            return this.axiosInstance
                .put(`/TributeVideoSong/${item.id}`, data)
                .then(res => {})
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        getSong(songId) {
            return this.axiosInstance.get(`/TributeVideoSong/${songId}`);
        },
        async updateSong(item) {
            this.loading = true;
            await this.updateInfo(item);

            // Get generate lists of keywords / genres added / removed
            const genresToRemove = this.selectedSong.musicGenres.filter(g => {
                if (!this.tempGenres.some(x => x.id == g.id)) {
                    return g;
                }
            });

            const genresToAdd = this.tempGenres.filter(g => {
                if (!this.selectedSong.musicGenres.some(x => x.id == g.id)) {
                    return g;
                }
            });

            const keywordsToRemove = this.selectedSong.musicKeywords.filter(kw => {
                if (!this.tempKeywords.some(x => x.id == kw.id)) {
                    return kw;
                }
            });

            const keywordsToAdd = this.tempKeywords.filter(kw => {
                if (!this.selectedSong.musicKeywords.some(x => x.id == kw.id)) {
                    return kw;
                }
            });

            // Compile list of promises
            const removeGenreRequests = genresToRemove.map(genre => {
                return this.removeGenre(this.selectedSong.id, genre.id);
            });
            const addGenreRequests = genresToAdd.map(genre => {
                return this.addGenre(this.selectedSong.id, genre.id);
            });
            const removeKeywordRequests = keywordsToRemove.map(kw => {
                return this.removeKeyword(this.selectedSong.id, kw.id);
            });
            const addKeywordRequests = keywordsToAdd.map(kw => {
                return this.addKeyword(this.selectedSong.id, kw.id);
            });

            await Promise.allSettled(removeGenreRequests);
            await Promise.allSettled(addGenreRequests);
            await Promise.allSettled(removeKeywordRequests);
            await Promise.allSettled(addKeywordRequests)
                .then(r => {
                    this.getSongs();
                    this.editModal = false;
                    this.selectedSong = {};
                    this.loading = false;
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },

        cancelEdit() {
            this.$refs['audio-modal'].currentTime = 0;
            this.$refs['audio-modal'].pause();
            this.editModal = false;
        },
        handleRowClick(item) {
            if (this.$route.name == 'TributeAdmin' && this.$auth.role.includes('SuperAdmin')) {
                this.editStep1(item);
            }

            if (
                this.$route.name == 'EditServices' ||
                this.$route.name == 'TributeUploadPage' ||
                this.$route.name == 'TributeFamilyPage'
            ) {
                this.selectedAudio = item;
                if (this.checkExpanded(item)) {
                    this.expanded = [];
                } else {
                    this.expanded = [item];
                }
            }
        },
        editStep1(item) {
            if (this.$route.name == 'TributeAdmin' && this.$auth.role.includes('SuperAdmin')) {
                this.selectedSong = item;
                this.tempGenres = JSON.parse(JSON.stringify(this.selectedSong.musicGenres));
                this.tempKeywords = JSON.parse(JSON.stringify(this.selectedSong.musicKeywords));
                this.editModal = true;
            }
        },
        confirmDelete(id) {
            this.axiosInstance
                .delete(`TributeVideoSong/${id}`)
                .then(res => {
                    this.deleteModal = false;
                    this.editModal = false;
                    this.getSongs();
                    this.showSnackbar({ message: `${this.selectedSong.title} deleted` });
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        cancelDelete() {
            this.deleteModal = false;
            // this.selectedSong = {};
        },
        deleteStep1(item) {
            this.deleteModal = true;
            this.selectedSong = item;
        },
        stopAllAudio() {
            const audioList = document.getElementsByClassName('audioPlayer');

            //Stop all audio sources
            for (let i = 0; i < audioList.length; i++) {
                this.$refs[audioList[i].id].stopAudio();
            }
        },
        playingAudio(item, audio) {
            const audioList = document.getElementsByClassName('audioPlayer');

            //Stop other audio sources if applicable
            for (let i = 0; i < audioList.length; i++) {
                if (audioList[i].id != `audio-${item.id}`) {
                    this.$refs[audioList[i].id].stopAudio();
                }
            }
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
        getSortByValue(value) {
            switch (value) {
                case 'title':
                    return 'Title';
                case 'id':
                    return 'Id';
                case 'artist':
                    return 'Artist';
                case 'album':
                    return 'Album';
                case 'createDate':
                    return 'CreateDate';
                case 'duration':
                    return 'Duration';
                case 'usedIn':
                    return 'UsedIn';
                default:
                    console.warn('No cases found in switch statement.');
                    return '';
            }
        },
        getKeywords() {
            return this.axiosInstance
                .get('/MusicKeyword?pageSize=100')
                .then(res => {
                    this.keywords = res.data.musicKeywords;
                    this.totalKeywords = res.data.total;
                })
                .catch(err => {
                    console.log(err, 'error');
                });
        },
        getGenres() {
            return this.axiosInstance
                .get('/MusicGenre?pageSize=100')
                .then(res => {
                    this.genres = res.data.musicGenres;
                    this.totalGenres = res.data.total;
                })
                .catch(err => {
                    console.log(err, 'error');
                });
        },
        async getSongs() {
            this.loading = true;

            const { sortBy, sortDesc, page, itemsPerPage } = this.options;

            var params = {
                pageSize: itemsPerPage,
                pageNumber: page ? page - 1 : 1,
                sortBy: sortBy && sortBy[0] ? this.getSortByValue(sortBy[0]) : null || null,
                sortAsc: sortBy && sortBy[0] ? !sortDesc[0] : null || null,
                search: this.search,
                funeralHomeId: this.funeralHomeId ? this.funeralHomeId : 0,
                favoritesOnly: this.featuredOnly,
            };

            if (this.filterFhId) {
                params.funeralHomeId = parseInt(this.filterFhId);
            }

            this.generateParamString(this.selectedGenres, 'genre');
            this.generateParamString(this.selectedKeywords, 'keyword');
            this.generateParamString(this.selectedUserTypes, 'userType');

            if (this.globalSongsOnly) {
                this.userTypeParamString = '&userType=2';
            }

            let fullParamString = [this.genreParamString, this.keywordParamString, this.userTypeParamString]
                .filter(Boolean)
                .join('');

            return this.axiosInstance
                .get(`TributeVideoSong?` + fullParamString, {
                    params: params,
                })
                .then(res => {
                    this.songs = res.data.tributeSongs;

                    this.totalSongs = res.data.total;
                })
                .catch(err => {
                    console.log(err, 'error');
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    // async created() {
    //     this.createAxiosInstance();

    //     await this.getGenres();
    //     await this.getKeywords();
    //     if (this.userSongsOnly) {
    //         this.selectedUserTypes = [0, 1, 4];
    //         let string = '';
    //         this.selectedUserTypes.forEach(u => (string += `&userType=${u}`));
    //         this.userTypeParamString = string;
    //     }
    //     this.getSongs();
    // },
    async mounted() {
        this.createAxiosInstance();

        await this.getGenres();
        await this.getKeywords();
        if (this.userSongsOnly) {
            this.selectedUserTypes = [0, 1, 4];
            let string = '';
            this.selectedUserTypes.forEach(u => (string += `&userType=${u}`));
            this.userTypeParamString = string;
        }
        this.getSongs();
        if (this.$route.name == 'TributeAdmin' && this.$auth.role.includes('SuperAdmin')) {
            if (this.globalSongsOnly) {
                this.headers = [
                    // { text: 'Id', value: 'id' },
                    { text: '', value: 'artwork' },
                    { text: 'Audio', value: 'playableLink', align: 'center', sortable: false },
                    { text: 'Title', value: 'title' },
                    { text: 'Artist', value: 'artist' },
                    { text: 'Album', value: 'album' },
                    { text: 'Genres', value: 'musicGenres', sortable: false },
                    { text: 'Keywords', value: 'musicKeywords', sortable: false },
                    { text: 'Uploaded', value: 'createDate' },
                    // { text: 'Upload Type', value: 'uploadType', sortable: false },
                    { text: 'License Confidence', value: 'licenseConfidence' },
                    { text: 'Duration', value: 'duration' },
                    { text: 'Used In', value: 'usedIn' },
                    // { text: 'Actions', value: 'actions', align: 'center' },
                ];
            } else if (this.userSongsOnly) {
                this.headers = [
                    { text: '', value: 'artwork' },
                    { text: 'Audio', value: 'playableLink', align: 'center', sortable: false },
                    { text: 'Title', value: 'title' },
                    { text: 'Artist', value: 'artist' },
                    { text: 'Album', value: 'album' },
                    // { text: 'Genres', value: 'musicGenres', sortable: false },
                    // { text: 'Keywords', value: 'musicKeywords', sortable: false },
                    { text: 'Uploaded', value: 'createDate' },
                    { text: 'Duration', value: 'duration' },
                    { text: 'Upload Type', value: 'uploadType', sortable: false },
                    { text: 'Used In', value: 'usedIn' },
                ];
            } else {
                this.headers = [
                    { text: '', value: 'artwork' },
                    { text: 'Audio', value: 'playableLink', align: 'center', sortable: false },
                    { text: 'Title', value: 'title' },
                    { text: 'Artist', value: 'artist' },
                    { text: 'Album', value: 'album' },
                    { text: 'Genres', value: 'musicGenres', sortable: false },
                    { text: 'Keywords', value: 'musicKeywords', sortable: false },
                    { text: 'Uploaded', value: 'createDate' },
                    // { text: 'Upload Type', value: 'uploadType', sortable: false },
                    { text: 'Used In', value: 'usedIn' },
                    { text: 'Duration', value: 'duration' },
                ];
            }
        } else {
            this.headers = [
                { text: 'Audio', value: 'playableLink', align: 'center', sortable: false },
                { text: 'Title', value: 'title' },
                { text: 'Artist', value: 'artist' },
                // { text: 'Album', value: 'album' },
                { text: 'Genres', value: 'musicGenres', sortable: false },
                { text: 'Keywords', value: 'musicKeywords', sortable: false },
                // { text: 'Create Date', value: 'createDate' },
                { text: 'Duration', value: 'duration' },
                // { text: 'Audio', value: 'playableLink', align: 'center', sortable: false },
                // { text: 'Actions', value: 'actions', align: 'center' },
            ];
        }
    },
};
</script>
<style lang="scss">
.text-field-btn {
    border-radius: 5px;
    cursor: pointer;
}

.text-field-btn:hover {
    background: #f5f5f5;
}

.service-music-row {
    border-radius: 5px !important;
}

.service-music-row-expanded {
    background-color: #dbe2e8 !important;
}
.service-music-row-selected {
    // background-color: #dbe2e8 !important;
    // border: 2px solid red !important;
    background-color: white !important;
}
.service-music-row-selected:hover {
    background-color: #f5f5f5 !important;
}

.service-music-row:hover {
    border-radius: 5px !important;
}

.expanded-item {
    // border: 2px solid blue;
    background-color: white;
    background-color: #f8fafb;
}

.trash:hover {
    cursor: pointer;
    color: #ff5252;
    transition: 0.2s;
}

.no-image {
    border: 2px solid #bbb;
    background-color: #eee;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
}
.no-image:hover {
    background-color: #ccc;
    transition: 0.3s;
}

.table-img-placeholder {
    background-color: #f1f3f4;
    height: 60px;
    width: 60px;
    border-radius: 5px;
    font-size: 0.7rem;
}
.text-overflow-elipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
</style>
