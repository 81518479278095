<template>
    <div v-click-outside="handleFocusOut" class="ms-date-picker" :class="handleClassCalculation()" ref="dp">
        <v-text-field
            autocomplete="off"
            id="calendar-input-field"
            :disabled="disabled"
            v-model="renderedDate"
            :label="label"
            @focus="handleInputFocus"
            @input="handleDateInput"
            @click="handleInputFieldClick"
            @keydown.tab="handleBlurInTabKey($event)"
        />

        <div v-if="showDropdown" class="main-container">
            <div class="date-header-container">
                <div>
                    <span
                        style="font-size: 1.8rem; cursor: pointer"
                        class="mdi mdi-chevron-left"
                        :style="
                            datetimeShowTime ? 'opacity: 0; pointer-events: none;' : 'opacity: 100; pointer-events: all'
                        "
                        @click="handleGoPrevMonth"
                    />
                </div>
                <div class="month-year-container">
                    <span @click="handleMonthTextClick" style="cursor: pointer; font-size: 1.5rem">{{
                        $moment(internalDate).format('MMMM DD')
                    }}</span>
                    <span style="cursor: pointer" @click="handleYearTextClick">{{
                        $moment(internalDate).format('YYYY')
                    }}</span>
                </div>
                <div>
                    <span
                        v-if="
                            !(
                                this.verifyCalendarType() &&
                                new Date().getMonth() < $moment(internalDate).format('MM') &&
                                new Date().getFullYear() - 1 < $moment(internalDate).format('YYYY')
                            )
                        "
                        style="font-size: 1.8rem; cursor: pointer"
                        class="mdi mdi-chevron-right"
                        :style="
                            datetimeShowTime ? 'opacity: 0; pointer-events: none;' : 'opacity: 100; pointer-events: all'
                        "
                        @click="handleGoNextMonth"
                    />
                </div>
            </div>
            <div class="month-selector-container">
                <ul>
                    <li
                        v-if="
                            !(
                                this.verifyCalendarType() &&
                                new Date().getMonth() < 0 &&
                                new Date().getFullYear() - 1 < $moment(internalDate).format('YYYY')
                            )
                        "
                        @click="setMonth(0)"
                    >
                        January
                    </li>
                    <li
                        v-if="
                            !(
                                this.verifyCalendarType() &&
                                new Date().getMonth() < 1 &&
                                new Date().getFullYear() - 1 < $moment(internalDate).format('YYYY')
                            )
                        "
                        @click="setMonth(1)"
                    >
                        February
                    </li>
                    <li
                        v-if="
                            !(
                                this.verifyCalendarType() &&
                                new Date().getMonth() < 2 &&
                                new Date().getFullYear() - 1 < $moment(internalDate).format('YYYY')
                            )
                        "
                        @click="setMonth(2)"
                    >
                        March
                    </li>
                    <li
                        v-if="
                            !(
                                this.verifyCalendarType() &&
                                new Date().getMonth() < 3 &&
                                new Date().getFullYear() - 1 < $moment(internalDate).format('YYYY')
                            )
                        "
                        @click="setMonth(3)"
                    >
                        April
                    </li>
                    <li
                        v-if="
                            !(
                                this.verifyCalendarType() &&
                                new Date().getMonth() < 4 &&
                                new Date().getFullYear() - 1 < $moment(internalDate).format('YYYY')
                            )
                        "
                        @click="setMonth(4)"
                    >
                        May
                    </li>
                    <li
                        v-if="
                            !(
                                this.verifyCalendarType() &&
                                new Date().getMonth() < 5 &&
                                new Date().getFullYear() - 1 < $moment(internalDate).format('YYYY')
                            )
                        "
                        @click="setMonth(5)"
                    >
                        June
                    </li>
                    <li
                        v-if="
                            !(
                                this.verifyCalendarType() &&
                                new Date().getMonth() < 6 &&
                                new Date().getFullYear() - 1 < $moment(internalDate).format('YYYY')
                            )
                        "
                        @click="setMonth(6)"
                    >
                        July
                    </li>
                    <li
                        v-if="
                            !(
                                this.verifyCalendarType() &&
                                new Date().getMonth() < 7 &&
                                new Date().getFullYear() - 1 < $moment(internalDate).format('YYYY')
                            )
                        "
                        @click="setMonth(7)"
                    >
                        August
                    </li>
                    <li
                        v-if="
                            !(
                                this.verifyCalendarType() &&
                                new Date().getMonth() < 8 &&
                                new Date().getFullYear() - 1 < $moment(internalDate).format('YYYY')
                            )
                        "
                        @click="setMonth(8)"
                    >
                        September
                    </li>
                    <li
                        v-if="
                            !(
                                this.verifyCalendarType() &&
                                new Date().getMonth() < 9 &&
                                new Date().getFullYear() - 1 < $moment(internalDate).format('YYYY')
                            )
                        "
                        @click="setMonth(9)"
                    >
                        October
                    </li>
                    <li
                        v-if="
                            !(
                                this.verifyCalendarType() &&
                                new Date().getMonth() < 10 &&
                                new Date().getFullYear() - 1 < $moment(internalDate).format('YYYY')
                            )
                        "
                        @click="setMonth(10)"
                    >
                        November
                    </li>
                    <li
                        v-if="
                            !(
                                this.verifyCalendarType() &&
                                new Date().getMonth() < 11 &&
                                new Date().getFullYear() - 1 < $moment(internalDate).format('YYYY')
                            )
                        "
                        @click="setMonth(11)"
                    >
                        December
                    </li>
                </ul>
            </div>
            <div class="year-selector-container">
                <ul>
                    <li v-for="year in possibleYears" :key="year" @click="setYear(year)">{{ year }}</li>
                </ul>
            </div>
            <date-time-picker
                :disabled="disabled"
                inline
                v-model="internalDate"
                :max-date="this.verifyCalendarType() ? new Date() : '2100-01-01'"
                :min-date="disablePastDates ? new Date() : ''"
                :no-toggle-time-picker="true"
                :time-picker="false"
                :hour-time="12"
                :minute-step="minuteStep"
                start-from-sunday
                :today-button="false"
                :close-button="true"
                empty-value="-- --"
            >
                <!-- v-click-outside="handleFocusOut" -->
                <template slot="years-next">
                    <span style="font-size: 1.8rem" class="mdi mdi-chevron-right" />
                </template>
                <template slot="years-prev">
                    <span style="font-size: 1.8rem" class="mdi mdi-chevron-left" />
                </template>
                <template slot="decades-next">
                    <span style="font-size: 1.8rem" class="mdi mdi-chevron-right" />
                </template>
                <template slot="decades-prev">
                    <span style="font-size: 1.8rem" class="mdi mdi-chevron-left" />
                </template>
                <template slot="hours-up">
                    <span style="font-size: 1.4rem" class="mdi mdi-chevron-up" />
                </template>
                <template slot="hours-down">
                    <span style="font-size: 1.4rem" class="mdi mdi-chevron-down" />
                </template>
                <template slot="minutes-up">
                    <span style="font-size: 1.4rem" class="mdi mdi-chevron-up" />
                </template>
                <template slot="minutes-down">
                    <span style="font-size: 1.4rem" class="mdi mdi-chevron-down" />
                </template>
                <template slot="meridiems-up">
                    <span style="font-size: 1.4rem" class="mdi mdi-chevron-up" />
                </template>
                <template slot="meridiems-down">
                    <span style="font-size: 1.4rem" class="mdi mdi-chevron-down" />
                </template>
                <template slot="close"> </template>
            </date-time-picker>
            <div :class="hideSaveButtons ? 'hidden-save-buttons' : ''" class="button-container">
                <v-btn v-if="datetime" small class="datetime-back-button" @click="handleBackButton">Back</v-btn>
                <span style="flex: 1"></span>
                <v-btn
                    v-if="datetime && !datetimeShowTime"
                    style="color: white"
                    color="#ff530c"
                    small
                    class="save-btn"
                    @click="handleSetNextTimeButton"
                    >Next: Set Time</v-btn
                >
                <v-btn
                    v-if="datetime && datetimeShowTime"
                    style="color: white"
                    color="#ff530c"
                    small
                    class="save-btn"
                    @click="handleSaveButton"
                    >Save</v-btn
                >
                <v-btn
                    v-if="!datetime"
                    style="color: white"
                    color="#ff530c"
                    small
                    class="save-btn"
                    @click="handleSaveButton"
                    >Save Date</v-btn
                >
                <!-- @keyup.tab="handleFocusOutTab" -->
            </div>
        </div>
    </div>
</template>

<script>
import DateTimePicker from 'vue-vanilla-datetime-picker';
import { mapActions } from 'vuex';

export default {
    props: {
        datetime: {
            type: Boolean,
            default: false,
        },
        outputLocal: {
            type: Boolean,
            default: false,
        },
        showLocal: {
            type: Boolean,
            default: true,
        },
        value: String,
        disabled: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: '',
        },
        inline: {
            type: Boolean,
            deafult: false,
        },
        hideSaveButtons: {
            type: Boolean,
            defalut: false,
        },
        disablePastDates: {
            type: Boolean,
            default: false,
        },
        minuteStep: {
            type: Number,
            default: 5,
        },
        debug: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            internalDate: null,
            outputDate: '',
            renderedDate: '',
            showDropdown: false,
            isTyping: false,
            isNull: true,
            triggerCount: 0, // This is a weird thing with vue.
            focus: false,
            calendarDates: [],
            datetimeShowTime: false,
            possibleYears: [],
            tempDate: false,
        };
    },
    components: {
        DateTimePicker,
    },
    mounted() {
        if (this.debug) {
            console.log(this.value, 'value at mounted');
        }

        // Generate possible years of death
        const currentYear = new Date().getFullYear();
        const yearArray = [];
        for (var i = 1900; i <= currentYear + (this.verifyCalendarType() ? 0 : 5); i++) yearArray.push(i);
        this.possibleYears = yearArray.reverse();

        // // If a value was passed in and is not datetime.minVal
        if (this.$props.value && !this.$props.value.includes('0001-01-01')) {
            this.internalDate = new Date(this.$props.value);
        }

        if (this.inline) {
            this.handleInputFocus();
            this.handleInputFieldClick();
        }
    },
    methods: {
        ...mapActions(['showSnackbar']),
        verifyCalendarType: function () {
            return this.$props.label === 'Death Date' || this.$props.label === 'Birth Date';
        },
        handleGoNextMonth: function () {
            var now = this.internalDate;
            this.internalDate = this.$moment(now).set({
                month: now.getMonth() === 11 ? 0 : now.getMonth() + 1,
                year: now.getMonth() === 11 ? now.getFullYear() + 1 : now.getFullYear(),
            })._d;
            // Set day marker to actual day if month reach actual month
            if (
                this.verifyCalendarType() &&
                new Date().getMonth() < this.$moment(this.internalDate).format('MM') &&
                new Date().getFullYear() - 1 < this.$moment(this.internalDate).format('YYYY')
            ) {
                this.internalDate.setDate(new Date().getDate());
            }
            // Remove current year from selector if month is bigger than actual
            if (this.verifyCalendarType() && new Date().getMonth() + 1 < this.$moment(this.internalDate).format('MM')) {
                if (new Date().getFullYear() == this.possibleYears[0]) {
                    this.possibleYears.shift();
                }
            } else if (
                this.verifyCalendarType() &&
                new Date().getMonth() + 1 >= this.$moment(this.internalDate).format('MM')
            ) {
                if (new Date().getFullYear() != this.possibleYears[0]) {
                    this.possibleYears.unshift(new Date().getFullYear());
                }
            }

            this.triggerCount = 0;
        },
        handleGoPrevMonth: function () {
            var now = this.internalDate;
            this.internalDate = this.$moment(now).set({
                month: now.getMonth() === 0 ? 11 : now.getMonth() - 1,
                year: now.getMonth() === 0 ? now.getFullYear() - 1 : now.getFullYear(),
            })._d;
            this.triggerCount = 0;

            // Remove current year from selector if month is bigger than actual
            if (
                this.verifyCalendarType() &&
                new Date().getMonth() + 1 >= this.$moment(this.internalDate).format('MM')
            ) {
                if (new Date().getFullYear() != this.possibleYears[0]) {
                    this.possibleYears.unshift(new Date().getFullYear());
                }
            } else if (
                this.verifyCalendarType() &&
                new Date().getMonth() + 1 < this.$moment(this.internalDate).format('MM')
            ) {
                if (new Date().getFullYear() == this.possibleYears[0]) {
                    this.possibleYears.shift();
                }
            }
        },
        hideTimePicker: function () {
            const picker = this.$refs.dp.getElementsByClassName('time-picker')[0];
            if (picker) {
                picker.style.display = 'none';
            }
        },
        showTimePicker: function () {
            const picker = this.$refs.dp.getElementsByClassName('time-picker')[0];
            if (picker) {
                picker.style.display = 'flex';
            }
        },
        hideDates: function () {
            const days = this.$refs.dp.getElementsByClassName('date-picker__days')[0];
            if (days) {
                days.style.display = 'none';
            }
        },
        hideButtonContainer: function () {
            const buttons = this.$refs.dp.getElementsByClassName('button-container')[0];
            if (buttons) {
                buttons.style.display = 'none';
            }
        },
        showButtonContainer: function () {
            const buttons = this.$refs.dp.getElementsByClassName('button-container')[0];
            if (buttons) {
                buttons.style.display = 'flex';
            }
        },
        showDates: function () {
            const days = this.$refs.dp.getElementsByClassName('date-picker__days')[0];
            if (days) {
                days.style.display = 'block';
            }
        },
        hideMonthPicker: function () {
            const months = this.$refs.dp.getElementsByClassName('month-selector-container')[0];
            if (months) {
                months.style.display = 'none';
            }
        },
        hideYearPicker: function () {
            const years = this.$refs.dp.getElementsByClassName('year-selector-container')[0];
            if (years) {
                years.style.display = 'none';
            }
        },
        showMonthPicker: function () {
            const months = this.$refs.dp.getElementsByClassName('month-selector-container')[0];
            if (months) {
                months.style.display = 'block';
            }
        },
        showYearPicker: function () {
            const years = this.$refs.dp.getElementsByClassName('year-selector-container')[0];
            if (years) {
                years.style.display = 'block';
            }
        },
        setMonth: function (month) {
            this.triggerCount = 0;
            const date = this.internalDate;
            this.internalDate = this.$moment(date).set('month', month)._d;

            this.hideMonthPicker();
            this.showDates();
            this.showButtonContainer();
        },
        setYear: function (year) {
            this.triggerCount = 0;
            const date = this.internalDate;
            this.internalDate = this.$moment(date).set('year', year)._d;

            this.hideYearPicker();
            this.showDates();
            this.showButtonContainer();
        },
        handleMonthTextClick: function () {
            this.triggerCount = 0;
            this.datetimeShowTime = false;
            this.showMonthPicker();
            this.hideYearPicker();
            this.hideDates();
            this.hideTimePicker();
        },
        handleYearTextClick: function () {
            this.triggerCount = 0;
            this.datetimeShowTime = false;
            this.showYearPicker();
            this.hideMonthPicker();
            this.hideDates();
            this.hideTimePicker();
        },
        /**
         * Handles behavior when the input field is clicked
         */
        handleInputFieldClick: function () {
            if (!this.showDropdown) {
                this.showDropdown = true;
            } else {
                // reset trigger count cause this shouldn't be considered an "outside"
                // click
                this.triggerCount = 0;
            }
        },
        handleSetNextTimeButton: function () {
            if (this.datetime) {
                if (!this.renderedDate) {
                    const renderFormat = 'MM/DD/YYYY - h:mm A';
                    this.renderedDate = this.$moment(this.internalDate).format(renderFormat);
                }
                this.datetimeShowTime = true;
                this.hideDates();
                this.hideYearPicker();
                this.hideMonthPicker();
                this.showTimePicker();
                this.triggerCount = 0;
            }
        },
        /**
         * An event when a date is clicked
         */
        handleDateClick: function (event, date) {
            if (date.classList.contains('date-picker__day--disabled')) {
                this.showSnackbar({ message: 'Invalid date selection', color: 'error' });
                return;
            }

            if (this.datetime) {
                this.handleSetNextTimeButton();
            } else {
                if (this.tempDate) {
                    this.tempDate = false;
                }
                this.handleFocusOut();
            }
        },
        handleClassCalculation: function () {
            if (!this.datetime) return '';
            if (this.datetime && this.datetimeShowTime) return 'show-datetime-time';
            if (this.datetime && !this.datetimeShowTime) return 'hide-datetime-time';
        },
        /**
         * When the input is focused on
         */
        handleInputFocus: function () {
            this.focus = true;
            this.showDropdown = true;
            this.dropdownFlag = true;

            // If internal date is null set the date to today at 1pm
            if (this.internalDate === null || this.internalDate === '') {
                this.tempDate = true;
                this.internalDate = new Date(new Date().setHours(13, 0, 0, 0));
            }

            this.$nextTick(function () {
                const _ = this;
                this.calendarDates = this.$refs.dp.getElementsByClassName('date-picker__day--current');
                Array.from(this.calendarDates).forEach(function (date) {
                    date.addEventListener('click', function (event) {
                        _.handleDateClick(event, date);
                    });
                });
            });

            this.$emit('focus', this.focus);
        },
        /**
         * Handle when someone is typing a date
         */
        handleDateInput: function () {
            this.isTyping = true;
            const cleanedDate = this.renderedDate.replace('-', '');

            const typed_dated = this.$moment(cleanedDate);
            if (typed_dated._isValid) this.internalDate = typed_dated._d;

            if (this.renderedDate == '') {
                this.tempDate = true;
            }
        },
        /**
         * Handle when clicking the save button
         */
        handleSaveButton: function () {
            if (this.inline) {
                this.$emit('inline-save');
                return;
            }

            this.tempDate = false;
            this.resetDatePicker();
            this.$emit('focus', this.focus);
        },
        handleBackButton: function () {
            this.datetimeShowTime = false;
            this.triggerCount = 0;
            this.showDates();
            this.hideYearPicker();
            this.hideTimePicker();
        },
        handleBlurInTabKey: function (event) {
            if (event.srcElement.id == 'calendar-input-field') {
                this.handleFocusOut();
            }
        },
        // handleFocusOutTab: function () {
        //     console.log('focus-out-tab');
        //     this.triggerCount = 1;
        //     this.handleFocusOut();
        // },
        handleFocusOut: function () {
            if (this.inline) {
                return;
            }

            if (this.tempDate) {
                this.internalDate = null;
            }
            if (this.showDropdown) {
                Array.from(this.calendarDates).forEach(date => date.removeEventListener('click', null));
                this.showDropdown = false;
                this.datetimeShowTime = false;
                this.focus = false;
            }

            // Commenting this out for now, causing component component blur issues click outside/tab away
            // if (this.showDropdown && this.triggerCount !== 1) {
            //     console.log('does not equal 1');
            //     this.triggerCount = 1;
            //     return;
            // }
            // if (this.showDropdown && this.triggerCount === 1) {
            //     console.log('equals 1!');
            //     this.triggerCount = 0;
            //     if (new Date() >= new Date(this.renderedDate)) {
            //         this.resetDatePicker();
            //     }
            // }

            this.$emit('focus', this.focus);
        },
        resetDatePicker: function () {
            Array.from(this.calendarDates).forEach(date => date.removeEventListener('click', null));
            this.showDropdown = false;
            this.datetimeShowTime = false;
            this.focus = false;
            this.hideMonthPicker();
            this.hideYearPicker();
            this.showDates();
        },
    },
    watch: {
        internalDate() {
            if (this.debug) {
                console.log(this.internalDate, 'internal date watcher start');
            }

            if (!this.internalDate) {
                this.renderedDate = '';
            }
            // Update Internals
            if (this.internalDate && !this.isTyping) {
                const renderFormat = this.datetime ? 'MM/DD/YYYY - h:mm A' : 'MM/DD/YYYY';
                // Create Dates
                this.renderedDate = this.$moment(this.internalDate).format(renderFormat);
                this.outputDate = this.$moment(this.internalDate).set({ second: 0, millisecond: 0 }).toISOString();
            }

            // Generate Output String
            // If ISOSTRING() is set to true
            // it retains timezone, else it returns UTC
            let output = '';

            // if datetime we only set the seconds to 0
            if (this.$props.datetime) {
                output = this.$moment(this.internalDate)
                    .set({ second: 0, millisecond: 0 })
                    .toISOString(this.outputLocal);
            } else {
                if (this.$props.outputLocal) {
                    output = this.$moment(this.internalDate)
                        .set({ minute: 0, hour: 0, second: 0, millisecond: 0 })
                        .toISOString(this.outputLocal)
                        .slice(0, -6);
                } else {
                    output = this.$moment(this.internalDate)
                        .set({ minute: 0, hour: 0, second: 0, millisecond: 0 })
                        .toISOString(this.outputLocal);
                }
            }

            if (this.debug) {
                console.log(this.internalDate, 'internal date watcher end');
            }

            // Emit
            this.$emit('input', output);
        },
        value(newValue) {
            if (this.debug) {
                console.log(newValue, 'value watcher start');
            }

            if (this.isTyping) {
                this.isTyping = false;
            } else {
                // clear internal date if passed datetime.minvalue
                if (!newValue || newValue.includes('0001') || newValue.includes('0000')) {
                    this.internalDate = null;
                } else {
                    this.internalDate = new Date(this.value);
                }
            }

            if (this.debug) {
                console.log(this.value, 'value watcher end');
            }
        },
        renderedDate() {
            if (!this.renderedDate) {
                this.internalDate = null;
            }
        },
        showDropdown() {
            if (this.showDropdown) {
                // Initiate UI State
                this.$nextTick(function () {
                    if (!this.$props.datetime) {
                        this.hideTimePicker();
                    }
                    this.hideMonthPicker();
                    this.hideYearPicker();
                    this.showButtonContainer();
                });
            } else {
                this.$emit('close');
            }
        },
        datetimeShowTime(val) {
            this.$emit('show-time', val);
        },
    },
};
</script>

<style lang="scss">
@import 'node_modules/vue-vanilla-datetime-picker/dist/DateTimePicker';

$accent: #ff530c;

// If you're in the date picker mode
.hide-datetime-time {
    .time-picker {
        display: none;
    }
    .date-picker__days {
        display: auto;
    }
    .datetime-back-button {
        display: none;
    }
}

// Shows the time after a date is clicked
.show-datetime-time {
    .time-picker {
        display: flex !important;
    }
    .date-picker__days {
        display: none;
    }
    .datetime-back-button {
        display: auto;
    }
}

.ms-date-picker {
    position: relative;
    width: 100%;

    .close__button {
        display: none;
    }

    .button-container {
        display: flex;
        justify-content: space-between;
        padding: 0.5rem;
    }

    .month-selector-container,
    .year-selector-container {
        ul {
            display: flex;
            flex-direction: column;
            list-style: none;
            margin: 0;
            padding: 0;
            max-height: 300px;
            overflow: auto;

            li {
                width: 100%;
                cursor: pointer;
                padding: 4px;
                text-align: center;
            }

            li:hover {
                background: #eeeeee;
            }
        }
    }

    .main-container {
        position: absolute;
        top: 2.8rem;
        z-index: 3000;
        background: white;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }

    .date-header-container {
        background: $accent;
        padding: 1rem;
        display: flex;
        align-items: center;
        gap: 12px;
        color: white;

        .month-year-container {
            display: flex;
            flex: 1;
            flex-direction: column;
        }
    }

    .date-picker__header {
        display: none;
    }

    .hide-time {
        .time-picker {
            display: none;
        }
    }

    .show-time {
        .time-picker {
            display: auto;
        }
    }

    .datetime-picker-inline {
        padding: 0;
        border: 0;
    }

    .date-picker__days {
        padding: 1rem;
    }

    .date-picker__cell {
        font-size: 15px;
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 9999px;
        color: #444;
    }

    .date-picker__month-header,
    .date-picker__year-header {
        font-size: 1.4rem;
    }

    .date-picker__cell:hover {
        cursor: pointer;
        background: #eeeeee;
    }

    .date-picker__cell--selected {
        background: $accent;
        color: white;
    }
    .date-picker__cell--selected:hover {
        background: $accent;
        color: white;
    }

    .weekday__cell {
        font-size: 15px;
        font-weight: bold;
    }

    .date-picker__header {
        background: $accent;
        color: white;
        padding: 1rem;
    }

    .text-slider {
        margin: 0 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .text-slider__value {
        font-size: 1.4rem;
    }

    .date-picker__years,
    .date-picker__months {
        display: flex;
        flex-direction: column;
        gap: 0.6rem;
        color: #444;
    }

    .date-picker__day--next,
    .date-picker__day--previous {
        opacity: 0;
        pointer-events: none;
    }

    .hidden-save-buttons {
        .save-btn {
            display: none;
        }
    }
}
</style>
