<template>
    <div>
        <v-row justify="end">
            <v-col>
                <div style="width: 100%" class="d-flex justify-space-between">
                    <calendar
                        style="max-width: 200px"
                        v-model="startDate"
                        label="From:"
                        :output-local="true"
                    ></calendar>

                    <calendar style="max-width: 200px" v-model="endDate" label="To:" :output-local="true"></calendar>
                </div>
            </v-col>
            <v-col>
                <div class="d-flex justify-end mb-6">
                    <v-text-field
                        label="Search"
                        style="max-width: 300px"
                        v-model="search"
                        append-icon="mdi-magnify"
                        @keyup.enter="getRenders"
                        single-line
                        hide-details
                    ></v-text-field>
                </div>
            </v-col>
        </v-row>
        <v-data-table
            class="mt-4"
            :headers="headers"
            :items="renders"
            :options.sync="options"
            :loading="loading"
            :footer-props="{
                itemsPerPageOptions: [5, 25, 50],
            }"
            loading-text="Getting Renders..."
            :server-items-length="total"
        >
            <!-- :search="search" -->
            <!-- <template v-slot:item.blobs="{ item }">
                        <a :href="item.blobs" :title="item.blobs">{{ item.blobs | truncate(10, '...') }}</a>
                    </template> -->

            <template v-slot:header.videoQueType="{ header }">
                <table-header-menu @menu-closed="getRenders">
                    <template v-slot:label>
                        <span>
                            {{ header.text }} ({{ selectedRenderTypes.length }})
                            <font-awesome-icon icon="fa-solid fa-chevron-down" />
                        </span>
                    </template>
                    <template v-slot:content>
                        <v-list>
                            <v-list-item v-for="(item, index) in renderTypes" :key="index" style="height: 20px">
                                <v-checkbox
                                    v-model="selectedRenderTypes"
                                    :label="item.label"
                                    :value="index"
                                ></v-checkbox>
                            </v-list-item>
                        </v-list>
                    </template>
                </table-header-menu>
            </template>

            <template v-slot:header.videoQueStatus="{ header }">
                <table-header-menu @menu-closed="getRenders">
                    <template v-slot:label>
                        <span>
                            {{ header.text }} ({{ selectedStatuses.length }})
                            <font-awesome-icon icon="fa-solid fa-chevron-down" />
                        </span>
                    </template>
                    <template v-slot:content>
                        <v-list>
                            <v-list-item v-for="(item, index) in queStatusEnum" :key="index" style="height: 20px">
                                <v-checkbox v-model="selectedStatuses" :label="item.label" :value="index"></v-checkbox>
                            </v-list-item>
                        </v-list>
                    </template>
                </table-header-menu>
            </template>

            <template v-slot:header.renderSpeed="{ header }">
                <table-header-menu @menu-closed="getRenders">
                    <template v-slot:label>
                        <span>
                            {{ header.text }} ({{ selectedSpeeds.length }})
                            <font-awesome-icon icon="fa-solid fa-chevron-down" />
                        </span>
                    </template>
                    <template v-slot:content>
                        <v-list>
                            <v-list-item v-for="(item, index) in renderSpeeds" :key="index" style="height: 20px">
                                <v-checkbox v-model="selectedSpeeds" :label="item.label" :value="index"></v-checkbox>
                            </v-list-item>
                        </v-list>
                    </template>
                </table-header-menu>
            </template>

            <template v-slot:item.eventTitle="{ item }">
                <a :href="'/services/' + item.serviceSlug" :title="item.eventTitle">{{ item.eventTitle }}</a>
            </template>

            <template v-slot:item.engineType="{ item }">
                <v-chip dark :color="renderEngineTypes[item.engineType].color">
                    {{ renderEngineTypes[item.engineType].label }}
                </v-chip>
            </template>
            <template v-slot:item.renderSpeed="{ item }">
                <v-chip dark :color="renderSpeeds[item.renderSpeed].color">
                    {{ renderSpeeds[item.renderSpeed].label }}
                </v-chip>
            </template>
            <template v-slot:item.videoQueType="{ item }">
                <div v-if="item.hevc">
                    <v-badge color="#0d3d60" overlap>
                        <v-chip dark :color="renderTypes[item.videoQueType].color">
                            {{ renderTypes[item.videoQueType].label }}
                        </v-chip>
                        <template v-slot:badge>
                            <span>HEVC</span>
                        </template>
                    </v-badge>
                </div>
                <div v-else>
                    <v-chip dark :color="renderTypes[item.videoQueType].color">
                        {{ renderTypes[item.videoQueType].label }}
                    </v-chip>
                </div>
            </template>
            <template v-slot:item.videoQueStatus="{ item }">
                <span v-if="item.videoQueStatus >= 0">
                    <div class="text-center" v-if="item.videoQueStatus === 5 || item.videoQueStatus === 7">
                        <v-chip class="mt-5" dark :color="queStatusEnum[item.videoQueStatus].color">
                            {{ queStatusEnum[item.videoQueStatus].label }}
                        </v-chip>

                        <v-progress-linear class="mt-2 mb-2" rounded indeterminate></v-progress-linear>
                    </div>
                    <div v-else>
                        <v-chip dark :color="queStatusEnum[item.videoQueStatus].color">
                            {{ queStatusEnum[item.videoQueStatus].label }}
                        </v-chip>
                    </div>
                </span>
            </template>
            <template v-slot:item.createDate="{ item }">
                <span style="font-size: 0.8rem; opacity: 0.7">{{ item.createDate | dateFilter() }}</span>
                <br />
                <span style="font-size: 0.8rem; opacity: 0.7">{{ item.createDate | timeFilter() }}</span>
            </template>

            <template v-slot:item.playbackType="{ item }">
                <v-chip :color="playbackTypes[item.playbackType].color" dark>{{
                    playbackTypes[item.playbackType].label
                }}</v-chip>
            </template>

            <template v-slot:item.completed="{ item }">
                <div>
                    <font-awesome-icon
                        v-if="item.completed"
                        style="font-size: 1.5rem; color: #4caf50"
                        icon="fa-solid fa-circle-check"
                    ></font-awesome-icon>
                    <font-awesome-icon
                        v-else
                        style="font-size: 1.5rem; color: #ff5252"
                        icon="fa-solid fa-circle-xmark"
                    ></font-awesome-icon>
                </div>
            </template>

            <template v-slot:item.actions="{ item }">
                <div class="action-list">
                    <custom-tooltip :tooltipProps="{ bottom: true }">
                        <template v-slot:activator>
                            <span>
                                <font-awesome-icon
                                    icon="fa-regular fa-gear"
                                    @click="edit(item)"
                                    style="font-size: 1rem"
                                />
                            </span>
                        </template>

                        <template v-slot:content>
                            <span>Edit</span>
                        </template>
                    </custom-tooltip>

                    <custom-tooltip :tooltipProps="{ bottom: true }">
                        <template v-slot:activator>
                            <span @click="openDeleteModal(item.id)">
                                <font-awesome-icon icon="fa-regular fa-trash-can" style="font-size: 1rem" />
                            </span>
                        </template>

                        <template v-slot:content>
                            <span>Delete</span>
                        </template>
                    </custom-tooltip>

                    <v-snackbar v-model="snackbar" :timeout="2000">
                        {{ message }}
                    </v-snackbar>
                    <v-dialog v-model="deleteModal" :retain-focus="false" persistent max-width="290">
                        <v-card>
                            <v-card-title class="headline"> Are you sure you want to delete this? </v-card-title>
                            <v-card-text>{{ deleteMessage }}</v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="gdarken-1" text @click="closeDeleteModal()"> cancel </v-btn>
                                <v-btn color="red darken-1" text @click="deleteRender()"> Delete </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </div>
            </template>
        </v-data-table>

        <v-dialog v-model="createOrEditModal" persistent max-width="720">
            <v-card>
                <v-card-title class="headline pl-8">
                    <span>
                        {{ editing ? 'Edit Render' : 'Create Render' }}
                    </span>
                </v-card-title>
                <div>
                    <v-form class="form-container pt-0 pb-0 pl-8 pr-8" ref="form" lazy-validation>
                        <span>ID: {{ selectedRender.id }}</span>
                        <v-divider></v-divider>
                        <div v-if="selectedRender.blobs">
                            <div v-for="(item, index) in selectedRender.expandedBlobs" :key="index">
                                <div class="d-flex justify-space-between align-center">
                                    <div style="width: 85%">
                                        <v-text-field v-if="item.editing" v-model="item.tempUrl"></v-text-field>
                                        <a v-else :href="item.url" target="_blank">{{ item.url }}</a>
                                    </div>

                                    <div class="ml-5 d-flex" style="gap: 12px">
                                        <v-btn
                                            small
                                            depressed
                                            @click="saveBlobEdit(item)"
                                            v-if="item.editing"
                                            color="primary"
                                            >Save</v-btn
                                        >
                                        <v-btn small @click="item.editing = !item.editing" depressed>{{
                                            item.editing ? 'Cancel' : 'Edit'
                                        }}</v-btn>
                                    </div>
                                </div>

                                <v-progress-circular
                                    v-if="currentlyDownloading.includes(item)"
                                    size="15"
                                    indeterminate
                                    color="green"
                                ></v-progress-circular>

                                <v-divider v-if="index != selectedRender.expandedBlobs.length - 1"></v-divider>
                            </div>
                        </div>
                        <v-divider v-if="selectedRender.blobs"></v-divider>

                        <v-row class="mt-0">
                            <v-col class="d-flex" style="gap: 12px" cols="12" md="6">
                                <v-text-field v-if="customVM" v-model="tempVMName" label="VM Name"></v-text-field>
                                <v-select
                                    v-else
                                    v-model="selectedRender.vmName"
                                    :items="vmList"
                                    item-text="name"
                                    item-value="name"
                                    label="VM Name"
                                ></v-select>
                                <v-checkbox v-model="customVM" label="Other"></v-checkbox>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-select
                                    v-model="selectedRender.renderSpeed"
                                    :items="renderSpeeds"
                                    item-text="label"
                                    item-value="value"
                                    label="Speed"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-select
                                    v-model="selectedRender.videoQueType"
                                    :items="videoQuoteType"
                                    item-text="description"
                                    item-value="id"
                                    label="Video Queue Type"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-select
                                    v-model="selectedRender.videoQueStatus"
                                    :items="videoQueStatus"
                                    item-text="description"
                                    item-value="id"
                                    label="Video Queue Status"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field label="Start" v-model="selectedRender.start"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field label="Stop" v-model="selectedRender.stop"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-select
                                    :items="boolSelectItems"
                                    item-text="label"
                                    item-value="value"
                                    label="Has Slate"
                                    v-model="selectedRender.hasSlate"
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-form>
                </div>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="gdarken-1" text @click="cancelEdit()"> cancel </v-btn>
                    <v-btn :disabled="loading" :loading="loading" color="blue darken-1" text @click="editRender()">
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { setLocalSettings, getLocalSettings } from '@/utilities/general';
import VmMasterList from '@/components/Misc/VmMasterList.vue';
import axios from 'axios';
import moment from 'moment';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';
import TableHeaderMenu from '@/components/ui/TableHeaderMenu.vue';
import {
    renderEngineTypes,
    renderSpeeds,
    renderTypes,
    queStatusEnum,
    boolSelectItems,
    playbackTypes,
} from '@/constants';
import { debounceV2 } from '@/utilities/debounce.js';
import Calendar from '@/components/ui/Calendar.vue';

export default {
    metaInfo: {
        title: 'Renders',
    },
    components: { VmMasterList, CustomTooltip, TableHeaderMenu, Calendar },
    data() {
        return {
            playbackTypes,
            renderEngineTypes,
            renderSpeeds,
            renderTypes,
            queStatusEnum,
            boolSelectItems,
            currentlyDownloading: [],
            message: '',
            token: '',
            renders: [],
            videoQuoteType: [],
            videoQueStatus: [],
            vmList: [],
            loading: true,
            snackbar: false,

            selectedRender: {},
            selectedIndex: -1,
            editing: false,
            customVM: false,
            createOrEditModal: false,
            deleteModal: false,
            deleteMessage: '',
            tempVMName: '',
            total: 0,
            search: '',
            selectedRenderTypes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
            selectedStatuses: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
            selectedSpeeds: [0, 1, 2],
            headers: [
                {
                    text: 'Render Machine',
                    value: 'vmName',
                },
                {
                    text: 'Funeral Home',
                    value: 'funeralHomeName',
                },
                {
                    text: 'Event',
                    value: 'eventTitle',
                },
                {
                    text: 'Upload Date',
                    value: 'createDate',
                },
                {
                    text: 'Engine Type',
                    value: 'engineType',
                },
                {
                    text: 'Render Speed',
                    value: 'renderSpeed',
                },
                {
                    text: 'Render Type',
                    value: 'videoQueType',
                },
                {
                    text: 'Status',
                    value: 'videoQueStatus',
                },
                {
                    text: 'Playback Type',
                    value: 'playbackType',
                },
                {
                    text: 'Completed',
                    value: 'completed',
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    width: '20%',
                    align: 'center',
                    sortable: false,
                },
            ],
            options: {
                itemsPerPage: 25,
            },
            tableLoading: true,
            startDate: this.$moment(new Date()).startOf('month').toISOString(),
            endDate: this.$moment(new Date()).add(1, 'days').startOf('day').toISOString(),
            offset: null,
        };
    },
    props: {
        engineTypes: {
            type: Array,
            default: () => [],
        },
        compressionRerenders: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        options: {
            handler() {
                if (this.mounted) {
                    this.debounceGetRenders();
                }
            },
            deep: true,
        },
        search() {
            if (this.mounted) {
                this.debounceGetRenders();
            }
        },
        startDate() {
            if (this.mounted) {
                this.debounceGetRenders();
            }
        },
        endDate() {
            if (this.mounted) {
                this.debounceGetRenders();
            }
        },
    },
    filters: {
        truncate: function (text, length, suffix) {
            if (text && text.length > length) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        },
        dateFilter: function (value) {
            const offset = moment(new Date()).utcOffset();
            return moment.utc(value).utcOffset(offset).format('l');
        },
        timeFilter: function (value) {
            const offset = moment(new Date()).utcOffset();
            return moment.utc(value).utcOffset(offset).format('LT');
        },
    },
    methods: {
        edit(item) {
            const vm = this.vmList.find(v => v.name == item.vmName);

            if (vm) {
                this.selectedRender.vmName == vm.name;
                this.customVM = false;
            } else {
                this.selectedRender.vmName == item.name;
                this.customVM = true;
            }

            const expandedBlobs = item.blobs.split(',').map(blob => {
                return { url: blob, tempUrl: blob, editing: false };
            });
            this.selectedRender = { ...item, expandedBlobs };

            this.editing = true;
            this.createOrEditModal = true;
            //this.selectedRender = this.selectedRender;
        },
        getOffset() {
            this.offset = Math.abs(this.$moment(new Date()).utcOffset() / 60);
        },
        debounceGetRenders: debounceV2(function () {
            this.getRenders();
        }, 500),
        cancelEdit() {
            this.createOrEditModal = false;
            this.customVM = false;
        },
        saveBlobEdit(item) {
            item.url = item.tempUrl;
            item.editing = false;
        },
        getVMList() {
            this.axiosInstance
                .get('/render/vm-list')
                .then(response => {
                    this.vmList = response.data;
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        downloadRender(item) {
            this.currentlyDownloading.push(item);
            axios({
                url: item,
                method: 'GET',
                responseType: 'blob',
                onDownloadProgress: progressEvent => {
                    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    if (percentCompleted === 100) {
                        let filteredDownloads = this.currentlyDownloading.filter(download => download != item);
                        this.currentlyDownloading = filteredDownloads;
                    }
                },
            }).then(response => {
                var file = window.URL.createObjectURL(new Blob([response.data], { type: 'text/plain' }));
                var docUrl = document.createElement('a');

                docUrl.href = file;
                docUrl.setAttribute('download', `${item}.mp4`);
                document.body.appendChild(docUrl);
                docUrl.dispatchEvent(new MouseEvent('click', {}));
            });
        },
        getVideoQueStatus() {
            this.axiosInstance
                .get(`/dropdown/videoquestatus`)
                .then(response => {
                    if (response.data) {
                        this.videoQueStatus = response.data;
                    }
                })
                .catch(error => {
                    // this.errorLoading = true;
                });
        },
        getVideoQuoteType() {
            this.axiosInstance
                .get(`/dropdown/videoquetype`)
                .then(response => {
                    if (response.data) {
                        this.videoQuoteType = response.data;
                    }
                })
                .catch(error => {});
        },
        getRenders() {
            this.loading = true;

            const { sortBy, sortDesc, page, itemsPerPage } = this.options;

            const renderTypeParamString = this.selectedRenderTypes.map(type => `renderType=${type}`).join('&');
            const renderStatusParamString = this.selectedStatuses.map(status => `renderStatus=${status}`).join('&');
            const renderSpeedParamString = this.selectedSpeeds.map(speed => `renderSpeed=${speed}`).join('&');

            const fullParamString = [renderTypeParamString, renderStatusParamString, renderSpeedParamString].join('&');

            var params = {
                pageSize: itemsPerPage,
                pageNumber: page ? page - 1 : 1,
                sortBy: sortBy && sortBy[0] ? this.getSortByValue(sortBy[0]) : null || null,
                sortAsc: sortBy && sortBy[0] ? !sortDesc[0] : null || null,
                search: this.search,
                startDate: this.startDate,
                endDate: this.endDate,
                offset: this.offset,
            };

            var endpoint = `/admin-tools/render-history?` + fullParamString;

            this.axiosInstance
                .get(endpoint, { params })
                .then(response => {
                    if (response.data) {
                        this.renders = response.data.renders;
                        this.total = response.data.total;
                        this.loading = false;
                        this.mounted = true;
                    }
                })
                .catch(error => {
                    // this.errorLoading = true;
                })
                .then(() => {
                    this.loading = false;
                });
        },
        deleteRender() {
            this.deleteMessage = '';
            this.axiosInstance
                .delete(`/Render/${this.selectedId}`)
                .then(result => {
                    this.message = `${this.renders[this.selectedIndex].id} was deleted`;
                    this.getRenders();
                })
                .catch(error => {
                    this.message = `Error deleting ${this.renders[this.selectedIndex].id}. Please try again`;
                })
                .then(() => {
                    this.snackbar = true;
                });

            this.deleteModal = false;
        },
        openDeleteModal(item) {
            this.deleteMessage = `Are you sure you want to delete this?`;

            this.selectedIndex = this.renders.findIndex(i => i.id === item);
            this.deleteModal = true;
            this.selectedId = item;
        },
        closeDeleteModal() {
            this.deleteModal = false;
        },
        getSortByValue(value) {
            switch (value) {
                case 'eventTitle':
                    return 'EventTitle';
                case 'id':
                    return 'Id';
                case 'createDate':
                    return 'CreateDate';
                case 'vmName':
                    return 'VmName';
                case 'renderSpeed':
                    return 'RenderSpeed';
                case 'engineType':
                    return 'EngineType';
                case 'videoQueStatus':
                    return 'VideoQueStatus';
                case 'videoQueEnum':
                    return 'VideoQueEnum';
                case 'funeralHomeName':
                    return 'FuneralHomeName';
                case 'completed':
                    return 'Completed';
                case 'playbackType':
                    return 'PlaybackType';

                default:
                    console.warn('No cases found in switch statement.');
                    return '';
            }
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInsatance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
    },
    async mounted() {
        await this.setAuthToken();
        this.createAxiosInsatance();

        this.getRenders();
        this.getVideoQuoteType();
        this.getVideoQueStatus();
        this.getVMList();
    },
};
</script>
<style>
/* .vm-list {
    min-width: 350px;
}
@media only screen and (max-width: 768px) {
    .vm-list {
        width: 100%;
    }
} */
</style>
