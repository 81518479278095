<template>
    <div style="width: 100%; height: 100%" id="v2-uploader-container">
        <div style="position: relative; width: 100%; height: 100%" class="v2-uploader" :id="'v2-uploader' + uploaderId">
            <div v-if="customCta">
                <slot></slot>
            </div>
            <div @click="openFileSelection()" v-else class="d-flex flex-column p-3 default-cta">
                <font-awesome-icon
                    stroke="#7A8187"
                    style="font-size: 4rem; margin-bottom: 8px"
                    icon="fa-regular fa-cloud-arrow-up"
                />
                <p class="text-center text-body-light" style="font-weight: 500; font-size: 14px">
                    <span style="color: #2096f3">Click</span> or Drag and Drop
                    {{ max == 1 ? 'Photo' : 'Photos/Videos' }}
                </p>
            </div>
        </div>
        <input type="file" :id="'uppy-file-input' + uploaderId" style="display: none" />
        <div v-if="disabled" class="disabled-overlay"></div>

        <v-dialog max-width="500px" v-model="uploadConfirmationModal">
            <v-card>
                <v-card-title> Continue Upload? </v-card-title>

                <v-card-text
                    >The current tribute video will need to be rerendered in order to include any new uploads. Are you
                    sure you want to continue?</v-card-text
                >
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn depressed @click="cancelUpload">Cancel</v-btn>
                    <v-btn depressed dark color="orange" @click="confirmUpload">Continue</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import QrcodeVue from 'qrcode.vue';
import Uppy from '@uppy/core';
import DropTarget from '@uppy/drop-target';
import FileInput from '@uppy/file-input';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import heic2any from 'heic2any';
import { checkHeicFtyp } from '@/utilities/imageProcessing';

import '@uppy/core/dist/style.css';
import '@uppy/drop-target/dist/style.css';

export default {
    data() {
        return {
            uppy: null,
            dragging: false,
            tempFiles: [],
            deeplink: null,
            dragDropProps: {
                id: 'DragDrop',
                target: '#testdrop-zone',
                height: '100%',
                width: '100%',
                allowMultipleUploads: true,
                restrictions: {
                    minNumberOfFiles: 1,
                    maxNumberOfFiles: this.max ? this.max : 999,
                    // only allow image file types supported by creatomate
                    // https://creatomate.com/docs/json/elements/image-element
                    allowedFileTypes: ['.jpg', '.jpeg', '.png', '.svg', '.heic'],
                },
            },
            isOpen: false,
            images: [],
            dragDropInstance: null,
            uploadConfirmationModal: false,
            alreadyConfirmed: false,
        };
    },
    props: {
        eventId: {
            type: Number,
            required: true,
        },
        tributeToken: {
            type: String,
            required: false,
        },
        max: {
            type: Number,
            default: 999,
        },
        requiresConfirm: {
            type: Boolean,
            default: false,
        },
        customCta: {
            type: Boolean,
            default: false,
        },
        videoAllowed: {
            type: Boolean,
            default: true,
        },
        dashboard: {
            type: Boolean,
            default: false,
        },
        uploaderId: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            defalut: false,
        },
    },
    components: {
        QrcodeVue,
    },
    computed: {
        tributeVideo() {
            return this.$store.state.tributeVideo;
        },
    },
    watch: {
        tempFiles() {
            if (this.tempFiles.length > 0) {
                if (this.max == 1) {
                    this.$emit('confirm', this.tempFiles[0]);
                } else if (!this.dashboard) {
                    if (this.requiresConfirm && !this.alreadyConfirmed) {
                        this.initConfirmUploadModal();
                    } else {
                        this.initDragDropUpload();
                    }
                }
            }
            this.$emit('current-files', this.tempFiles);
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        ...mapActions('tributeVideo', ['updateTributeVideo', 'updateUploadingPhotos']),
        confirmUpload() {
            this.uploadConfirmationModal = false;
            this.alreadyConfirmed = true;
            this.initDragDropUpload();
        },
        cancelUpload() {
            this.reset();
            this.uploadConfirmationModal = false;
        },
        initConfirmUploadModal() {
            this.uploadConfirmationModal = true;
        },
        openFileSelection() {
            const input = this.uppy.getPlugin('FileInput');
            if (input) {
                input.handleClick();
            }
        },
        convertHeicToJpeg(blobUrl) {
            return fetch(blobUrl)
                .then(res => res.blob())
                .then(blob => heic2any({ blob }))
                .then(conversionResult => {
                    return conversionResult;
                })
                .catch(e => {
                    console.log(e, 'conversion error');
                });
        },
        reset() {
            if (this.uppy) {
                this.uppy.cancelAll();
            }
        },
        addFiles(files) {
            if (this.uppy) {
                files.forEach(file => {
                    this.uppy.addFile(file);
                });
            }
        },
        removeFile(item) {
            let files = this.uppy.getFiles();
            let found = files.find(x => x.id == item.id);
            if (found) {
                this.uppy.removeFile(found.id);
            }
        },
        getFileURL(file) {
            if (file?.data) {
                return URL.createObjectURL(file.data);
            } else {
                return '';
            }
        },
        openFileExplorer() {
            const fileInput = document.createElement('input');
            fileInput.type = 'file';
            fileInput.multiple = true; // Allow multiple file selection
            fileInput.accept = this.dragDropProps.allowedFileTypes;

            // Add a change event listener to handle selected files
            fileInput.addEventListener('change', event => {
                const files = event.target.files;
                this.handleSelectedFiles(files);
                // Process the selected files here, e.g., upload them to a server
            });

            // Trigger the click event to open the file browser
            fileInput.click();
        },
        handleSelectedFiles(files) {
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                this.uppy.addFile({
                    id: file.name,
                    name: file.name,
                    type: file.type,
                    data: file,
                });
            }
        },
        getTributeVideo(id) {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/TributeVideo/get-tribute-by-event/${id}`)
                .then(response => {
                    this.deeplink = response.data.deeplink;
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        removeTemp(file) {
            this.uppy.removeFile(file.id);
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            return (this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            }));
        },
        async uploadToEndpoint(file, endpoint, onProgressUpdate) {
            return new Promise((resolve, reject) => {
                var reader = new FileReader();
                reader.readAsArrayBuffer(file.data);
                reader.addEventListener('error', error => {});
                reader.addEventListener('load', async () => {
                    const config = {
                        onUploadProgress: onProgressUpdate,
                    };
                    try {
                        await this.axios
                            .create({
                                headers: {
                                    'Content-Type': file.type,
                                    'x-ms-blob-type': 'BlockBlob',
                                },
                            })
                            .put(endpoint, reader.result, config);
                        resolve('done');
                    } catch (error) {
                        console.error(error);
                        reject(error);
                    }
                });
            });
        },
        handleVideoPosterSrc(url) {
            // img kit docs: https://docs.imagekit.io/features/image-transformations

            //const imgKitBase = 'https://ik.imagekit.io/memoryshare/';

            if (url) {
                const imgPath = url.split('/tribute-photos/')[1];

                return process.env.VUE_APP_IMG_KIT_BASE + imgPath + '/ik-thumbnail.jpg';
            }

            return url;
        },
        getUploadUrl(file) {
            // const eventId = this.event.id;
            return this.axiosInstance
                .get(`/TributeVideoPhoto/sas/${this.eventId}/${file.name}`)
                .then(response => {
                    // log('==== getUploadUrl() called ====', 'silver');
                    // 'response.data', response.data;
                    return response.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        checkRemainingAllowedUploads(id) {
            return this.axiosInstance
                .get(`/TributeVideoPhoto/remaining-allowed-uploads/${id}`)
                .then(response => {
                    // log('==== getUploadUrl() called ====', 'silver');
                    // 'response.data', response.data;
                    return response.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        async initDragDropUpload(replaceMainPhoto = false) {
            if (this.disabled) {
                return;
            }

            if (this.max != 1 && replaceMainPhoto == true) {
                this.showSnackbar({ message: 'Cannot replace main photo at this time', color: 'error' });
                return;
            }

            //requires confirm only true for initial photo upload
            //before initial photo upload, tribute not created in db so no max will return
            if (!this.requiresConfirm) {
                var remainingAllowed = await this.checkRemainingAllowedUploads(this.eventId);

                this.updateTributeVideo({ maxItems: remainingAllowed.max });
                if (remainingAllowed.remaining < this.tempFiles.length) {
                    this.showSnackbar({
                        message: `Cannot exceed remaining allowed uploads: ${remainingAllowed.remaining}`,
                        color: 'error',
                    });
                    this.$emit('upload-cancelled');
                    return;
                }
            }

            this.updateUploadingPhotos(true);
            this.$emit('upload-start', this.tempFiles);
            this.loading = true;
            let totalCount = this.tempFiles.length;
            let loadedCount = 0;
            let remaining = totalCount;
            var currentUpload = null;

            if (!this.requiresConfirm) {
                this.updateTributeVideo({
                    incomingUploadCount: totalCount,
                });
            }

            const totalBytes = this.tempFiles.reduce((acc, file) => acc + file.size, 0);
            var uploadedBytes = 0;

            for (let i = 0; i < this.tempFiles.length; i++) {
                try {
                    let SELECTED_FILE = this.tempFiles[i];

                    this.$emit('file-upload-started', SELECTED_FILE);

                    let TOTAL_BYTES = SELECTED_FILE.size;

                    let isVideo = this.isVideoFile(SELECTED_FILE.extension);

                    let SAS_INFO = await this.getUploadUrl(SELECTED_FILE);

                    // // Upload to azure storage
                    await this.uploadToEndpoint(SELECTED_FILE, SAS_INFO.sas, ev => {
                        // Emit progress update so Uppy Dashboard shows progress bar
                        this.uppy.emit('upload-progress', SELECTED_FILE, {
                            uploader: this,
                            bytesUploaded: ev.loaded,
                            bytesTotal: ev.total,
                            percentage: (ev.loaded / ev.total) * 100,
                            uploadComplete: ev.total < ev.loaded ? false : true,
                            uploadStarted: ev.total > 0 ? true : false,
                        });

                        const currentBytes = uploadedBytes + ev.loaded;
                        const percent = Math.round((currentBytes / totalBytes) * 100);
                        this.$emit('progress', percent);
                    });

                    let data = {
                        duration: 0,
                        mediaType: 0,
                        uploadSource:
                            this.$route.name == 'TributeUploadPage' || this.$route.name == 'TributeFamilyPage' ? 2 : 0,
                        uploadUserName:
                            this.$route.name == 'TributeUploadPage' || this.$route.name == 'TributeFamilyPage'
                                ? 'Unknown'
                                : this.$auth.user.name,
                        uploadUserRelationship:
                            this.$route.name == 'TributeUploadPage' || this.$route.name == 'TributeFamilyPage'
                                ? 'Unknown'
                                : 'Funeral Staff',
                        url: SAS_INFO.sas.split('?sv=')[0],
                        uniqueName: SAS_INFO.fileName,
                        name: SELECTED_FILE.name,
                    };

                    if (isVideo) {
                        data.duration = await this.getVideoDuration(SELECTED_FILE);
                        data.mediaType = 1;

                        //Generate imagekit thumbail
                        try {
                            const response = await fetch(this.handleVideoPosterSrc(dbPhoto.url));
                        } catch (err) {}
                    }

                    const result = await this.uploadDbPhoto(replaceMainPhoto, data);

                    if (result) {
                        this.$emit('upload-success-result', result);
                        loadedCount++;
                    }

                    remaining = totalCount - loadedCount;
                    this.$emit('upload-success', remaining);
                    this.updateTributeVideo({
                        incomingUploadCount: remaining,
                    });

                    uploadedBytes += SELECTED_FILE.size;
                } catch (err) {
                    if (err.response.data && typeof err.response.data === 'string') {
                        this.showSnackbar({ message: err.response.data, color: 'error' });
                    } else {
                        this.showSnackbar({ message: 'An error occured during the upload', color: 'error' });
                    }

                    this.$emit('close');
                    return;
                }
            }
            this.loading = false;

            this.updateUploadingPhotos(false);

            this.$emit('all-uploads-complete');
            this.uppy.cancelAll();
        },
        async uploadDbPhoto(replaceMainPhoto, data) {
            return this.axiosInstance
                .post(`/TributeVideoPhoto/${this.eventId}?replaceMain=${replaceMainPhoto}`, data)
                .then(resp => {
                    return resp.data;
                });
        },
        deletePhoto(item) {
            if (this.tributeVideo.mainPhotoId == item.id) {
                this.showSnackbar({
                    message: 'Cannot delete main photo, please update main photo to continue',
                    color: 'error',
                });
                return;
            }

            this.axiosInstance
                .delete(process.env.VUE_APP_API + `/TributeVideoPhoto/${item.id}`)
                .then(res => {})
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        updateOverallProgress(loadedCount, totalCount) {
            const progress = Math.round((loadedCount / totalCount) * 100);
            this.$emit('progress', progress);
        },
        getVideoDuration(file) {
            return new Promise((resolve, reject) => {
                const video = document.createElement('video');
                video.preload = 'metadata';

                video.onloadedmetadata = () => {
                    window.URL.revokeObjectURL(video.src);
                    const duration = video.duration;
                    resolve(Math.round(duration));
                };

                video.onerror = () => {
                    reject(new Error('Failed to retrieve video duration.'));
                };

                video.src = URL.createObjectURL(file.data);
            });
        },
        updatePhotoFileName(id, fileName) {
            return this.axios
                .create({
                    headers: { Authorization: `Bearer ${this.token}`, 'Content-Type': 'application/json' },
                })
                .put(process.env.VUE_APP_API + `/TributeVideoPhoto/${id}`, JSON.stringify(fileName))
                .then(res => {})
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        isVideoFile(fileExtension) {
            const videoFileTypes = ['mp4', 'mov', 'avi', 'mkv', 'wmv', 'flv', 'webm'];

            // Check if the file extension is included in the videoFileTypes array
            return videoFileTypes.includes(fileExtension.toLowerCase());
        },
        initUppy() {
            this.uppy = new Uppy({
                restrictions: {
                    minNumberOfFiles: 1,
                    maxNumberOfFiles: this.max ? this.max : 999,
                    allowedFileTypes: this.dragDropProps.allowedFileTypes,
                },
            })
                .use(DropTarget, {
                    target: '#v2-uploader' + this.uploaderId,
                    onDragOver: event => {
                        if (this.dragging == false) {
                            this.dragging = true;
                        }
                    },
                    onDragLeave: event => {
                        if (this.dragging) {
                            this.dragging = false;
                        }
                    },
                    onDrop: event => {
                        if (this.dragging) {
                            this.dragging = false;
                        }
                    },
                })
                .use(ThumbnailGenerator, { thumbnailHeight: 300 })
                .use(FileInput, {
                    target: '#uppy-file-input' + this.uploaderId,
                    pretty: false,
                })

                .on('file-added', async file => {
                    this.tempFiles = [...this.tempFiles, { ...file, loading: true }];

                    let url;
                    const isHeic = await checkHeicFtyp(file.data);

                    if (file.extension.toLocaleLowerCase() == 'heic' || isHeic) {
                        const heicUrl = this.getFileURL(file);
                        const converted = await this.convertHeicToJpeg(heicUrl);
                        url = URL.createObjectURL(converted);

                        let data = { file, preview: url };
                        this.$emit('preview-ready', data);
                    }
                })
                .on('file-removed', file => {
                    let tempFound = this.tempFiles.find(x => x.id == file.id);
                    if (tempFound) {
                        let index = this.tempFiles.indexOf(tempFound);
                        this.tempFiles.splice(index, 1);
                    }
                })
                .on('upload-progress', (file, progress) => {
                    this.$emit('file-upload-progress', { file, progress });
                })
                .on('thumbnail:generated', (file, preview) => {
                    let data = { file, preview };
                    this.$emit('preview-ready', data);
                })
                .on('restriction-failed', (file, error) => {
                    this.showSnackbar({
                        // message: `${file.name} contains invalid extenstion type: ${file.extension}`,
                        message: `${file.name} : ${error}`,
                        color: 'red',
                        timeout: 4000,
                    });
                });
        },
        destroyUppy() {
            if (this.uppy) {
                this.uppy.close();
            }
        },
        mountTextOverlay() {
            const overlay = document.getElementById('upload-overlay-text');
            if (overlay) {
                overlay.remove();
                document.body.appendChild(overlay);
            }
        },
        destroyTextOverlay() {
            const overlay = document.getElementById('upload-overlay-text');
            if (overlay) {
                overlay.remove();
            }
        },
    },
    async created() {
        if (this.tributeToken) {
            this.token = this.tributeToken;
        } else {
            await this.setAuthToken();
        }
        this.createAxiosInstance();
    },
    mounted() {
        //Block video uploads for main photo
        if (!this.videoAllowed) {
            this.dragDropProps.allowedFileTypes = ['.jpg', '.jpeg', '.png', '.svg', '.heic'];
        } else {
            this.dragDropProps.allowedFileTypes = ['.jpg', '.jpeg', '.png', '.svg', '.mp4', '.mov', '.heic'];
        }

        this.initUppy();

        this.mountTextOverlay();
    },
    beforeDestroy() {
        if (this.uppy) {
            this.uppy.close();
        }
        this.destroyTextOverlay();
    },
};
</script>
<style lang="scss">
#v2-uploader-container {
    position: relative;

    .disabled-overlay {
        background-color: rgba(74, 74, 74, 0.494);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: -10px;
        cursor: not-allowed;
        border-radius: 5px;
    }
}
.v2-uploader.uppy-is-drag-over::after {
    // background-color: transparent;
    // border: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: rgba(234, 234, 234, 0.5) !important;
    border: 3px dashed #bbb !important;
    border-radius: 5px;
}

#v2-uploaderfamily.v2-uploader.uppy-is-drag-over::after {
    border-radius: 32px !important;
}
#v2-uploadermainPhotoUploader.v2-uploader.uppy-is-drag-over::after {
    border-radius: 32px !important;
}

.default-cta {
    background-color: #fcfcfc;
    cursor: pointer;
    border-radius: 5px;
}

#upload-overlay-text-v2 {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    background-color: rgba(50, 183, 255, 0.4);
    border: 3px dashed rgb(50, 183, 255);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;
    color: white;
}
</style>
