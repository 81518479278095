<template>
    <v-card>
        <v-card-title class="text-h5"> Super Admin Render Edit </v-card-title>
        <v-card-text v-if="loaded">
            <form class="super-container" @submit.prevent="handleFormSubmission">
                <v-text-field readonly label="Id" v-model="formData.id" />

                <v-text-field readonly label="Tribute Video Id" v-model="formData.tributeVideoId" />

                <v-text-field readonly label="Funeral Home Id" v-model="formData.funeralHomeId" />

                <v-text-field readonly label="Service Id" v-model="formData.serviceId" />

                <v-text-field readonly label="Event Id" v-model="formData.eventId" />

                <v-text-field readonly label="Owner Id" v-model="formData.ownerId" />

                <v-text-field readonly label="Cosmos Id" v-model="formData.cosmosId" />

                <v-text-field label="Url" v-model="formData.url" />

                <calendar disabled label="Create Date" v-model="formData.createDate" />

                <calendar label="Update Date" datetime v-model="formData.updateDate" />

                <v-select
                    :items="statuses"
                    item-text="label"
                    item-value="value"
                    label="Status"
                    v-model="formData.status"
                ></v-select>

                <v-select
                    item-text="text"
                    item-value="value"
                    :items="boolSelectOptions"
                    label="Out Of Sync"
                    v-model="formData.outOfSync"
                ></v-select>

                <v-text-field label="Hls Guid" v-model="formData.hlsGuid" />
                <v-text-field label="Hls Url" v-model="formData.hlsUrl" />
                <v-select
                    label="Storage Type"
                    v-model="formData.storageType"
                    :items="storageTypes"
                    item-text="label"
                    item-value="value"
                    disabled
                ></v-select>

                <v-select
                    :items="hlsStatuses"
                    v-model="formData.hlsStatus"
                    item-text="label"
                    item-value="value"
                    label="Hls Status"
                />

                <v-text-field readonly label="Title" v-model="formData.title" />
                <v-btn block color="#0C3C60" dark large type="submit">Save</v-btn>
            </form>
        </v-card-text>
    </v-card>
</template>

<script>
import Calendar from '@/components/ui/Calendar.vue';
import { creatomateRenderStatuses, storageTypes, hlsStatuses } from '@/constants';

export default {
    props: {
        render: {
            type: Object,
            required: true,
        },
    },
    components: {
        Calendar,
    },
    data() {
        return {
            statuses: creatomateRenderStatuses,
            storageTypes,
            hlsStatuses,
            token: null,
            formData: {},
            loaded: false,
            boolSelectOptions: [
                { text: 'True', value: true },
                { text: 'False', value: false },
            ],
        };
    },

    methods: {
        /**
         * Veutify automatically takes text input and converst to string. This is not ideal
         * as a number needs to be a number type, not a string. This will go through and convert
         * any string that is a valid number into a number.
         */
        convertIntObj: function (obj) {
            {
                let res = {};
                Object.keys(obj).forEach(key => {
                    if (key === 'pin') {
                        res[key] = obj[key];
                    } else {
                        res[key] = !isNaN(parseFloat(obj[key])) && isFinite(obj[key]) ? parseInt(obj[key]) : obj[key];
                    }
                });
                return res;
            }
        },
        checkForUTC: function (timeString) {
            if (timeString.includes('Z')) return timeString;
            else return timeString + 'Z';
        },
        checkForLocal: function (timeString) {
            if (timeString.includes('Z')) return timeString.replace('Z', '');
            else return timeString;
        },
        handleFormSubmission: function () {
            let result = this.convertIntObj(this.formData);

            // // Convert Time to UTC  String
            // let eventTime = new Date(result.time);
            // let UTCEventTime = new Date(eventTime.getTime() - eventTime.getTimezoneOffset() * 60000).toISOString();
            // result.time = UTCEventTime;

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .put(`${process.env.VUE_APP_API}/TributeVideoRender/admin-edit/${this.render.id}`, result)
                .then(response => {
                    this.$emit('close');
                })
                .catch(error => {});
        },
        refresh() {
            this.$auth.getIdTokenClaims().then(async result => {
                // Get Api Token
                this.token = result.__raw;

                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .get(`${process.env.VUE_APP_API}/TributeVideoRender/admin-edit/${this.render.id}`)
                    .then(response => {
                        this.formData = { ...response.data };
                        this.formData.updateDate = this.checkForUTC(response.data.updateDate);
                        this.formData.createDate = this.checkForUTC(response.data.createDate);
                        this.loaded = true;
                    })
                    .catch(error => {
                        console.log(error, 'error');
                    });
            });
        },
    },
    mounted() {
        this.refresh();
    },
};
</script>

<style lang="scss" scoped>
.super-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
}
</style>
