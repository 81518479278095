<template>
    <div>
        <div class="py-2">
            <v-progress-linear v-if="tributeVideo.loadingPhotos" indeterminate></v-progress-linear>
        </div>

        <div>
            <draggable v-model="photos" animation="300" @change="handleChange($event)">
                <transition-group class="grid" name="flip-transition" tag="ul" type="transition">
                    <div
                        @click="selectPhoto(photo)"
                        class="grid-item"
                        :class="handleSelected(photo)"
                        v-for="photo in photos"
                        :key="imageKey(photo.id)"
                    >
                        <!-- top keywords -->
                        <div style="height: 60px">
                            <div style="display: flex; justify-content: space-between">
                                <span>
                                    {{ photo.id }}
                                </span>
                                <v-btn @click.stop="editImage(photo)" x-small>Edit</v-btn>
                                <v-btn @click.stop="downloadImage(photo)" x-small>
                                    <font-awesome-icon
                                        icon="fa-regular fa-arrow-down-to-bracket"
                                        style="font-size: 0.8rem"
                                    />
                                </v-btn>
                            </div>
                            <v-chip class="mr-1" dark small v-if="photo.event" color="#975DBE">{{
                                photo.event
                            }}</v-chip>
                            <v-chip class="mr-1" dark small v-if="photo.place" color="#009688">{{
                                photo.place
                            }}</v-chip>
                            <v-chip class="mr-1" dark small v-if="photo.hobby" color="#374550">{{
                                photo.hobby
                            }}</v-chip>
                        </div>
                        <!-- top keywords end -->
                        <div class="grid-item-inner">
                            <div class="delete-div">
                                <v-hover v-slot="{ hover }">
                                    <v-badge
                                        :color="hover ? 'error' : 'secondary'"
                                        class="delete-btn"
                                        @click.native="deletePhoto(photo)"
                                    >
                                        <template v-slot:badge>
                                            <font-awesome-icon icon="fa-regular fa-x" />
                                        </template>
                                    </v-badge>
                                </v-hover>
                            </div>

                            <v-img
                                :src="photo.url"
                                :lazy-src="photo.url"
                                aspect-ratio="1"
                                class="grey lighten-2 rotate"
                                id="photo"
                                :id="'photo' + photo.id"
                                @error="updatePhoto"
                            >
                                <template v-slot:placeholder>
                                    <v-row class="fill-height ma-0" align="center" justify="center">
                                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                    </v-row>
                                </template>
                                <template v-slot:default>
                                    <v-row
                                        v-if="photo.loading"
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                    >
                                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>

                            <div class="transparent-div">
                                <span>age: {{ photo.age }}</span>
                                <span>score: {{ photo.qualityScore }}</span>
                            </div>
                        </div>

                        <!-- other keywords start -->
                        <div class="keywords" style="height: 30px">
                            <v-chip class="mr-1" v-for="kw in photo.imageKeywords" x-small color="orange" dark>{{
                                kw.name
                            }}</v-chip>
                        </div>
                        <!-- other keywords end -->
                    </div>
                </transition-group>
            </draggable>
        </div>

        <v-dialog @click:outside="cancelEdit" v-model="imageEditModal" width="600px">
            <v-card dark v-if="imageToEdit != null" class="image-edit-card">
                <div class="image-edit-div">
                    <v-img contain id="editImage" class="rotate" :src="imageToEdit.url"></v-img>
                </div>
                <!-- <div
                    style="
                        position: absolute;
                        top: 0;
                        left: 0;
                        padding: 10px;
                        display: flex;
                        justify-content: space-between;
                        min-width: 100%;
                    "
                >
                    <v-btn @click="animateRotation(imageToEdit, -90)"
                        ><font-awesome-icon icon="fa-solid fa-rotate-left"></font-awesome-icon
                    ></v-btn>
                    <v-btn @click="animateRotation(imageToEdit, 90)"
                        ><font-awesome-icon icon="fa-solid fa-rotate-right"></font-awesome-icon
                    ></v-btn>
                </div> -->
                <div class="edit-btn-group">
                    <v-btn @click="animateRotation(imageToEdit, -90, '#editImage')"
                        ><font-awesome-icon icon="fa-solid fa-rotate-left"></font-awesome-icon
                    ></v-btn>
                    <v-btn color="primary" @click="saveRotation(imageToEdit)">Save</v-btn>
                    <v-btn @click="animateRotation(imageToEdit, 90, '#editImage')"
                        ><font-awesome-icon icon="fa-solid fa-rotate-right"></font-awesome-icon
                    ></v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import draggable from 'vuedraggable';
import { mapActions } from 'vuex';
export default {
    data() {
        return {
            message: 'hi',
            selectedPhoto: {
                id: '',
                order: 1,
            },
            draggedElem: null,
            overElem: null,
            token: '',
            dropIndex: null,
            reorderSortAsc: false,
            imageToEdit: null,
            imageEditModal: false,
            retryCount: 0,
        };
    },
    components: {
        draggable,
    },
    props: {
        event: {
            type: Object,
            required: true,
        },
        service: {
            type: Object,
            required: true,
        },
    },
    computed: {
        photos: {
            get() {
                return this.$store.state.tributeVideo.photos;
            },
            set(value) {
                this.$store.dispatch('updateTributeVideoPhotos', value);
            },
        },
        selectedPhotos: {
            get() {
                return this.$store.state.tributeVideo.selectedPhotos;
            },
            set(value) {
                this.$store.dispatch('updateTributeVideoSelectedPhotos', value);
            },
        },
        tributeVideo() {
            return this.$store.state.tributeVideo;
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        imageKey(id) {
            return `imageKey-${id}${this.retryCount}`;
        },
        updatePhoto(url) {
            if (this.retryCount < 5) {
                setTimeout(() => {
                    let found = this.photos.find(x => x.url == url);
                    let dateString = Date.now();
                    //reset cached image source
                    let clone = { ...found, url: url + '?' + dateString };
                    let index = this.photos.indexOf(found);

                    this.photos.splice(index, 1, clone);
                    this.retryCount++;
                }, 1000);
            }
        },
        getPhotos(id) {
            this.$store.dispatch('updateTributeVideo', { ...this.tributeVideo, loadingPhotos: true });
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(
                    process.env.VUE_APP_API +
                        `/TributeVideoPhoto/event-photos/${id}?pageNumber=${this.tributeVideo.photosOptions.pageNum}&pageSize=${this.tributeVideo.photosOptions.pageSize}`,
                )
                .then(response => {
                    const photoList = response.data.photos;
                    this.$store.dispatch('updateTributeVideo', { ...this.tributeVideo, loadingPhotos: false });
                    this.$store.dispatch('updateTributeVideoPhotos', photoList);
                })
                .catch(error => {
                    [console.log(error, 'error')];
                });
        },
        cancelEdit() {
            // this.animateRotation(this.imageToEdit, -Math.abs(this.imageToEdit.currentRotation), '#editImage');
            this.imageEditModal = false;
            this.imageToEdit.currentRotation = 0;
        },
        editImage(photo) {
            this.imageToEdit = { ...photo, currentRotation: 0 };
            this.imageEditModal = true;
        },
        async getUploadUrl(name, photo) {
            return this.axiosInstance
                .get(`/TributeVideoPhoto/sas/${this.tributeVideo.eventId}/${name}/${photo.id}`)
                .then(response => {
                    // log('==== getUploadUrl() called ====', 'silver');

                    return response.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        async uploadToEndpoint(file, endpoint) {
            return new Promise((resolve, reject) => {
                var reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.addEventListener('error', error => {});
                reader.addEventListener('load', async () => {
                    try {
                        this.axios
                            .create({
                                headers: {
                                    'Content-Type': 'image/jpg',
                                    'x-ms-blob-type': 'BlockBlob',
                                },
                            })
                            .put(endpoint, reader.result)
                            .then(response => {
                                resolve('done');
                            });
                    } catch (error) {
                        console.error(error);
                        reject(error);
                    }
                });
            });
        },
        replacePhoto(id, fileName) {
            return this.axios
                .create({
                    headers: { Authorization: `Bearer ${this.token}`, 'Content-Type': 'application/json' },
                })
                .put(process.env.VUE_APP_API + `/TributeVideoPhoto/replace-photo/${id}`, JSON.stringify(fileName))
                .then(res => {})
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        animateRotation(photo, deg, selector) {
            const el = document.querySelector(selector);
            photo.currentRotation += deg;
            el.style.transform = `rotate(${photo.currentRotation}deg)`;
        },
        saveRotation(photo) {
            let deg = photo.currentRotation;
            this.rotateImage(photo, deg);

            this.imageEditModal = false;
            this.imageToEdit = null;
            photo.currentRotation = 0;
        },
        rotateImage(photo, deg) {
            let img = new Image();
            img.src = photo.url;
            img.crossOrigin = 'anonymous';

            // Create a canvas object.
            let canvas = document.createElement('canvas');
            // let canvas = document.getElementById('myCanvas');

            img.onload = async () => {
                let ctx = canvas.getContext('2d');

                if (deg % 180 == 0) {
                    //Height and width stay the same for full turn
                    canvas.width = img.width;
                    canvas.height = img.height;
                } else {
                    // swap width and height to keep aspect ratio w/ 90 deg rotation
                    canvas.width = img.height;
                    canvas.height = img.width;
                }

                // center canvas context for rotation
                ctx.translate(canvas.width / 2, canvas.height / 2);

                // clockwise in radians. You can use degree * Math.PI / 180 to calculate a radian from a degree.
                ctx.rotate((deg * Math.PI) / 180);
                ctx.drawImage(img, -img.width / 2, -img.height / 2);

                // clean filename
                var sliced = photo.name.split('_').slice(7);
                var name = sliced.join('_');

                // Convert to blob and upload
                canvas.toBlob(async blob => {
                    const file = new File([blob], name);
                    let SAS_INFO = await this.getUploadUrl(name, photo);

                    // replace photo with placeholder to handle image cache
                    let found = this.photos.find(x => x.id == photo.id);
                    let placeholder = { ...found, loading: true, url: '' };
                    let index = this.photos.indexOf(found);
                    this.photos.splice(index, 1, placeholder);

                    //remove from selectedPhotos
                    let selectedFound = this.selectedPhotos.find(x => x.id == photo.id);
                    if (selectedFound) {
                        let selIndex = this.selectedPhotos.indexOf(selectedFound);
                        this.selectedPhotos.splice(selIndex, 1);
                    }

                    await this.uploadToEndpoint(file, SAS_INFO.sas);

                    await this.replacePhoto(photo.id, SAS_INFO.fileName);
                    this.showSnackbar({ message: 'Photo saved' });

                    //allow azure blob to be available before loading replaced image image
                    // setTimeout(async () => {
                    //     // await this.replacePhoto(photo.id, SAS_INFO.fileName);
                    // }, 1000);
                });
            };
        },
        downloadImage(photo) {
            var sliced = photo.name.split('_').slice(7);
            var name = sliced.join('-');

            fetch(photo.url)
                .then(resp => resp.blob())
                .then(blobObject => {
                    const blob = window.URL.createObjectURL(blobObject);
                    const anchor = document.createElement('a');
                    anchor.style.display = 'none';
                    anchor.href = blob;
                    anchor.download = name;
                    document.body.appendChild(anchor);
                    anchor.click();
                    window.URL.revokeObjectURL(blob);
                    this.showSnackbar({ message: 'Downloading' });
                })
                .catch(error => {
                    console.log(error, 'error');
                    this.showSnackbar({ message: 'Error downloading image', color: 'red' });
                });
        },
        handleSelected(item) {
            const found = this.selectedPhotos.find(x => x.id == item.id);
            if (found) {
                return 'selected';
            }
        },
        selectPhoto(item) {
            const found = this.selectedPhotos.find(x => x.id == item.id);

            if (found) {
                const index = this.selectedPhotos.indexOf(found);
                this.selectedPhotos.splice(index, 1);
            } else {
                this.selectedPhotos.push(item);
            }

            this.$store.dispatch('updateTributeVideoSelectedPhotos', this.selectedPhotos);
        },
        async handleChange(e) {
            let targetEl = null;
            var movedEl = e.moved.element;

            if (e.moved.newIndex < e.moved.oldIndex) {
                targetEl = this.photos[e.moved.newIndex + 1];
            } else {
                targetEl = this.photos[e.moved.newIndex - 1];
            }
            const targetOrder = targetEl.order;

            await this.updateOrder(movedEl.id, targetOrder);

            this.getPhotos(this.event.id);
        },
        deletePhoto(item) {
            if (this.tributeVideo.mainPhotoId == item.id) {
                this.showSnackbar({
                    message: 'Cannot delete main photo, please update main photo to continue',
                    color: 'error',
                });
                return;
            }

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .delete(process.env.VUE_APP_API + `/TributeVideoPhoto/${item.id}`)
                .then(res => {
                    this.getPhotos(this.event.id);
                    this.$emit('refresh-photos', this.event.id);
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        updateOrder(id, index) {
            return this.axiosInstance.put(`TributeVideoPhoto/update-order/${id}/${index}`);
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
    },

    async created() {
        await this.setAuthToken();
        this.createAxiosInstance();
    },
};
</script>
<style lang="scss" scoped>
.edit-btn-group {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.image-edit-card {
    position: relative;
    overflow: hidden;

    .image-edit-div {
        width: 600px;
        height: 600px;
        // border: 2px solid red;
        // padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        #editImage {
            // border: 2px solid blue;
            max-width: 600px;
            max-height: 600px;
        }
    }
}
.rotate {
    transition: transform 0.3s ease-in-out;
}
.selected {
    border: 2px solid #1877f2 !important;
    background-color: #ecf3fb !important;
}
.flip-transition-move {
    // transition: all 0.5s;
}

.delete-div {
    position: absolute;
    padding-left: 9px;
    padding-top: 4px;
    z-index: 5;
    transition: 0.5s;
}
.transparent-div {
    position: absolute;
    right: 0;
    bottom: 0;
    padding-top: 4px;
    z-index: 5;
    color: white;
    background: rgba(0, 0, 0, 0.785);
    font-size: 0.8rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0 5px;
    border-radius: 5px 0 0 0;
}
.grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, auto));
    grid-auto-rows: minmax(auto, 200px);
    justify-content: center;

    padding: 10px;
    gap: 10px;
}
.grid-item {
    cursor: pointer;
    height: 290px;
    width: 200px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #eee;
    .grid-item-inner {
        position: relative;
        max-width: 100%;
        max-height: 100%;
    }
}
#photo {
    transition: transform 0.5s;
}
.keywords {
    transition: transform 0.5s;
}

.delete-btn {
    cursor: pointer;
    opacity: 0;
    transition: 0.3s;
}

.grid-item:hover .delete-btn {
    opacity: 1;
    transition: 0.3s;
}
</style>
