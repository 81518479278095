<template>
    <div>
        <div id="audio-player-container">
            <div v-if="!scrubberOnly" class="d-flex align-center">
                <!-- <div v-if="selectedAudio.artwork" class="py-2 mr-2">
                    <img
                        v-if="selectedAudio.artwork"
                        style="max-height: 45px; max-width: 45px; border-radius: 5px"
                        :src="selectedAudio.artwork"
                        alt="Album Cover"
                    />
                </div> -->
                <div class="py-2">
                    <font-awesome-icon
                        class="pointer"
                        v-if="!isPlaying"
                        icon="fa-regular fa-circle-play"
                        @click="togglePlaying"
                        style="font-size: 2.5rem; color: #ff530d"
                    />
                    <font-awesome-icon
                        class="pointer"
                        v-else
                        icon="fa-regular fa-circle-pause"
                        @click="togglePlaying"
                        style="font-size: 2.5rem; color: #ff530d"
                    />
                    <!-- <v-btn >{{ isPlaying ? 'Pause' : 'Play' }}</v-btn> -->
                </div>
                <!-- <div class="d-flex flex-column justify-content-center ml-2" v-if="selectedAudio">
                    <span style="font-size: 0.9rem; color: #999">{{ selectedAudio.artist }}</span>
                    <span>{{ selectedAudio.title }}</span>
                </div> -->
                <!-- <v-spacer></v-spacer> -->
            </div>
            <!-- {{ selectedAudio }} -->
            <audio preload="metadata" @progress="updateBuffered" id="audio-source" v-if="source" :src="source"></audio>
            <v-slider
                v-model="currentTime"
                id="seek-slider"
                min="0"
                :max="Math.floor(duration)"
                step="0.1"
                dense
                hide-details
                color="#ff530d"
                track-color="#91a6b6"
                @change="updateCurrentTime"
                @mousedown="handleMousedown"
                @mouseup="handleMouseup"
            ></v-slider>

            <div style="display: flex; justify-content: space-between">
                <div>{{ formatTime(currentTime) }}</div>
                <div>{{ formatTime(remaining) }}</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            currentTime: 0,
            duration: 0,
            test: 1,
            remaining: 0,
            isPlaying: false,
            audioElement: null,
            bufferedAmount: 0,
            sliderValue: 50,
        };
    },
    props: {
        source: {
            type: String,
            default: '',
        },
        selectedAudio: {
            type: Object,
            default: {},
        },
        scrubberOnly: {
            type: Boolean,
            default: false,
        },
        playOnMount: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        source() {
            this.duration = this.audioElement.duration;
            this.remaining = this.audioElement.duration;
            this.isPlaying = false;
        },
    },
    methods: {
        handleMousedown() {
            if (this.isPlaying) {
                this.audioElement.pause();
                this.updateCurrentTime();
            }
        },
        handleMouseup() {
            if (this.isPlaying) {
                this.audioElement.play();
            }
        },

        togglePlaying() {
            if (this.audioElement) {
                if (!this.isPlaying) {
                    this.audioElement.play();
                    this.isPlaying = true;
                } else {
                    this.audioElement.pause();
                    this.isPlaying = false;
                }
                this.$emit('playing', this.isPlaying);
            }
        },
        formatTime(timeInSeconds) {
            const minutes = Math.floor(timeInSeconds / 60);
            const seconds = Math.floor(timeInSeconds % 60);
            const formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
            return formattedTime;
        },
        updateBuffered() {
            const buffered = this.audioElement.buffered;
            if (buffered.length > 0) {
                const bufferedEnd = buffered.end(buffered.length - 1);
                const bufferedPercentage = (bufferedEnd / this.audioElement.duration) * 100;
                this.bufferedAmount = bufferedPercentage.toFixed(2);
            }
        },
        updateCurrentTime() {
            if (this.audioElement) {
                this.audioElement.currentTime = this.currentTime;
            }
        },
    },
    mounted() {
        this.audioElement = document.getElementById('audio-source');

        this.audioElement.addEventListener('timeupdate', () => {
            const current = this.audioElement.currentTime;
            const duration = this.audioElement.duration;
            const remaining = duration - current;

            this.remaining = remaining;
            this.currentTime = current;
        });

        this.audioElement.addEventListener('loadedmetadata', () => {
            this.duration = this.audioElement.duration;
            this.remaining = this.audioElement.duration;

            if (this.playOnMount) {
                this.togglePlaying();
            }
        });
    },
};
</script>
<style lang=""></style>
