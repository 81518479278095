<template>
    <nav class="ms-side-navbar" v-bind:class="{ 'collapsed-menu': collapsedMenu }">
        <button @click="$emit('toggleMenu')" class="mobile-menu-toggle">x</button>

        <div class="logo-container">
            <img
                v-if="this.$store.state.customLogo && this.$store.state.whitelabel"
                :src="this.$store.state.customLogo"
                alt="custom logo"
                class="logo menu-collapse"
            />
            <img v-else :src="require(`@/assets/images/logo.png`)" alt="MemoryShare Logo" class="logo" />
        </div>

        <div v-if="!$auth.role.includes('Supplier')" style="padding: 0 0.5rem; margin-top: 4rem">
            <ul class="ms-side-navbar-link-container" style="flex: 0">
                <router-link
                    :to="{ name: 'CreateService' }"
                    v-if="$auth.role.includes('FuneralHome')"
                    style="text-decoration: none"
                >
                    <li class="menu-entry">
                        <div style="width: 2rem">
                            <font-awesome-icon icon="fa-solid fa-cloud-arrow-up" />
                        </div>
                        <span v-if="!collapsedMenu">Upload New Service</span>
                    </li>
                </router-link>
                <router-link :to="{ name: 'LiveDashboard' }" style="text-decoration: none">
                    <li class="menu-entry">
                        <div style="width: 2rem">
                            <font-awesome-icon icon="fa-solid fa-play" />
                        </div>
                        <strong v-if="!collapsedMenu"><span>Live Dashboard</span></strong>
                    </li>
                </router-link>
            </ul>
        </div>

        <div style="padding: 0 1rem">
            <v-divider />
        </div>
        <perfect-scrollbar>
            <ul class="ms-side-navbar-link-container">
                <div
                    v-for="(entry, index) in filteredMenuData"
                    :key="index"
                    style="text-decoration: none"
                    @mousedown.stop
                    @mousedown.middle="handleMiddle(entry, $event)"
                    @click="handleNavigate(entry)"
                >
                    <li class="menu-entry" v-bind:class="{ selected: determineIfSelected(entry) }">
                        <div style="width: 2rem">
                            <font-awesome-icon :icon="entry.icon" />
                        </div>
                        <span v-if="!collapsedMenu">{{ entry.label }}</span>
                    </li>
                </div>
            </ul>
        </perfect-scrollbar>
    </nav>
</template>

<script>
import { generateMenu } from '@/configs/mainMenu';

export default {
    props: {
        collapsedMenu: {
            type: Boolean,
        },
    },
    data: function () {
        return {};
    },
    methods: {
        determineIfSelected: function (entry) {
            if (entry.selectedWhen.includes(this.$route.name)) return true;
            else return false;
        },
        handleMiddle(entry, $event) {
            $event.preventDefault();
            if ($event.button == 1) {
                const url = this.$router.resolve({ name: entry.to, params: entry.params });
                window.open(url.href, '_blank');
            }
        },
        handleNavigate: function (entry) {
            if (this.$router.history.current.name == entry.to) {
                return;
            } else if (entry.to == 'TermsAndConditions') {
                const url = this.$router.resolve({ name: entry.to, params: entry.params });
                window.open(url.href, '_blank');
            } else if (entry.label == 'PowerBi') {
                console.log(entry, 'powerbi entry');
                window.open(entry.to, '_blank');
            } else if (entry.to.includes('http')) {
                window.open('http://help.memoryshare.com/', '_blank');
            } else {
                this.$router.push({ name: entry.to, params: entry.params });
                window.localStorage.removeItem('memoryshare-local-settings');
            }
        },
    },
    computed: {
        filteredMenuData: function () {
            var menu = generateMenu(this.$auth).filter(entry => {
                if (entry.to === 'TermsAndConditions' && !this.$auth.session?.owner?.displayTerms) {
                    return false;
                } else if (entry.to === 'GoogleReport' && !this.$store.state.displayKeywordReportNav) {
                    return false;
                } else if (entry.roles.some(role => this.$auth.role.includes(role))) {
                    return true;
                } else {
                    return false;
                }
            });

            return menu;
        },
    },
};
</script>
