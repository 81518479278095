<template>
    <div>
        <v-dialog v-model="dialog" width="600">
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="s" dark v-bind="attrs" v-on="on"> + Add Admin User</v-btn>
            </template>

            <v-card style="padding: 2rem 3rem">
                <h2>New Admin</h2>
                <v-form class="" ref="form" lazy-validation>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="firstName"
                                label="First Name"
                                :rules="[v => !!v || 'First name is required']"
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="lastName"
                                label="Last Name"
                                :rules="[v => !!v || 'Last name is required']"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-text-field
                                :disabled="editing"
                                v-model="email"
                                type="email"
                                label="Email"
                                :rules="[v => !!v || 'Email is required']"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <div class="text-right mt-4">
                        <v-btn @click="dialog = false" text class="mr-4">Cancel</v-btn>
                        <v-btn @click.prevent="createUser()" color="primary">Create Admin</v-btn>
                    </div>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    metaInfo: {
        title: 'Create Admin User',
    },
    data() {
        return {
            firstName: '',
            lastName: '',
            email: '',
            token: '',
            dialog: false,
            editing: false,
        };
    },
    methods: {
        ...mapActions(['showSnackbar']),
        createUser() {
            this.$auth.getIdTokenClaims().then(result => {
                this.token = result.__raw;
                this.errorMessage = '';

                let data = {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    phoneNo: '',
                    userType: 2,
                };

                this.axios
                    .create({
                        headers: {
                            Authorization: `Bearer ${this.token}`,
                        },
                    })
                    .post(process.env.VUE_APP_API + '/users/invite', data)
                    .then(response => {
                        this.dialog = false;
                        this.$emit('refresh');
                        this.showSnackbar({ message: 'Admin User Created' });
                    })
                    .catch(error => {
                        console.log(error);
                        this.showSnackbar({ message: 'Error Creating Admin' });
                    })
                    .finally(() => {
                        this.firstName = '';
                        this.lastName = '';
                        this.email = '';
                    });
            });
        },
    },
};
</script>
