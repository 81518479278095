<template>
    <div>
        <div class="mb-6 p-3" :class="event.hidden ? 'light-orange-bg' : ''" v-if="render">
            <div class="d-flex justify-space-between">
                <v-chip v-if="event.hidden" label outlined color="orange" class="mb-2">Hidden</v-chip>
                <v-spacer v-else></v-spacer>

                <v-btn
                    v-if="$auth.role.includes('SuperAdmin')"
                    class="mb-3"
                    :disabled="loading"
                    x-small
                    color="error"
                    @click="deleteRenderStep1()"
                >
                    {{ render.status == 0 || render.status == 1 ? 'Cancel' : 'Delete' }}</v-btn
                >
            </div>
            <div class="text-center" v-if="render.status == 0 || render.status == 1">
                <div class="d-flex justify-center m-5">
                    <custom-loader></custom-loader>
                </div>
                <h5 class="mt-6">Creating Tribute Video. This will take a few minutes.</h5>
            </div>
            <div v-if="render.status == 2">
                <styled-videojs-player :key="refreshKey" :src="render.url" ref="videoPlayer" />

                <div v-if="$auth.role.includes('SuperAdmin') && render.hlsStatus" class="d-flex justify-end py-4">
                    <div class="d-flex">
                        <div class="mr-2">
                            <v-img :src="bunnyLogo" :lazy-src="bunnyLogo" width="100px"></v-img>
                        </div>
                        <v-btn
                            @click="openBunnyDeepLink"
                            small
                            depressed
                            class="cursor-pointer"
                            dark
                            :color="hlsStatuses[render.hlsStatus].color"
                        >
                            <span>
                                {{ hlsStatuses[render.hlsStatus].label }}
                            </span>
                        </v-btn>
                    </div>
                </div>

                <div class="text-center ma-3">
                    <v-btn
                        color="#0d3d60"
                        :dark="!loading"
                        :disabled="loading"
                        @click="downloadTributeVideo(render.url)"
                    >
                        <font-awesome-icon
                            icon="fa-regular fa-arrow-down-to-bracket"
                            style="font-size: 0.9rem"
                            class="mr-2"
                        />
                        <span>Download</span>
                    </v-btn>

                    <v-btn
                        class="ml-2"
                        v-if="service.slug && tributeVideoLink"
                        :disabled="loading"
                        @click="initTributeVideoShareModal"
                    >
                        <font-awesome-icon icon="fa-regular fa-share" style="font-size: 0.9rem" class="mr-2" />
                        <span>Share</span>
                    </v-btn>
                </div>
            </div>
            <div v-if="render.status == 3">
                <v-alert outlined text prominent transition="scale-transition" type="error" icon="mdi-cloud-alert">
                    Unable to create tribute video due to an error, please check settings and try again.
                    <v-divider class="error" style="opacity: 0.22"></v-divider>
                    <small v-if="loading">Please select {{ tributeVideo.minPhotos }} or more photos to retry</small>
                    <div>
                        <v-btn :dark="!loading" :disabled="loading" @click="$emit('submit-render')">Retry</v-btn>
                    </div>
                </v-alert>
            </div>
        </div>

        <!--START: Delete Render Modal -->
        <v-dialog v-model="deleteRenderModal" max-width="400px">
            <v-card class="p-3">
                <h5>Delete Render?</h5>
                <p>This cannot be undone, confirm to continue.</p>

                <div class="d-flex justify-space-between">
                    <v-btn :disabled="loading" depressed @click="deleteRenderModal = false"> Cancel </v-btn>
                    <v-btn :disabled="loading" depressed color="error" @click="deleteRender(tributeVideo.id)"
                        >Confirm</v-btn
                    >
                </div>
            </v-card>
        </v-dialog>
        <!--END: Delete Render Modal -->

        <!-- START: Render download modal -->
        <v-dialog persistent v-model="downloading" max-width="500px">
            <v-card class="p-3">
                <div class="d-flex justify-content-between">
                    <h5>Downloading</h5>
                    <p>{{ downloadProgress }}%</p>
                </div>
                <v-progress-linear rounded v-model="downloadProgress"></v-progress-linear>
                <v-btn class="mt-3" color="error" @click="cancelDownload">Cancel</v-btn>
            </v-card>
        </v-dialog>
        <!-- END: Render download modal -->

        <link-share-modal
            v-if="service.slug && tributeVideoLink"
            :title="`Share ${service.firstName}'s Tribute Video`"
            :link="tributeVideoLink"
            :linkType="'Tribute Video'"
            :qrFileName="`${service.firstName}'s Tribute Video`"
            ref="linkShareModal"
            @submit-invites="contacts => handleInviteSubmit(contacts)"
            @invalidEmail="email => handleInvalidEmail(email)"
        ></link-share-modal>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import CustomLoader from '@/components/ui/CustomLoader.vue';
import StyledVideojsPlayer from '@/components/videojs/StyledVideojsPlayer.vue';
import LinkShareModal from '@/components/ui/LinkShareModal.vue';
import bunnyLogo from '@/assets/images/bunny_logo.png';
import { hlsStatuses } from '@/constants';

export default {
    data() {
        return {
            hlsStatuses,
            bunnyLogo,
            deleteRenderModal: false,
            loading: false,
            reloadCount: 0,
            downloading: false,
            downloadProgress: 0,
            downloadCancelToken: null,
            testLoad: true,
            refreshKey: 0,
            tributeVideoLink: '',
        };
    },
    props: {
        event: {
            type: Object,
            required: true,
        },
        service: {
            type: Object,
            required: true,
        },
        render: {
            type: Object,
            default: {},
        },
    },
    components: {
        CustomLoader,
        StyledVideojsPlayer,
        LinkShareModal,
    },
    watch: {
        render() {
            this.refreshKey++;
        },
    },
    computed: {
        tributeVideo() {
            return this.$store.state.tributeVideo;
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        openBunnyDeepLink() {
            if (!this.render.hlsGuid) {
                this.showSnackbar({ message: 'Invalid hls guid', color: 'error' });
                return;
            }
            if (!this.$auth.role.includes('SuperAdmin')) {
                this.showSnackbar({ message: 'Unauthorized Access', color: 'error' });
                return;
            }

            const deeplink = `https://dash.bunny.net/stream/${process.env.VUE_APP_BUNNY_STREAM_LIB_ID}/library/videos?search=${this.render.hlsGuid}&videoId=${this.render.hlsGuid}`;

            window.open(deeplink, '_blank');
        },
        handleInvalidEmail(email) {
            this.showSnackbar({ message: `${email} is not a valid email`, color: 'error' });
        },
        handleInviteSubmit(contacts) {
            let data = {
                emailList: contacts.emailList.join(','),
                phoneList: contacts.phoneList.join(','),
                url: this.tributeVideoLink,
            };

            this.$refs.linkShareModal.reset();

            this.axiosInstance
                .post(`/TributeVideo/tribute-player-invite/${this.tributeVideo.id}`, data)
                .then(resp => {
                    this.showSnackbar({ message: 'Invitations sent' });
                })
                .catch(err => {
                    this.showSnackbar({ message: 'Error sending invitations', color: 'error' });
                });
        },
        initTributeVideoShareModal() {
            if (this.$refs.linkShareModal) {
                this.$refs.linkShareModal.displayModal = true;
            }
        },
        pauseVideoPlayer() {
            if (this.$refs.videoPlayer) {
                this.$refs.videoPlayer.pause();
            }
        },
        deleteRenderStep1() {
            this.deleteRenderModal = true;
        },
        deleteRender(id) {
            this.loading = true;
            this.axiosInstance
                .delete(`/TributeVideoRender/${id}`)
                .then(response => {
                    this.showSnackbar({ message: 'Render deleted' });
                    this.$emit('render-deleted');
                })
                .catch(error => {
                    console.log(error, 'error');
                })
                .finally(() => {
                    this.loading = false;
                });
            this.deleteRenderModal = false;
        },
        handleThumbnail(url) {
            // img kit docs: https://docs.imagekit.io/features/image-transformations
            //https://ik.imagekit.io/demo/sample-video.mp4/ik-thumbnail.jpg
            //https://ik.imagekit.io/memoryshare/templates/fa6da4d5-b142-40ac-a67f-efdc1acd0523.mp4/ik-thumbnail.jpg?tr=so-5

            const thumbNailMod = '/ik-thumbnail.jpg?tr=so-2';
            const srcPath = url.split('/tribute-video-containers/')[1];
            const poster = `${process.env.VUE_APP_IMG_KIT_BASE}tribute-renders/${srcPath}${thumbNailMod}`;

            return poster;
        },
        handleRenderError() {
            const videoEl = this.$refs['renderPlayer'];
            const maxAttempts = 5;

            if (this.posterReloadCount < maxAttempts) {
                setTimeout(() => {
                    videoEl.poster = this.handleThumbnail(this.render.url, 'story-render');
                    this.posterReloadCount++;
                }, 1000);
            }
        },
        cancelDownload() {
            this.downloadCancelToken.cancel('Download canceled by user.');
        },
        downloadTributeVideo(url) {
            let name = `${this.tributeVideo.firstName} ${this.tributeVideo.lastName}'s' Tribute`;
            this.showSnackbar({ message: 'Downloading' });

            this.downloadCancelToken = this.axios.CancelToken.source();

            this.downloading = true;
            this.axios
                .get(url, {
                    responseType: 'blob',
                    onDownloadProgress: progressEvent => {
                        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                        this.downloadProgress = progress;
                    },
                    cancelToken: this.downloadCancelToken.token,
                })
                .then(response => {
                    const blob = new Blob([response.data], { type: 'video/mp4' });
                    const blobUrl = window.URL.createObjectURL(blob);
                    const anchor = document.createElement('a');
                    anchor.style.display = 'none';
                    anchor.href = blobUrl;
                    anchor.download = name;
                    document.body.appendChild(anchor);
                    anchor.click();
                    window.URL.revokeObjectURL(blobUrl);
                    this.showSnackbar({ message: 'Download complete' });
                })
                .catch(error => {
                    console.log(error);
                    if (this.axios.isCancel(error)) {
                        this.showSnackbar({ message: 'Download Canceled', color: 'error' });
                    } else {
                        this.showSnackbar({ message: 'Error downloading video', color: 'error' });
                    }
                })
                .finally(() => {
                    this.downloading = false;
                    this.downloadCancelToken = null;
                    this.downloadProgress = 0;
                });
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
    },
    async created() {
        await this.setAuthToken();
        this.createAxiosInstance();
    },
    mounted() {
        if (this.service) {
            this.tributeVideoLink = `${process.env.VUE_APP_VIDEO}/memoryshare/video/${this.service.slug}?tvl=true`;
        }
    },
};
</script>
<style lang="scss" scoped>
.light-orange-bg {
    background-color: #fff0dd;
    border-radius: 5px;
}
</style>
