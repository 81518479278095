<template>
    <div>
        <v-tooltip content-class="custom-tooltip" :disabled="disabled" v-bind="mergedProps">
            <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                    <slot name="activator"></slot>
                </div>
            </template>

            <span class="tip">
                <div>
                    <strong>
                        <slot name="title"> </slot>
                    </strong>
                </div>
                <slot name="content"></slot>
            </span>
        </v-tooltip>
    </div>
</template>
<script>
export default {
    data() {
        return {
            defaultTooltipProps: {
                openDelay: 400,
            },
        };
    },
    props: {
        tooltipProps: {
            type: Object,
            default: () => ({}),
        },
        disable: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        mergedProps() {
            return this.mergeProps(this.defaultTooltipProps, this.tooltipProps);
        },
        disabled() {
            return !(this.$slots.title || this.$slots.content) || this.disable;
        },
    },
    methods: {
        mergeProps(defaults, overrides) {
            let merged = { ...defaults };
            for (let key in overrides) {
                if (overrides[key] !== undefined) {
                    merged[key] = overrides[key];
                }
            }
            return merged;
        },
    },
};
</script>
<style lang="scss">
// .v-tooltip__content {
//     color: black !important;
//     background-color: white !important;
//     opacity: 1 !important;
//     box-shadow: 0 -3px 10px -3px rgba(0, 0, 0, 0.1), 0 10px 20px -5px rgba(0, 0, 0, 0.3);
// }

.custom-tooltip {
    color: black !important;
    background-color: white !important;
    opacity: 1 !important;
    box-shadow: 0 -3px 10px -3px rgba(0, 0, 0, 0.1), 0 10px 20px -5px rgba(0, 0, 0, 0.3);
}
</style>
