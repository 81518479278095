<!-- div -->
<template>
    <AppLayout :has-background="true" :contained="true">
        <v-card class="p-4">
            <v-tabs class="mb-3" v-model="tab">
                <v-tab v-for="item in tabs" :key="item.value">{{ item.label }}</v-tab>
            </v-tabs>

            <v-tabs-items style="overflow: visible" v-model="tab">
                <v-tab-item :key="0">
                    <v-card-title>
                        <font-awesome-icon icon="fa-regular fa-server" style="margin-right: 0.5rem"></font-awesome-icon>
                        <h4 class="m-0">Renders</h4>
                    </v-card-title>

                    <v-card-title>
                        <h5>Standard</h5>
                    </v-card-title>
                    <renders-table ref="standardRenders" :engineTypes="[0]"></renders-table>

                    <v-divider></v-divider>

                    <!-- <v-card-title>
                        <h5>Hls Renders</h5>
                    </v-card-title>
                    <renders-table ref="hlsRenders" :engineTypes="[2]"></renders-table> -->

                    <v-card-title>
                        <h5>Archive</h5>
                    </v-card-title>
                    <renders-table ref="archiveRenders" :engineTypes="[1]"></renders-table>

                    <div class="d-flex justify-end mt-6" v-if="tab == 0">
                        <vm-master-list @refresh-vm-list="vms => refreshVmLists(vms)" class="vm-list" />
                    </div>
                </v-tab-item>

                <v-tab-item :key="1">
                    <live-events-table></live-events-table>
                </v-tab-item>

                <v-tab-item :key="2">
                    <v-card-title>History</v-card-title>
                    <render-history-table></render-history-table>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </AppLayout>
</template>

<script>
import RendersTable from '@/components/Tables/Admin/RendersTable.vue';
import VmMasterList from '@/components/Misc/VmMasterList.vue';
import LiveEventsTable from '@/components/Tables/Admin/LiveEventsTable.vue';
import RenderHistoryTable from '@/components/Tables/Admin/RenderHistoryTable';

export default {
    metaInfo: {
        title: 'Renders',
    },
    components: { VmMasterList, RendersTable, LiveEventsTable, RenderHistoryTable },
    data() {
        return {
            tab: 0,
            tabs: [
                { label: 'Renders', value: 0 },
                { label: 'Auto Renders', value: 1 },
                { label: 'History', value: 2 },
            ],
        };
    },

    methods: {
        refreshVmLists(vms) {
            if (this.$refs.standardRenders) {
                this.$refs.standardRenders.vmList = vms;
            }

            if (this.$refs.hlsRenders) {
                this.$refs.hlsRenders.vmList = vms;
            }

            if (this.$refs.archiveRenders) {
                this.$refs.archiveRenders.vmList = vms;
            }
        },
        refreshRenders() {
            if (this.$refs.standardRenders) {
                this.$refs.standardRenders.getRenders();
            }
        },
    },
};
</script>
<style>
.vm-list {
    min-width: 350px;
    max-width: 300px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
}

@media only screen and (max-width: 768px) {
    .vm-list {
        width: 100%;
    }
}
</style>
