<template>
    <div>
        <div>
            <div v-if="!displayOnboarding">
                <v-row class="mb-2">
                    <v-col>
                        <div id="tribute-avatar" style="display: flex; align-items: center; flex-grow: 1; gap: 12px">
                            <v-avatar
                                @click="mainPhotoModal = true"
                                class="pointer"
                                v-if="tributeVideo.mainPhotoUrl"
                                size="70"
                            >
                                <v-img
                                    :src="handleMainImgSrc(tributeVideo.mainPhotoUrl)"
                                    :lazy-src="handleMainImgSrc(tributeVideo.mainPhotoUrl)"
                                    alt="Avatar"
                                />
                            </v-avatar>
                            <div class="mr-4" style="display: flex; flex-direction: column; justify-content: center">
                                <h3>Tribute Video</h3>
                                <div class="d-flex align-items-center" style="color: #999999">
                                    <p @click="settingsModal = true" class="m-0 pointer deadline-link">
                                        <!-- {{ service.firstName }} {{ service.lastName }} -->
                                        <font-awesome-icon icon="fa-regular fa-calendar-circle-exclamation" />
                                        <span v-if="tributeVideo.deadline">
                                            {{ $moment(tributeVideo.deadline).format('MMM Do, h:mm a') }}
                                        </span>
                                        <span class="text-decoration-underline ml-2" v-else>Set a Deadline </span>
                                    </p>
                                </div>
                            </div>
                            <div class="chip-container" style="display: flex; gap: 0.5rem; align-items: center">
                                <span v-if="event.hidden" :style="{ '--background-color': '#fb8c00' }" class="chip">
                                    <strong>Hidden</strong>
                                </span>
                            </div>
                        </div>
                    </v-col>

                    <!-- START: Tabs -->
                    <v-col cols="auto">
                        <v-tabs class="mb-1" show-arrows center-active v-model="tabIndex">
                            <v-tab v-for="(tab, index) in tributeTabs" :key="index" :href="'#' + tab.value">{{
                                tab.label
                            }}</v-tab>
                        </v-tabs>
                    </v-col>
                    <!-- END: Tabs -->

                    <v-col v-if="showProgress" cols="12">
                        <!-- START: Upload progress bar -->
                        <div>
                            <p class="m-0" style="font-size: 0.8rem">Upload {{ uploadProgress }}% Complete</p>
                            <v-progress-linear rounded height="5" v-model="uploadProgress"> </v-progress-linear>
                        </div>
                        <!-- END: Upload progress bar -->
                    </v-col>
                </v-row>
            </div>

            <div v-show="displayOnboarding" class="tribute-onboarding">
                <tribute-onboarding
                    :service="service"
                    :event="event"
                    :service-events="serviceEvents"
                    :minPhotos="minPhotos"
                    :mainPhotoUrl="tributeVideo.mainPhotoUrl"
                    :processingUploads="processingUploads"
                    @upload-start="files => handleUploadStart(files)"
                    @upload-success="remaining => handleOnboardUploadSuccess(remaining)"
                    @upload-progress="progress => handleUploadProgress(progress)"
                    @refresh-photos="handlePhotoRefresh"
                    @refresh-deadline="time => refreshDeadline(time)"
                    @onboard-override="handleOnboardingOverride"
                />
            </div>

            <div v-show="!displayOnboarding">
                <v-alert v-if="outOfSyncChanges.length > 0" class="text-center mt-4" text outlined color="warning"
                    >Video Out of Sync! Please <u @click="tabIndex = 'summary'" style="cursor: pointer">re-render</u> or
                    undo changes.
                </v-alert>

                <div
                    style="background-color: #f8f8f8; padding: 10px; margin-bottom: 16px; border-radius: 5px"
                    v-if="outOfSyncChanges.length > 0"
                    class="d-flex align-items-center justify-space-between"
                >
                    <div>
                        <v-btn :disabled="loading" @click="undoLastChange" class="mr-2" text x-small>
                            <font-awesome-icon
                                style="font-size: 0.8rem"
                                icon="fa-solid fa-rotate-left"
                            ></font-awesome-icon>
                        </v-btn>
                        <span>{{ outOfSyncChanges.length }} changes since render</span>
                    </div>

                    <v-btn @click="openTributeChangelog" text>View Changes</v-btn>
                </div>

                <div>
                    <!-- @refresh-photos="id => $emit('refresh-photos', id)" -->
                    <TributeTabSection
                        ref="tabs"
                        @changeTab="tab => handleChangeTab(tab)"
                        @delete-photo="photo => handlePhotoDelete(photo)"
                        @devModeToggle="val => handleDevModeToggle(val)"
                        @upload-progress="progress => handleUploadProgress(progress)"
                        @refresh-photos="handlePhotoRefresh"
                        @refresh-tribute-render="getTributeRender(tributeVideo.id)"
                        @refresh-sync="checkOutOfSync"
                        @render-deleted="handleRenderDeleted"
                        @render-started="handleRenderStarted"
                        @template-customize-main-photo="mainPhotoModal = true"
                        :tabIndex="tabIndex"
                        :event="event"
                        :service="service"
                        :devMode="devMode"
                        :minPhotos="minPhotos"
                        :show-progress="showProgress"
                        :render="render"
                        :onboarding="displayOnboarding"
                    />
                </div>

                <!-- START: SUPER ADMIN BUTTON CONTAINER -->
                <div v-if="$auth.role.includes('SuperAdmin')" class="super-admin-button-container">
                    <h3>Super Admin Edit</h3>

                    <v-btn @click="openTributeAdminModal" block color="#ff530d" dark class="mt-4">Tribute</v-btn>
                    <v-btn @click="openRenderAdminModal" v-if="render.id" block color="#ff530d" dark class="mt-4"
                        >Render</v-btn
                    >
                    <v-btn @click="openTributeChangelog" block color="#ff530d" dark class="mt-4">Changelog</v-btn>
                </div>
                <!-- END: SUPER ADMIN BUTTON CONTAINER -->

                <div class="text-center mt-4">
                    <v-btn
                        v-if="event.hidden && devMode && tabIndex == 'gallery'"
                        small
                        class="p-3"
                        color="warning"
                        @click="handleUnHideClick(event)"
                        >Un Hide</v-btn
                    >
                    <v-btn
                        v-if="!event.hidden && $auth.role.includes('SuperAdmin')"
                        small
                        class="p-3"
                        color="error"
                        @click="openDeleteEventConfirm(event)"
                        >Delete Tribute</v-btn
                    >

                    <v-btn v-if="!event.hidden" small class="p-3 mx-2" color="warning" @click="handleHideclick(event)"
                        >Hide</v-btn
                    >

                    <v-btn v-if="event.hidden" small class="p-3 mx-2" color="warning" @click="handleUnHideClick(event)"
                        >Un Hide</v-btn
                    >
                </div>
            </div>
        </div>

        <v-dialog max-width="750px" v-model="renderAdminModal">
            <tribute-render-super
                ref="renderSuper"
                @close="closeRenderAdminModal"
                v-if="render.id"
                :render="render"
            ></tribute-render-super>
        </v-dialog>

        <!-- START: Tribute Admin modal -->
        <v-dialog max-width="750px" v-model="tributeAdminModal">
            <v-card class="p-3">
                <v-card-title>Tribute Video Admin</v-card-title>
                <div class="p-3" v-if="tributeVideo != null">
                    <v-row>
                        <v-col cols="6">
                            <v-text-field
                                readonly
                                label="Name"
                                :value="tributeVideo.firstName + ' ' + tributeVideo.lastName"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Age" v-model="tributeVideo.age" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Tribute Id" v-model="tributeVideo.id" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Main Photo Id" v-model="tributeVideo.mainPhotoId" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Face Uuid" v-model="tributeVideo.faceUuid" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Deep Link" v-model="tributeVideo.deeplink" />
                        </v-col>
                        <v-col md="6">
                            <v-text-field
                                v-model="adminMaxTributeItems"
                                type="number"
                                label="Max Tribute Video Items"
                                min="1"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-btn @click="updateMaxItems" dark block color="#0c3c60">Save</v-btn>
                        </v-col>

                        <v-col cols="6">
                            <div class="p-3 tribute-admin-event-div">
                                <h5>Tribute Event</h5>
                                <v-text-field readonly label="Event Id" v-model="tributeVideo.eventId" />
                                <v-text-field readonly label="Status" :value="eventStates[event.eventStatus].label" />
                                <v-text-field readonly label="Hidden" :value="event.hidden" />
                                <v-text-field
                                    readonly
                                    label="Video Source"
                                    persistent-hint
                                    hint="Update url in admin render modal"
                                    v-model="event.convertedVideo"
                                />
                            </div>
                        </v-col>
                        <v-col cols="6">
                            <div class="p-3 tribute-admin-event-div">
                                <h5>Story Event</h5>
                                <v-text-field readonly label="Event Id" v-model="tributeVideo.storyEventId" />
                                <v-text-field
                                    readonly
                                    label="Status"
                                    :value="
                                        tributeVideo.storyEvent != null
                                            ? eventStates[tributeVideo.storyEvent.eventStatus].label
                                            : 'N/A'
                                    "
                                />
                                <v-text-field
                                    readonly
                                    label="Video Source"
                                    :value="
                                        tributeVideo.storyEvent != null ? tributeVideo.storyEvent.convertedVideo : ''
                                    "
                                />
                            </div>
                        </v-col>
                    </v-row>
                </div>
                <div class="p-3" v-else>
                    <p>No tribute video object found for this event</p>
                </div>
            </v-card>
        </v-dialog>
        <!-- END: Tribute Admin modal -->

        <!-- START: Main photo modal -->

        <branded-modal @close="mainPhotoModal = false" v-model="mainPhotoModal">
            <template v-slot:title>Profile Photo</template>
            <template v-slot:body>
                <v-row>
                    <v-col sm="6" md="4" lg="3" cols="12">
                        <div style="position: relative">
                            <v-img
                                v-if="tributeVideo.mainPhotoId && tributeVideo.mainPhotoUrl"
                                class="modal-photo"
                                :class="{ shake: modalMainAnimated }"
                                style="outline: 3px solid #2196f3"
                                @click="handleModalMainPhotoClick"
                                aspect-ratio="1"
                                :src="tributeVideo.mainPhotoUrl"
                            ></v-img>
                            <!-- <font-awesome-icon
                                style="position: absolute; font-size: 1.2rem; bottom: 8px; right: 8px; color: 2196f3"
                                icon="fa-circle-user"
                            ></font-awesome-icon> -->
                        </div>
                    </v-col>
                    <v-col
                        sm="6"
                        md="4"
                        lg="3"
                        cols="12"
                        v-for="(item, index) in photos.filter(x => x.mediaType == 0)"
                        :key="index"
                    >
                        <v-img
                            v-if="item.mediaType == 0"
                            @click="submitMainPhotoUpdate(tributeVideo.eventId, item.id)"
                            class="modal-photo"
                            aspect-ratio="1"
                            :src="item.url"
                        ></v-img>
                    </v-col>
                </v-row>

                <div class="text-center">
                    <!-- <span style="color: grey; font-size: 0.8rem">{{ totalPhotos }}</span> -->
                    <v-pagination class="pt-2" v-model="pageNumber" :length="totalPages"></v-pagination>
                </div>
            </template>
        </branded-modal>

        <!-- END: Main photo modal -->

        <!-- START: Confirmation Modal -->
        <v-dialog max-width="400px" v-model="confirmationModal">
            <v-card class="p-3">
                <v-card-title>Update main photo?</v-card-title>
                <div class="p-3" style="display: flex; justify-content: space-between">
                    <v-btn @click="cancelMainPhotoUpdate">Cancel</v-btn>
                    <v-btn @click="submitMainPhotoUpdate(tributeVideo.eventId, selectedPhoto.id)" color="primary"
                        >Confirm</v-btn
                    >
                </div>
            </v-card>
        </v-dialog>
        <!-- END: Confirmation Modal -->

        <!-- START: Confirmation Modal -->
        <v-dialog max-width="400px" v-model="deleteEventModal">
            <v-card>
                <v-card-title>Delete Tribute Event</v-card-title>
                <v-card-text>Delete tribute event for {{ service.firstName }} {{ service.lastName }}?</v-card-text>

                <div class="p-4 pt-0 d-flex" style="justify-content: space-between">
                    <v-btn @click="deleteEventModal = false">Cancel</v-btn>
                    <v-btn @click="handleDelete(event)" color="error">Confirm</v-btn>
                </div>
            </v-card>
        </v-dialog>

        <!-- START: Settings Modal -->
        <v-dialog @click:outside="cancelSettingsUpdate" max-width="400px" v-model="settingsModal">
            <v-card class="tribute-settings-modal">
                <v-card-title>Settings</v-card-title>
                <!-- <v-card-text>Delete tribute event for {{ service.firstName }} {{ service.lastName }}?</v-card-text> -->
                <div class="calendar-wrapper px-6">
                    <calendar
                        ref="deadlineCalendar"
                        datetime
                        v-model="tributeDeadline"
                        label="Deadline"
                        :output-local="true"
                        disable-past-dates
                    />
                </div>

                <!-- <div class="px-6">
                    <v-switch label="Separate Player" v-model="separatePlayer"></v-switch>
                    <v-switch label="Only Allow Uploads" v-model="uploadsOnly"></v-switch>
                </div> -->
                <div v-if="loopTribLink" class="ma-6 loop-link-section">
                    <h6 class="pa-4" style="font-size: 1.1rem">Tribute Video Loop</h6>
                    <div class="pa-4 pt-0 d-flex justify-content-between">
                        <v-btn color="success" depressed @click="openWindow(loopTribLink)">
                            <font-awesome-icon icon="fa-regular fa-arrow-up-right-from-square" class="mr-2" />
                            Open</v-btn
                        >
                        <v-btn dark depressed class="ml-2" @click="copyToClipboard(loopTribLink)"
                            ><font-awesome-icon icon="fa-regular fa-clone" class="mr-2" />
                            Copy Link
                        </v-btn>
                    </div>
                </div>
                <div class="p-4 pt-0 d-flex" style="justify-content: space-between">
                    <v-btn @click="cancelSettingsUpdate" depressed>Cancel</v-btn>
                    <v-btn @click="submitTributeSettings" dark depressed color="primary">Confirm</v-btn>
                </div>
            </v-card>
        </v-dialog>
        <!-- END: Settings Modal -->

        <changelog-modal
            @refresh-summary="refreshSummary"
            @refresh-sync="checkOutOfSync"
            @undo-all-success="handleUndoAllSuccess"
            v-if="tributeVideo.id"
            :tributeId="tributeVideo.id"
            ref="changelog"
        ></changelog-modal>
    </div>
</template>

<script>
import moment from 'moment';
import draggable from 'vuedraggable';
import TributeTabSection from '@/components/ManageService/Tribute/TributeTabSection.vue';
import TributeOnboarding from '@/components/ManageService/Tribute/TributeOnboarding.vue';
import Calendar from '@/components/ui/Calendar.vue';
import { mapActions } from 'vuex';
import { eventStates, tributeChangelogActions } from '@/constants';
import TributeRenderSuper from '@/components/ManageService/super-admin/TributeRenderSuper.vue';
import ChangelogModal from '@/components/ManageService/Tribute/ChangelogModal.vue';
import BrandedModal from '@/components/ui/BrandedModal.vue';

export default {
    name: 'TributeEvent',
    props: {
        type: {
            type: Number,
            required: true,
        },
        event: {
            type: Object,
            required: true,
        },
        service: {
            type: Object,
            required: true,
        },
        serviceEvents: {
            type: Array,
        },
        onboarding: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        tributeVideo() {
            return this.$store.state.tributeVideo;
        },
        totalPages() {
            return Math.ceil(this.totalPhotos / this.pageSize);
        },
        photos() {
            return this.$store.state.tributeVideo.photos;
        },
        displayOnboarding() {
            return this.onboarding && !this.onboardingOverride;
        },
        photosOptions() {
            return this.$store.state.tributeVideo.photosOptions;
        },
    },
    components: {
        TributeTabSection,
        TributeOnboarding,
        draggable,
        Calendar,
        TributeRenderSuper,
        ChangelogModal,
        BrandedModal,
    },
    data() {
        return {
            eventStates,
            tributeChangelogActions,
            modalMainAnimated: false,
            eventFormData: {},
            message: 'here!',
            tributeAdminModal: false,
            mainPhotoUrl: null,
            mainPhotoModal: false,
            eventPhotos: [],
            pageNumber: 1,
            pageSize: 12,
            totalPhotos: 0,
            selectedPhoto: null,
            confirmationModal: false,
            deleteEventModal: false,
            devMode: false,
            minPhotos: 1,
            confirmationPhotoUrl: null,
            confirmingPhoto: false,
            loading: false,
            stepIndex: 1,
            tabIndex: 'gallery',
            processingUploads: false,
            uploadProgress: 0,
            showProgress: false,
            settingsModal: false,
            uploadsOnly: false,
            separatePlayer: false,
            tributeDeadline: '',
            modalCalendarActive: false,
            loopTribLink: null,
            render: {},
            renderAdminModal: false,
            incomingUploadCount: 0,
            outOfSyncChanges: [],
            tabSectionRefreshKey: 0,
            outOfSyncChangesModal: false,
            adminMaxTributeItems: 0,
            onboardingOverride: false,
            commonTabs: [
                { label: 'Slides', value: 'gallery' },
                // { label: 'Stories', value: 'stories' },
                { label: 'Theme', value: 'templates' },
                { label: 'Music', value: 'music' },
                { label: 'Summary', value: 'summary' },
            ],
            tributeTabs: [
                { label: 'Slides', value: 'gallery' },
                // { label: 'Stories', value: 'stories' },
                { label: 'Theme', value: 'templates' },
                { label: 'Music', value: 'music' },
                { label: 'Summary', value: 'summary' },
            ],
            stepHeaders: [
                { text: 'Overview', value: 1 },
                { text: 'Identify', value: 2 },
                { text: 'Upload Photos', value: 3 },
            ],
        };
    },
    watch: {
        tributeVideo: {
            handler(val) {
                if (val.mainPhotoId && this.tributeVideo.mainPhotoUrl != val.mainPhotoUrl) {
                    this.refreshTributeVideoData();
                }
            },
            deep: true,
        },
        outOfSyncChanges() {
            if (this.outOfSyncChanges.length == 0) {
                this.outOfSyncChangesModal = false;
            }
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        ...mapActions('tributeVideo', [
            'updateTributeVideo',
            'updateTributeVideoPhotos',
            'updateTributeVideoSelectedPhotos',
            'updateTributeVideoSelectedTemplates',
            'updateTributeVideoSelectedSongs',
            'resetTributeVideoState',
        ]),
        handleOnboardingOverride() {
            this.onboardingOverride = true;
        },
        handleUndoAllSuccess() {
            this.outOfSyncChanges = [];
            this.outOfSyncChangesModal = false;
            this.refreshSummary();
            this.checkOutOfSync();
        },
        updateMaxItems() {
            this.loading = true;

            this.axiosInstance
                .post(`/TributeVideoPhoto/update-max-items/${this.event.id}/${this.adminMaxTributeItems}`)
                .then(response => {
                    this.tributeVideo.maxItems = response.data.maxItems;
                    this.showSnackbar({ message: 'Max slides updated' });
                    this.tributeAdminModal = false;
                })
                .catch(error => {
                    console.log(error);
                    this.showSnackbar({ message: 'Error updating max slides', color: 'error' });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        handleModalMainPhotoClick() {
            this.showSnackbar({ message: 'This profile photo is already selected' });
            this.modalMainAnimated = true;
            setTimeout(() => {
                this.modalMainAnimated = false;
            }, 1000);
        },
        refreshSummary() {
            if (this.$refs.tabs) {
                this.$refs.tabs.refreshSummary();
            }
        },

        initOutOfSyncChangesModal() {
            this.outOfSyncChangesModal = true;
        },
        changeWasRendered(item) {
            if (this.lastRenderDate == null) {
                return false;
            } else {
                return item.createDate < this.lastRenderDate;
            }
        },
        undoAllChanges() {
            this.loading = true;
            this.axiosInstance
                .post(`/TributeVideo/changelog/undo-all-since-render/${this.tributeVideo.id}`)
                .then(resp => {
                    this.showSnackbar({ message: 'Undo successful' });
                    this.outOfSyncChanges = [];
                    this.outOfSyncChangesModal = false;
                    this.refreshSummary();
                    this.checkOutOfSync();
                })
                .catch(error => {
                    console.log(error, 'error @ undo');
                    this.showSnackbar({ message: 'Error occurred during undo', color: 'error' });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        undoLastChange() {
            if (this.outOfSyncChanges.length == 0) {
                this.showSnackbar({ message: 'Change record not found.', color: 'error' });
                return;
            }

            const targetRecord = this.outOfSyncChanges[0];

            this.undoChange(targetRecord);
        },
        undoChange(item) {
            if (this.mainTributePhotoId > 0) {
                if (
                    item.action == 'Add' &&
                    item.tableName == 'TributeVideoPhoto' &&
                    item.itemId == this.mainTributePhotoId
                ) {
                    this.showSnackbar({ message: 'Cannot remove main photo', color: 'error' });
                    this.selectedRecord = null;
                    this.recordToDelete = null;
                    this.continuationToken = null;
                    this.showRaw = false;
                    this.results = [];
                    return;
                }
            }

            const found = this.outOfSyncChanges.find(x => x.id == item.id);

            if (found) {
                this.loading = true;
                this.axiosInstance
                    .post(`/TributeVideo/changelog/undo/${item.tributeId}/${item.id}`)
                    .then(resp => {
                        if (found) {
                            const index = this.outOfSyncChanges.indexOf(found);
                            this.outOfSyncChanges.splice(index, 1);
                        }
                        this.showSnackbar({ message: 'Undo successful' });
                        this.refreshSummary();
                        this.checkOutOfSync();
                    })
                    .catch(error => {
                        console.log(error, 'error @ undo');
                        this.showSnackbar({ message: 'Error occurred during undo', color: 'error' });
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        openTributeChangelog() {
            if (this.$refs.changelog) {
                this.$refs.changelog.openModal();
            }
        },
        handlePhotoRefresh() {
            this.refreshTributeVideoData();
            // this.checkOutOfSync();
        },
        closeRenderAdminModal() {
            this.renderAdminModal = false;

            if (this.tributeVideo.id) {
                this.getTributeRender(this.tributeVideo.id);
                this.checkOutOfSync();
            }
        },
        openRenderAdminModal() {
            this.renderAdminModal = true;
        },
        cancelSettingsUpdate() {
            if (this.tributeVideo.deadline) {
                this.$refs.deadlineCalendar.tempDate = false;
                this.tributeDeadline = moment(this.tributeVideo.deadline).toISOString(true);
            }

            this.uploadsOnly = this.tributeVideo.uploadsOnly;
            this.separatePlayer = this.tributeVideo.separatePlayer;
            this.settingsModal = false;
        },
        handleUploadProgress(progress) {
            this.uploadProgress = progress;

            if (progress == 100) {
                this.showProgress = false;
                setTimeout(() => {
                    this.uploadProgress = 0;
                }, 200);
            }
        },
        checkOutOfSync() {
            if (this.render.id) {
                this.axiosInstance.get(`/TributeVideoRender/check-out-of-sync/${this.render.id}`).then(resp => {
                    this.outOfSyncChanges = resp.data.outOfSyncChanges;

                    // if (this.$refs.renderSuper) {
                    //     this.$refs.renderSuper.refresh();
                    // }
                });
            }
        },
        refreshDeadline(dt) {
            this.tributeDeadline = moment(dt).toISOString(true);
        },
        handlePhotoDelete(photo) {
            $emit('delete-photo', photo);
            this.refreshTributeVideoData();
        },
        refreshTributeVideoData() {
            if (this.tributeVideo?.eventId) {
                this.axiosInstance
                    .get(`/TributeVideo/get-tribute-by-event/${this.tributeVideo?.eventId}`)
                    .then(resp => {
                        if (this.service.slug && this.event.convertedVideo) {
                            this.getTributeRender(this.tributeVideo.id);
                        }
                        this.updateTributeVideo({ ...resp.data });
                        this.$emit('refresh-photos', this.event);
                    });
            }
        },
        handleRenderStarted() {
            this.tributeTabs = [{ label: 'Video', value: 'video' }, ...this.commonTabs];
            this.tabIndex = 'video';
            this.getTributeRender(this.tributeVideo.id);
        },
        handleRenderDeleted() {
            this.tributeTabs = this.commonTabs;
            this.render = {};
            this.tabIndex = 'summary';
        },
        handleChangeTab(tab) {
            this.tabIndex = tab;
            const main = document.getElementById('scroll');
            main.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        },
        handleUploadStart(files) {
            var count = files.length;
            this.updateTributeVideo({ incomingUploadCount: count });
            if (count > 0) {
                this.showProgress = true;
            }
        },
        openWindow(url) {
            window.open(url, '_blank');
        },
        copyToClipboard(string) {
            navigator.clipboard.writeText(string);
            this.showSnackbar({ message: 'Link copied' });
        },
        submitTributeSettings() {
            // If there is an event time passed in, just check if it's older than 30 days.
            let time = new Date(this.tributeDeadline);

            if (this.$refs.deadlineCalendar.renderedDate == '') {
                // time = new Date('0001-01-01T00:00:00');
                time = null;
            } else {
                let offset = time.getTimezoneOffset() / 60;
                time.setHours(time.getHours() - offset);
            }

            let data = {
                deadline: time,
                separatePlayer: this.separatePlayer,
                uploadsOnly: this.uploadsOnly,
            };

            this.axiosInstance
                .post(`TributeVideo/settings/${this.tributeVideo.id}`, data)
                .then(resp => {
                    this.$emit('refresh-service', this.event);
                    this.showSnackbar({ message: 'Upload deadline updated' });
                    this.settingsModal = false;
                })
                .catch(err => {
                    console.log(err, 'error');
                    this.showSnackbar({ message: 'Error updating upload deadline', color: 'error' });
                });
        },
        handleOnboardUploadSuccess(remaining) {
            if (remaining == 0) {
                this.showProgress = false;
            }

            if (this.$refs.tabs) {
                this.$refs.tabs.handleOnboardUploadSuccess();
            }

            // Update tribute video state
            if (
                !this.tributeVideo.birthDate ||
                !this.tributeVideo.deathDate ||
                !this.tributeVideo.firstName ||
                !this.tributeVideo.lastName
            ) {
                this.getTributeVideo(this.tributeVideo.eventId);
            }
        },

        handleEventFormSubmission: function () {
            if (this.eventFormData.userId === 0 || this.eventFormData.userId === null) {
                this.eventFormData.userId = this.$auth.user['https://memoryshare.com/user_id'];
                if (this.eventFormData.userId < 1) {
                    this.eventFormData.userId = 0;
                }
            }

            let error = false;

            if (this.eventFormData.title.length == 0) {
                this.showSnackbar({ message: 'Title cannot be blank', color: 'error' });
                error = true;
            }

            if (error) return;

            const data = {
                ...this.eventFormData,
                serviceId: this.service.id,
                eventType: 0,
                userId: this.eventFormData.userId ? this.eventFormData.userId : 0,
            };

            this.$emit('updateEvent', { data: data, id: this.event.id });
        },
        handleMainImgSrc(url) {
            if (url && url.includes('/tribute-photos/')) {
                const imgPath = url.split('/tribute-photos/')[1];
                return process.env.VUE_APP_IMG_KIT_BASE + 'tr:w-500,h-500,fo-face/' + imgPath;
            }
        },
        handleDevModeToggle(val) {
            this.devMode = val;
        },
        toggleDevMode() {
            this.devMode = !this.devMode;
        },
        submitMainPhotoUpdate(eventId, photoId) {
            this.loading = true;
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(process.env.VUE_APP_API + `/TributeVideoPhoto/update-main-photo/${eventId}/${photoId}`)
                .then(resp => {
                    this.confirmationModal = false;
                    this.mainPhotoModal = false;
                    this.refreshTributeVideoData();
                })
                .catch(err => {
                    console.log(err, 'err');
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        cancelMainPhotoUpdate() {
            this.confirmationModal = false;
            this.selectedPhoto = null;
        },
        initConfirmModal(item) {
            this.selectedPhoto = item;
            this.confirmationModal = true;
        },
        handleMainClass(item) {
            if (this.tributeVideo.mainPhotoId) {
                if (item.id == this.tributeVideo.mainPhotoId) {
                    return 'elevation-8';
                }
            }
        },
        openMainPhotoModal() {
            this.mainPhotoModal = true;
            this.pageNumber = 1;
        },
        openTributeAdminModal() {
            this.tributeAdminModal = true;
        },
        handleUnHideClick(event) {
            this.$emit('unhideEvent', event);
        },
        handleHideclick(event) {
            this.$emit('hideEvent', event);
        },
        openDeleteEventConfirm() {
            this.deleteEventModal = true;
        },
        handleDelete(event) {
            this.resetTributeVideoState();
            this.$emit('deleteEvent', event);
        },
        getPhotos(eventId, params) {
            this.loading = true;
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/TributeVideoPhoto/event-photos/${eventId}`, {
                    params: params,
                })
                .then(resp => {
                    this.eventPhotos = resp.data.photos;
                    this.totalPhotos = resp.data.total;
                    this.updateTributeVideoPhotos(resp.data.photos);
                    this.updateTributeVideo({ totalPhotos: resp.data.total });
                })
                .catch(err => {
                    console.log(err, 'err');
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getSelectedTemplates(id) {
            this.axiosInstance
                .get(`TributeVideo/selected-templates/${id}`)
                .then(resp => {
                    if (resp.data) {
                        this.updateTributeVideoSelectedTemplates(resp.data.templates);
                    }
                })
                .catch(err => {
                    console.log(err, 'template json error');
                });
        },
        getSelectedSongs(id) {
            this.axiosInstance
                .get(`TributeVideo/selected-songs/${id}`)
                .then(resp => {
                    if (resp.data) {
                        this.updateTributeVideoSelectedSongs(resp.data);
                    }
                })
                .catch(err => {
                    console.log(err, 'template json error');
                });
        },
        getTributeVideo(id) {
            return this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/TributeVideo/get-tribute-by-event/${id}`)
                .then(response => {
                    this.updateTributeVideo(response.data);
                    return this.tributeVideo;
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },

        async getTributeRender(tributeId) {
            if (tributeId) {
                this.loading = true;
                return this.axiosInstance
                    .get(`/TributeVideoRender/${tributeId}`)
                    .then(resp => {
                        this.render = resp.data;
                    })
                    .catch(error => {
                        if (error.response && error.response.status == 404) {
                            return null;
                        } else {
                            console.log('error', error);
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        getStoryEvent(id) {
            this.loading = true;
            return this.axiosInstance
                .get(`/Events/${id}`)
                .then(resp => {
                    return resp.data;
                })
                .catch(error => {
                    console.log(error, 'error');
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
    },
    async mounted() {
        await this.setAuthToken();
        this.createAxiosInstance();
        this.separatePlayer = this.service.separatePlayer;

        if (this.service.slug && this.event.convertedVideo) {
            this.loopTribLink = `${process.env.VUE_APP_VIDEO}/memoryshare/video/${this.service.slug}?tvl=true`;
        }
        await this.getTributeRender(this.tributeVideo.id);

        if (this.render.id) {
            this.tributeTabs = [{ label: 'Video', value: 'video' }, ...this.commonTabs];
            this.tabIndex = 'video';

            // this.outOfSyncChanges = this.render.outOfSyncChanges;
            this.checkOutOfSync();
        } else {
            this.tabIndex = 'gallery';
        }

        if (this.tributeVideo.storyEventId) {
            const storyEvent = await this.getStoryEvent(this.tributeVideo.storyEventId);
            this.updateTributeVideo({ storyEvent: storyEvent });
        }

        this.eventFormData = {
            id: this.event.id,
            title: this.event.title,
            time: this.service.deathDate ? moment(this.service.deathDate)?.toISOString() : null,
            live: false,
            private: this.event?.private || false,
            introVideo: false,
            userId: this.event?.userId,
            eventType: 0,
            serviceId: this.service.id,
        };

        if (this.tributeVideo.id) {
            let params = {
                pageNumber: this.pageNumber - 1,
                pageSize: this.pageSize,
            };
            // this.getPhotos(this.tributeVideo.eventId, params);
            this.getSelectedSongs(this.tributeVideo.id);
            this.getSelectedTemplates(this.tributeVideo.id);
        }
        this.stepIndex = 1;

        if (this.tributeVideo.mainPhotoId) {
            this.stepIndex = 3;
        }

        if (this.tributeVideo.deadline) {
            this.tributeDeadline = moment(this.tributeVideo.deadline).toISOString(true);
        }

        this.adminMaxTributeItems = this.tributeVideo.maxItems;

        this.uploadsOnly = this.tributeVideo.uploadsOnly;
        // else {
        //     this.tributeDeadline = moment().toISOString(true);
        // }
    },
    sockets: {
        async NotifyTributeRender(data) {
            if (this.tributeVideo.id == data.tributeVideoId) {
                await this.getTributeRender(this.tributeVideo.id);
                if (this.render) {
                    this.checkOutOfSync();
                }
            }
        },
        async NotifyUpload(data) {
            if (data.id === this.event.id) {
                // this.handlePhotoRefresh();
                this.refreshSummary();
            }

            // if (data.id == this.event.id && !this.rotatingImages) {
            //     if (this.tributeVideo.eventId) {
            //         this.pageNumber = 0;
            //         this.pageSize = this.photos.length >= 24 ? this.photos.length : 24;
            //         await this.updatePhotoStore(this.pageNumber, this.pageSize, false, false);
            //         this.checkOutOfSyncPhotos(this.event.id);
            //         this.$emit('refresh-sync');
            //     }
            // }
        },
        NotifyMainPhotoReplace(data) {
            if (data.eventId == this.tributeVideo.eventId) {
                this.refreshTributeVideoData();
                this.$emit('refresh-photos', this.event);
            }
        },
        // NotifyTributeSongsUpdate(data) {
        //     if (data?.tributeId == this.tributeVideo.id) {
        //         this.updateTributeVideoSelectedSongs(data.songs);
        //     }
        // },
        // NotifyTributeThemeUpdate(data) {
        //     if (data && data.tributeId && data.tributeId > 0 && data.tributeId == this.tributeVideo.id) {
        //         if (data?.template?.id > 0) {
        //             this.updateTributeVideoSelectedTemplates([data.template]);
        //         } else {
        //             this.updateTributeVideoSelectedTemplates([]);
        //         }
        //     }
        // },
    },
};
</script>
<style lang="scss">
.tribute-onboarding {
    .hidden {
        opacity: 0;
        height: 0;
        width: 0;
    }
}
.uploader-wrap--onboarding {
    border: 3px dashed #d3d3d3;
    border-radius: 40px;
    margin: 25px 0;
    #drop-zone {
        height: 200px;
        border-radius: 40px;
        div {
            border-radius: 40px;

            button {
                border-radius: 40px;
            }
        }
    }
    .uppy-DragDrop-inner {
        border-radius: 40px;
        padding: 0;
        height: 100%;
    }
}
.main-photo-avatar:hover {
    cursor: pointer;
}
.main-photo-avatar {
    // border: 2px solid red;
}
.modal-photo {
    border-radius: 10px;
    cursor: pointer;
    outline: 3px solid transparent;
    transition: 0.2s;
}

.modal-photo:hover {
    outline: 3px solid #bababa;
}
.current-main-photo {
    border: 3px solid #2196f3;
    // transform: scale(1.08);
}

.super-admin-button-container {
    background: #f8f8f8;
    padding: 1rem 3rem;
    margin: 1rem 0;
    gap: 2rem;
    text-align: center;
    border-radius: 4px;
}

.tribute-settings-modal {
    // .calendar-wrapper {
    //     height: 73px;
    //     transition: height 0.3s ease;
    // }
    // .calendar-wrapper--active {
    //     height: 520px !important;
    // }

    .calendar-wrapper {
        .main-container {
            position: relative;
            top: 0;
            margin-bottom: 18px;
        }
    }

    .loop-link-section {
        border: 1px solid #d3d3d3;
        border-radius: 5px;
    }
}

.tribute-admin-event-div {
    border: 1px solid #d8d8d8;
    border-radius: 5px;
}

.deadline-link:hover {
    text-decoration: underline;
}

.shake {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
}
@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }
    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}
</style>
