<template>
    <div>
        <v-btn v-if="!hideButton" dark depressed small color="#ff530d" @click="handleOpen">Upload +</v-btn>

        <dashboard-modal v-if="uppy" :uppy="uppy" :props="dashboardProps" :open="isOpen" />

        <branded-modal @close="warningModal = false" max-width="400px" v-model="warningModal">
            <template v-slot:title>
                <h2>Warning!</h2>
            </template>

            <template v-slot:body>
                <v-card-text>Please ensure you have the correct synchronization rights to use this music.</v-card-text>
                <v-card-actions>
                    <v-btn depressed color="error" @click="confirmWarning">Continue</v-btn>
                </v-card-actions>
            </template>
        </branded-modal>

        <branded-modal @close="userNotFoundModal = false" max-width="400px" v-model="userNotFoundModal">
            <template v-slot:title>
                <h2>Warning!</h2>
            </template>

            <template v-slot:body>
                <v-card-text>Unauthorized Access: UserId not found</v-card-text>
                <v-card-actions>
                    <div style="width: 100%" class="text-center">
                        <v-btn depressed color="error" @click="userNotFoundModal = false">Ok</v-btn>
                    </div>
                </v-card-actions>
            </template>
        </branded-modal>
    </div>
</template>
<script>
import Uppy from '@uppy/core';
import { DashboardModal } from '@uppy/vue';
import UppyAddUploaderPlugin from '@/utilities/uppy-add-uploader-plugin.js';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import { log } from '@/utilities/debug-helpers';
import { mapActions } from 'vuex';
import BrandedModal from '@/components/ui/BrandedModal.vue';

export default {
    data() {
        return {
            uppy: null,
            isOpen: false,
            warningModal: false,
            tempFiles: [],
            userNotFoundModal: false,
            dashboardProps: {
                theme: 'light',
                onRequestCloseModal: this.handleClose,
                hideUploadButton: false,
                height: 450,
                showProgressDetails: true,
                browserBackButtonClose: true,
                closeModalOnClickOutside: true,
                proudlyDisplayPoweredByUppy: false,
                // note: '1MB file size limit',
                note: 'Tribute Music Uploader',
            },
        };
    },
    components: {
        DashboardModal,
        BrandedModal,
    },
    props: {
        hideButton: {
            type: Boolean,
            defalult: false,
        },
        token: {
            type: String,
            required: true,
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
        handleOpen() {
            if (this.$auth.role.includes('SuperAdmin')) {
                this.isOpen = true;
            } else {
                this.warningModal = true;
            }
        },
        confirmWarning() {
            this.warningModal = false;
            this.isOpen = true;
        },
        handleClose() {
            this.isOpen = false;
        },
        async getUploadUrl(id, file) {
            return this.axiosInstance.get(`TributeVideoSong/song/sas/${id}/${file.name}`);
        },
        async uploadToEndpoint(file, endpoint, onProgressUpdate) {
            return new Promise((resolve, reject) => {
                var reader = new FileReader();
                reader.readAsArrayBuffer(file.data);
                reader.addEventListener('error', error => {});
                reader.addEventListener('load', async () => {
                    const config = {
                        onUploadProgress: onProgressUpdate,
                    };
                    try {
                        await this.axios
                            .create({
                                headers: {
                                    'Content-Type': file.type,
                                    'x-ms-blob-type': 'BlockBlob',
                                },
                            })
                            .put(endpoint, reader.result, config)
                            .then(res => {});
                        resolve('done');
                    } catch (error) {
                        console.error(error);
                        reject(error);
                    }
                });
            });
        },
        async createSong(data) {
            return this.axiosInstance.post('/TributeVideoSong', data);
        },
        updatePlayableLink(id, fileName) {
            return this.axios
                .create({
                    headers: { Authorization: `Bearer ${this.token}`, 'Content-Type': 'application/json' },
                })
                .put(process.env.VUE_APP_API + `/TributeVideoSong/song/${id}`, JSON.stringify(fileName))
                .then(res => {
                    return res.data;
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        cleanFileName(file) {
            const lastDot = file.name.lastIndexOf('.');
            let cleanedName = file.name.substring(0, lastDot);
            cleanedName = cleanedName.replaceAll('_', ' ');
            return cleanedName;
        },
        getMusicFileDuration(file) {
            return new Promise((resolve, reject) => {
                const src = URL.createObjectURL(file.data);

                const audioElement = new Audio(src);
                audioElement.addEventListener('loadeddata', () => {
                    let duration = Math.floor(audioElement.duration);
                    return resolve(duration);
                    // The duration variable now holds the duration (in seconds) of the audio clip
                });
                audioElement.addEventListener('error', () => {
                    return reject(error);
                });
            });
        },

        uploadSuccessful() {
            this.tempFiles = [];
            this.uppy.cancelAll();
        },
        async uploadFiles() {
            return new Promise(async (resolve, reject) => {
                // Tell dashboard the the upload has started
                this.uppy.emit('upload-start', this.tempFiles);

                for (let i = 0; i < this.tempFiles.length; i++) {
                    let SELECTED_FILE = this.tempFiles[i];
                    try {
                        const cleaned = this.cleanFileName(SELECTED_FILE);

                        const duration = await this.getMusicFileDuration(SELECTED_FILE);

                        // // Create DB instance
                        let songDto = {
                            title: cleaned,
                            album: '',
                            artist: '',
                            duration: duration ? duration : 0,
                        };

                        let { data: created } = await this.createSong(songDto);

                        // // Get Sas info
                        let {
                            data: { sas },
                            data: { fileName },
                        } = await this.getUploadUrl(created.id, SELECTED_FILE);

                        // // Upload to azure storage
                        await this.uploadToEndpoint(SELECTED_FILE, sas, ev => {
                            // Emit progress update so Uppy Dashboard shows progress bar
                            this.uppy.emit('upload-progress', SELECTED_FILE, {
                                uploader: this,
                                bytesUploaded: ev.loaded,
                                bytesTotal: ev.total,
                            });
                        });

                        // // Save playable link to Db instance
                        const updated = await this.updatePlayableLink(created.id, fileName);

                        const uploadResp = {
                            status: 200,
                        };

                        this.uppy.emit('upload-success', SELECTED_FILE, uploadResp);
                        this.$emit('upload-success', updated);
                    } catch (error) {
                        //Error thrown if userId not found on backend
                        if (error.response.data.Message == 'Unauthorized access: UserId not found') {
                            console.log('specific error');
                            this.userNotFoundModal = true;
                            this.isOpen = false;
                        }

                        var status = -1;

                        if (error.response) {
                            status = error.response.status;
                        }

                        const response = {
                            status: status,
                        };

                        this.uppy.emit('upload-error', SELECTED_FILE, error, response);
                        console.log(error, 'upload error');
                        reject(error);
                    }
                }

                resolve('done');
            });
        },
        initUppy() {
            this.uppy = new Uppy({
                id: 'tribute-music-uploader',
                debug: true,
                autoProceed: false,
                // logger: Uppy.debugLogger,
                allowMultipleUploadBatches: false,
                restrictions: {
                    // maxFileSize: MAX_FILE_SIZE,
                    minNumberOfFiles: 1,
                    maxNumberOfFiles: 10,
                    allowedFileTypes: ['.mp3'],
                },
            })
                .use(UppyAddUploaderPlugin, {
                    uploader: fileIds => {
                        this.uploadFiles()
                            .then(res => {
                                this.handleClose();
                                this.$emit('refresh');
                                this.showSnackbar({ message: 'Upload Complete' });

                                this.uploadSuccessful();
                            })
                            .catch(error => {
                                this.showSnackbar({ color: 'error', message: 'An error occurred during the upload' });
                            });
                    },
                })
                .on('file-added', file => {
                    this.tempFiles = [...this.tempFiles, file];
                })
                .on('file-removed', file => {
                    this.tempFiles = this.uppy.getFiles();
                });
        },
    },

    async created() {
        // await this.setAuthToken();
        this.createAxiosInstance();
        this.initUppy();
    },
    beforeDestroy() {
        this.uppy.close();
    },
};
</script>
<style lang=""></style>
