export default {
    namespaced: true,
    state: {
        id: null,
        eventId: null,
        storyEventId: null,
        storyEvent: null,
        loadingPhotos: false,
        uploadingPhotos: false,
        photos: [],
        photosOptions: {
            pageNum: 0,
            pageSize: 24,
        },
        selectedPhotos: [],
        selectedTemplates: [],
        selectedSongs: [],
        age: null,
        deeplink: null,
        deadline: null,
        mainPhotoId: null,
        mainPhotoUrl: null,
        faceUuid: null,
        render: null,
        firstName: null,
        lastName: null,
        birthDate: null,
        deathDate: null,
        totalPhotos: 0,
        slideDuration: 0,
        minPhotos: 1,
        incomingUploadCount: 0,
        endingType: 1,
        endingDuration: 0,
        videoSlidesDuration: 0,
        videoSlidesCount: 0,
        uploadsOnly: false,
        maxItems: 0,
    },
    mutations: {
        updateUploadingPhotos(state, payload) {
            state.uploadingPhotos = payload;
        },
        updateGalleryItem(state, payload) {
            // Find the gallery item in the state by ID
            const foundItem = state.photos.find(item => item.id === payload.id);

            if (foundItem) {
                // Update the properties of the found item
                for (const key in payload.data) {
                    if (Object.hasOwnProperty.call(payload.data, key)) {
                        foundItem[key] = payload.data[key];
                        foundItem.refreshKey++;
                    }
                }
            }
        },
        updateTributeVideoPhotosOptions(state, payload) {
            // Checking if the payload values for pageNum and pageSize are numbers and are 0 or greater
            if (typeof payload.pageNum === 'number' && payload.pageNum >= 0) {
                state.photosOptions.pageNum = payload.pageNum;
            }

            if (typeof payload.pageSize === 'number' && payload.pageSize >= 0) {
                state.photosOptions.pageSize = payload.pageSize;
            }
        },
        updateTributeVideoSelectedSongs(state, payload) {
            state.selectedSongs = payload;
        },
        updateTributeVideoSelectedTemplates(state, payload) {
            state.selectedTemplates = payload;
        },
        updateTributeVideoSelectedPhotos(state, payload) {
            state.selectedPhotos = payload;
        },
        updateTributeVideoPhotos(state, payload) {
            state.photos = payload;
        },
        updateTributeVideo(state, payload) {
            Object.assign(state, payload);
        },
        resetTributeVideoState(state) {
            const originalState = {
                id: null,
                eventId: null,
                storyEventId: null,
                storyEvent: null,
                loadingPhotos: false,
                photos: [],
                photosOptions: {
                    pageNum: 0,
                    pageSize: 5,
                },
                selectedPhotos: [],
                selectedTemplates: [],
                selectedSongs: [],
                age: null,
                deeplink: null,
                mainPhotoId: null,
                mainPhotoUrl: null,
                faceUuid: null,
                render: null,
                firstName: null,
                lastName: null,
                birthDate: null,
                deathDate: null,
                totalPhotos: 0,
                slideDuration: 0,
                minPhotos: 1,
                incomingUploadCount: 0,
                endingType: 1,
                endingDuration: 0,
                videoSlidesDuration: 0,
                videoSlidesCount: 0,
                deadline: null,
                onlyUploads: false,
                maxItems: 0,
            };
            Object.assign(state, originalState);
        },
    },
    actions: {
        updateUploadingPhotos({ commit }, payload) {
            commit('updateUploadingPhotos', payload);
        },
        updateGalleryItem({ commit }, payload) {
            commit('updateGalleryItem', payload);
        },
        updateTributeVideoPhotosOptions({ commit }, payload) {
            commit('updateTributeVideoPhotosOptions', payload);
        },
        updateTributeVideoSelectedSongs({ commit }, payload) {
            commit('updateTributeVideoSelectedSongs', payload);
        },
        updateTributeVideoSelectedTemplates({ commit }, payload) {
            commit('updateTributeVideoSelectedTemplates', payload);
        },
        updateTributeVideoSelectedPhotos({ commit }, payload) {
            commit('updateTributeVideoSelectedPhotos', payload);
        },
        updateTributeVideoPhotos({ commit }, payload) {
            commit('updateTributeVideoPhotos', payload);
        },
        updateTributeVideo({ commit }, payload) {
            commit('updateTributeVideo', payload);
        },
        resetTributeVideoState({ commit }) {
            commit('resetTributeVideoState');
        },
    },
    getters: {},
};
