<template>
    <div>
        <btn-x-scroll-container
            :containerId="'playlist-scroll-container'"
            :itemBaseId="itemBaseId"
            :itemQuerySelector="'.song-summary-item'"
            :maxItemCount="computedItemCount"
        >
            <template v-slot:content>
                <div
                    v-if="displayDurationWarning"
                    :id="`playlist-0`"
                    class="alert-wrapper song-summary-item"
                    style="text-wrap: wrap"
                >
                    <v-alert
                        @click="$emit('changeTab', 'playlist')"
                        outlined
                        text
                        color="warning"
                        class="d-flex align-items-center justify-content-center text-center"
                        style="height: 100%; width: 100%; cursor: pointer"
                    >
                        <div>Playlist shorter than slideshow!</div>
                        <div>
                            <font-awesome-icon
                                class="mt-2"
                                style="font-size: 1.2rem"
                                icon="fa-regular fa-circle-plus"
                            ></font-awesome-icon>
                        </div>
                    </v-alert>
                </div>
                <draggable
                    @change="handleChange"
                    v-model="selectedSongs"
                    :disabled="disableDragDrop"
                    animation="300"
                    class="d-flex"
                    style="gap: 20px"
                >
                    <div
                        :id="`${itemBaseId}${displayDurationWarning ? index + 1 : index}`"
                        class="song-wrapper song-summary-item"
                        v-for="(item, index) in selectedSongs"
                        :key="`${index} - ${refreshKey}`"
                    >
                        <custom-tooltip :tooltipProps="{ bottom: true }">
                            <template v-slot:activator>
                                <div :key="refreshKey" :class="['song-div', { 'has-album-cover': item.artwork }]">
                                    <v-img
                                        v-if="item.artwork"
                                        cover
                                        class="artwork"
                                        :src="item.artwork"
                                        :lazy-src="item.artwork"
                                    ></v-img>
                                    <tribute-music-player
                                        style="position: absolute"
                                        v-if="item.playableLink"
                                        :id="'trib-audio-' + item.id"
                                        class="tribAudioPlayer"
                                        :ref="'trib-audio-' + item.id"
                                        :playableLink="item.playableLink"
                                        :fontSize="'3rem'"
                                        @playing="audio => playingAudio(item, audio)"
                                    />
                                    <span class="timestamp">
                                        {{ item.duration | durationTimeStamp }}
                                    </span>
                                </div>
                                <strong style="font-size: 0.9rem" class="song-title">{{ item.title }}</strong>

                                <v-btn
                                    style="position: absolute"
                                    color="error"
                                    fab
                                    x-small
                                    class="quick-action-btn"
                                    @click="toggleSelectedSong(item)"
                                    >x</v-btn
                                >
                            </template>

                            <template v-slot:content>
                                <span
                                    ><font-awesome-icon icon="fa-regular fa-arrow-left"></font-awesome-icon>
                                    Drag to re-arrange
                                    <font-awesome-icon icon="fa-regular fa-arrow-right"></font-awesome-icon
                                ></span>
                            </template>
                        </custom-tooltip>
                    </div>
                </draggable>
            </template>
        </btn-x-scroll-container>
        <v-alert
            class="pointer"
            @click="$emit('changeTab', 'playlist')"
            v-if="selectedSongs.length == 0"
            dense
            outlined
            text
            type="warning"
        >
            <div>
                <strong>Music Selection Needed</strong>
            </div>
            <div>
                Looks like you haven't selected any music! We recommend selecting a minimum of
                {{ remainingSongs }} more {{ remainingSongs > 1 ? 'songs' : 'song' }} to continue.
            </div>
        </v-alert>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import TributeMusicPlayer from '@/components/ManageService/Tribute/TributeMusicPlayer.vue';
import BtnXScrollContainer from '@/components/ui/BtnXScrollContainer.vue';
import draggable from 'vuedraggable';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';

export default {
    data() {
        return {
            refreshKey: 0,
        };
    },
    components: {
        TributeMusicPlayer,
        BtnXScrollContainer,
        draggable,
        CustomTooltip,
    },
    watch: {
        selectedSongs() {
            this.refreshKey++;
        },
    },
    props: {
        displayDurationWarning: {
            type: Boolean,
            default: false,
        },
        itemBaseId: {
            type: String,
            required: true,
        },
        disableDragDrop: {
            type: Boolean,
            default: false,
        },
        token: {
            type: String,
            required: true,
        },
    },
    computed: {
        selectedSongs: {
            get() {
                return this.$store.state.tributeVideo.selectedSongs;
            },
            set(value) {
                this.updateTributeVideoSelectedSongs(value);
            },
        },
        playlistDuration() {
            let duration = 0;
            this.selectedSongs.forEach(song => {
                duration += song.duration;
            });
            return duration;
        },
        recommendedDuration() {
            return (this.tributeVideo.totalPhotos + 1) * this.tributeVideo.slideDuration;
        },
        remainingSongs() {
            const averageSongLength = 210;

            const remainingTime = this.recommendedDuration - this.playlistDuration;
            const remainingSongs = Math.round(remainingTime / averageSongLength);
            return remainingSongs ? remainingSongs : 1;
        },
        tributeVideo() {
            return this.$store.state.tributeVideo;
        },
        computedItemCount() {
            var count = this.selectedSongs.length;

            if (this.displayDurationWarning) {
                count++;
            }

            return count;
        },
    },
    filters: {
        durationTimeStamp(val) {
            var minutes = Math.floor(val / 60);
            var seconds = val - minutes * 60;

            function str_pad_left(string, pad, length) {
                return (new Array(length + 1).join(pad) + string).slice(-length);
            }
            const finalTime = minutes + ':' + str_pad_left(seconds, '0', 2);
            return finalTime;
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        ...mapActions('tributeVideo', ['updateTributeVideoSelectedSongs', 'updateTributeVideo']),
        playingAudio(item, audio) {
            const audioList = document.getElementsByClassName('tribAudioPlayer');

            for (let i = 0; i < audioList.length; i++) {
                if (audioList[i].id != `trib-audio-${item.id}`) {
                    let player = this.$refs[audioList[i].id][0];

                    if (player) {
                        player.stopAudio();
                    }
                }
            }
        },
        stopAllAudio() {
            const audioList = document.getElementsByClassName('tribAudioPlayer');

            for (let i = 0; i < audioList.length; i++) {
                let player = this.$refs[audioList[i].id][0];

                if (player) {
                    player.stopAudio();
                }
            }
        },
        async handleChange($event) {
            const selectedIds = this.selectedSongs.map(obj => obj.id);
            await this.updateSelectedSongs(this.tributeVideo.id, selectedIds);
            this.$emit('refresh-preview');
            this.$emit('refresh-sync');
        },
        updateSelectedSongs(id, ids) {
            let data = {
                selectedSongs: ids,
            };

            return this.axiosInstance
                .post(`TributeVideo/selected-songs/${id}`, data)
                .then(resp => {})
                .catch(err => {
                    console.log(err, 'template json error');
                });
        },
        async toggleSelectedSong(item) {
            const found = this.selectedSongs.find(x => x.id == item.id);
            var listClone = [...this.selectedSongs];
            if (found) {
                const indexToRemove = listClone.indexOf(found);
                listClone.splice(indexToRemove, 1);
                for (let i = indexToRemove; i < listClone.length; i++) {
                    listClone[i].order = i;
                }
            } else {
                listClone.push({ ...item, order: this.selectedSongs.length });
            }
            this.updateTributeVideoSelectedSongs(listClone);

            const selectedIds = listClone.map(obj => obj.id);
            await this.updateSelectedSongs(this.tributeVideo.id, selectedIds);
            if (found) {
                this.showSnackbar({ message: 'Song removed from playlist' });
            }
            this.$emit('refresh-sync');
            this.$emit('refresh-preview');
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        // handleFileSize(fileSize) {
        //     console.log(this.formatFileSize(fileSize), 'data loaded');
        // },
        // formatFileSize(fileSizeInBytes) {
        //     const kilobyte = 1024;
        //     const megabyte = kilobyte * 1024;
        //     const gigabyte = megabyte * 1024;

        //     if (fileSizeInBytes >= gigabyte) {
        //         return (fileSizeInBytes / gigabyte).toFixed(2) + ' GB';
        //     } else if (fileSizeInBytes >= megabyte) {
        //         return (fileSizeInBytes / megabyte).toFixed(2) + ' MB';
        //     } else if (fileSizeInBytes >= kilobyte) {
        //         return (fileSizeInBytes / kilobyte).toFixed(2) + ' KB';
        //     } else {
        //         return fileSizeInBytes + ' bytes';
        //     }
        // },
        getSelectedSongs(id) {
            this.axiosInstance
                .get(`TributeVideo/selected-songs/${id}`)
                .then(resp => {
                    if (resp.data) {
                        this.updateTributeVideoSelectedSongs(resp.data);
                    }
                })
                .catch(err => {
                    console.log(err, 'template json error');
                });
        },
    },

    async mounted() {
        // await this.setAuthToken();
        this.createAxiosInstance();
        this.getSelectedSongs(this.tributeVideo.id);
    },
    sockets: {
        NotifyTributeSongsUpdate(data) {
            if (data.tributeId == this.tributeVideo.id) {
                // this.$emit('refresh-sync');
                this.getSelectedSongs(this.tributeVideo.id);
            }
        },
    },
};
</script>
<style lang="scss">
.flex-overflow-container {
    overflow-x: auto;
    gap: 20px;
    padding: 10px 0;
}
.song-wrapper {
    position: relative;
    min-width: 200px;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: grab;
}
.alert-wrapper {
    position: relative;
    min-width: 200px;
    max-width: 200px;
    height: 100px;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    cursor: grab;
}
.artwork {
    position: absolute;
    width: 200px;
    height: 100px;
    max-height: 100%;
    max-width: 100%;
    border-radius: 5px;
    filter: opacity(70%);
}

.song-div {
    width: 200px;
    height: 100px;
    border-radius: 5px;
    // overflow: hidden;
    position: relative;
    background-color: #f8f8f8;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}
.quick-action-btn {
    position: absolute;
    z-index: 2;
    top: 5px;
    right: 5px;
    opacity: 0;
    height: 20px !important;
    width: 20px !important;
}

.quick-action-btn:hover {
    opacity: 1 !important;
}

.song-wrapper:hover .quick-action-btn {
    opacity: 0.4;
}

.song-title {
    flex-grow: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.timestamp {
    line-height: 1rem;
    position: absolute;
    bottom: 5px;
    right: 5px;
}

.has-album-cover {
    overflow: hidden;
    .player-icon {
        color: white;
    }

    .timestamp {
        color: white;
    }
}
</style>
