<template>
    <div>
        <div class="grid-container">
            <div v-if="loading" class="grid">
                <div v-for="num in 8" style="background-color: #f8f8f8" class="grid-item">
                    <v-skeleton-loader class="mx-auto" type="list-item-avatar-two-line"></v-skeleton-loader>
                </div>
            </div>

            <div class="grid">
                <div class="grid-item" v-for="song in songs">
                    <featured-audio-player
                        :ref="'audio-' + song.id"
                        :song="song"
                        :token="token"
                        @refresh-sync="$emit('refresh-sync')"
                        @playing="song => stopPlayingAudio(song)"
                    ></featured-audio-player>
                </div>
            </div>
        </div>
        <!-- <div class="mt-2 text-right">
            <v-btn small text @click="$emit('show-all-featured')">See More Picks</v-btn>
        </div> -->
    </div>
</template>
<script>
import FeaturedAudioPlayer from '@/components/ui/FeaturedAudioPlayer.vue';

export default {
    data() {
        return {
            songs: [],
            totalSongs: 0,
            loading: true,
        };
    },
    props: {
        token: {
            type: String,
            requried: true,
        },
    },
    components: {
        FeaturedAudioPlayer,
    },

    methods: {
        stopPlayingAudio(song = null) {
            const matchingRefs = Object.keys(this.$refs).filter(key => key.startsWith('audio-'));

            matchingRefs.forEach(ref => {
                const refId = ref.split('-')[1];
                const audioPlayer = this.$refs[ref][0];

                if (!song || (song && song.id.toString() !== refId)) {
                    audioPlayer.pauseAudio();
                }
            });
        },
        async getSongs(favoritesOnly = true) {
            this.loading = true;

            var params = {
                pageSize: 8,
                favoritesOnly: favoritesOnly,
            };

            return this.axiosInstance
                .get(`TributeVideoSong`, {
                    params: params,
                })
                .then(res => {
                    this.songs = res.data.tributeSongs;
                    this.totalSongs = res.data.total;
                    this.loading = false;
                })
                .catch(err => {
                    console.log(err, 'error');
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
    },
    async mounted() {
        this.createAxiosInstance();
        this.getSongs();
    },
};
</script>
<style lang="scss" scoped>
.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
    padding-top: 16px;
    position: relative;
}

.grid-item {
    border-radius: 8px;
    position: relative;
    border: 2px solid transparent;
    transition: 0.2s;
    width: 100%;
}

@media (max-width: 400px) {
    .grid {
        justify-items: center;
    }
    .grid-item {
        max-width: 200px;
    }
}
</style>
