<template>
    <div class="wrapper">
        <div :id="this.containerId"></div>
        <div class="horizontal-bar" :class="{ hidden: !ready }"></div>
    </div>
</template>
<script>
import WaveSurfer from 'wavesurfer.js';
export default {
    data() {
        return {
            wavesurfer: null,
            ready: false,
        };
    },
    components: {
        WaveSurfer,
    },
    props: {
        containerId: {
            type: String,
            required: true,
        },
        sourceUrl: {
            type: String,
            required: true,
        },
    },
    methods: {
        togglePlaying() {
            this.wavesurfer.playPause();
        },
        pauseAudio() {
            this.wavesurfer.pause();
            // const isPlaying = this.wavesurfer.isPlaying();
            // this.$emit('is-playing', isPlaying);
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.wavesurfer = WaveSurfer.create({
                container: `#${this.containerId}`,
                waveColor: '#91a6b6',
                progressColor: '#ff530d',
                cursorColor: 'rgba(255, 0, 0, 0)',
                url: this.sourceUrl,
                height: 20,
                barWidth: 2,
                barGap: 2,
                normalize: true,
                barRadius: 5,
            });

            this.wavesurfer.on('ready', () => {
                this.ready = true;
                this.$emit('ready');
            });
            this.wavesurfer.on('play', () => {
                this.$emit('playing');

                this.$emit('is-playing', true);
            });
            this.wavesurfer.on('pause', () => {
                this.$emit('is-playing', false);
            });
        });
    },
};
</script>
<style lang="scss" scoped>
.wrapper {
    position: relative;
}
.horizontal-bar {
    position: absolute;
    top: 50%;
    margin: auto;
    width: 100%;
    transform: translateY(50%);
    border-top: 1px solid #91a6b6;
}

.hidden {
    display: none !important;
}
</style>
