import Vue from 'vue';
import Vuex from 'vuex';
import tributeVideo from './modules/tribute-video';
import { getLocalSettings, setLocalSettings } from '../utilities/general';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        tributeVideo,
    },
    state: {
        loggedInUser: null,
        blocked: false, // UI displays a global loading spinner if true
        menuShowing: true,
        topNavShowing: true,
        UsersTableShowing: false,
        streaming: false,
        videoSrc: '',
        enablePreview: false,
        customLogo: '',
        whitelabel: false,
        customHTML: '',
        userSupport: false,
        displayTerms: true,
        impersonatingName: '',
        displayPromoBanner: false,
        displayKeywordReportNav: false,
        // tributeVideo: {
        //     id: null,
        //     eventId: null,
        //     loadingPhotos: false,
        //     photos: [],
        //     photosOptions: {
        //         pageNum: 0,
        //         pageSize: 5,
        //     },
        //     selectedPhotos: [],
        //     selectedTemplates: [],
        //     selectedSongs: [],
        //     age: null,
        //     deeplink: null,
        //     mainPhotoId: null,
        //     mainPhotoUrl: null,
        //     faceUuid: null,
        //     render: null,
        //     firstName: null,
        //     lastName: null,
        //     birthDate: null,
        //     deathDate: null,
        //     totalPhotos: 0,
        // },
        snackbar: {
            message: null,
            color: null,
            timeout: null,
            button: {
                text: null,
                color: null,
                callback: () => {},
            },
        },
    },
    mutations: {
        // updateTributeVideoPhotosOptions(state, payload) {
        //     state.tributeVideo.photosOptions = payload;
        // },
        // updateTributeVideoSelectedSongs(state, payload) {
        //     state.tributeVideo.selectedSongs = payload;
        // },
        // updateTributeVideoSelectedTemplates(state, payload) {
        //     state.tributeVideo.selectedTemplates = payload;
        // },
        // updateTributeVideoSelectedPhotos(state, payload) {
        //     state.tributeVideo.selectedPhotos = payload;
        // },
        // updateTributeVideoPhotos(state, payload) {
        //     state.tributeVideo.photos = payload;
        // },
        // updateTributeVideo(state, payload) {
        //     state.tributeVideo.id = payload.id ?? null;
        //     state.tributeVideo.eventId = payload.eventId ?? null;
        //     state.tributeVideo.loadingPhotos = payload.loadingPhotos ?? false;
        //     state.tributeVideo.age = payload.age ?? false;
        //     state.tributeVideo.deeplink = payload.deeplink ?? null;
        //     state.tributeVideo.mainPhotoId = payload.mainPhotoId ?? null;
        //     state.tributeVideo.mainPhotoUrl = payload.mainPhotoUrl ?? null;
        //     state.tributeVideo.faceUuid = payload.faceUuid ?? null;
        //     state.tributeVideo.render = payload.render ?? null;
        //     state.tributeVideo.firstName = payload.firstName ?? null;
        //     state.tributeVideo.lastName = payload.lastName ?? null;
        //     state.tributeVideo.birthDate = payload.birthDate ?? null;
        //     state.tributeVideo.deathDate = payload.deathDate ?? null;
        //     state.tributeVideo.totalPhotos = payload.totalPhotos ?? null;
        // },
        updateDisplayKeywordReportNav(state, payload) {
            state.displayKeywordReportNav = payload;
        },
        updateDisplayPromoBanner(state, payload) {
            state.displayPromoBanner = payload;
        },
        updateDisplayTerms(state, payload) {
            state.displayTerms = payload;
        },
        updateLoggedInUser(state, payload) {
            state.loggedInUser = payload;
        },
        updateImpersonatingName(state, payload) {
            state.impersonatingName = payload;
        },
        updateCustomHTML(state, payload) {
            state.customHTML = payload;
        },
        updateUserSupport(state, payload) {
            state.userSupport = payload;
        },
        updateCustomLogo(state, payload) {
            state.customLogo = payload;
        },
        updateWhitelabel(state, payload) {
            state.whitelabel = payload;
        },
        toggleBlock(state, payload) {
            state.blocked = payload;
        },
        toggleSideNav(state) {
            state.menuShowing = !state.menuShowing;

            if (state.menuShowing) {
                setLocalSettings('appSettings', { menuState: 'max' });
            } else {
                setLocalSettings('appSettings', { menuState: 'min' });
            }
        },
        toggleTopNav(state, payload) {
            state.topNavShowing = payload;
        },
        toggleUsersTable(state) {
            state.UsersTableShowing = !state.UsersTableShowing;
        },
        toggleStreaming(state, payload) {
            state.streaming = payload;
        },
        setVideoSrc(state, payload) {
            state.videoSrc = payload;
        },
        setEnablePreview(state, payload) {
            state.enablePreview = payload;
        },
        showSnackbar(state, { message = '', color = '', timeout = '2000', button = null }) {
            state.snackbar.message = message;
            state.snackbar.color = color;
            state.snackbar.timeout = timeout;
            const defaultCallback = () => {};
            if (button !== null) {
                state.snackbar.button.text = button.text || null;
                state.snackbar.button.color = button.color || null;
                state.snackbar.button.callback = button.callback || defaultCallback;
            } else {
                state.snackbar.button = null;
            }
        },
    },
    actions: {
        // updateTributeVideoPhotosOptions({ commit }, payload) {
        //     commit('updateTributeVideoPhotosOptions', payload);
        // },
        // updateTributeVideoSelectedSongs({ commit }, payload) {
        //     commit('updateTributeVideoSelectedSongs', payload);
        // },
        // updateTributeVideoSelectedTemplates({ commit }, payload) {
        //     commit('updateTributeVideoSelectedTemplates', payload);
        // },
        // updateTributeVideoSelectedPhotos({ commit }, payload) {
        //     commit('updateTributeVideoSelectedPhotos', payload);
        // },
        // updateTributeVideoPhotos({ commit }, payload) {
        //     commit('updateTributeVideoPhotos', payload);
        // },
        // updateTributeVideo({ commit }, payload) {
        //     commit('updateTributeVideo', payload);
        // },
        updateDisplayKeywordReportNav({ commit }, payload) {
            commit('updateDisplayKeywordReportNav', payload);
        },
        updateDisplayPromoBanner({ commit }, payload) {
            commit('updateDisplayPromoBanner', payload);
        },
        updateDisplayTerms({ commit }, payload) {
            commit('updateDisplayTerms', payload);
        },
        updateLoggedInUser({ commit }, payload) {
            commit('updateLoggedInUser', payload);
        },
        updateImpersonatingName({ commit }, payload) {
            commit('updateImpersonatingName', payload);
        },
        updateCustomHTML({ commit }, payload) {
            commit('updateCustomHTML', payload);
        },
        updateUserSupport({ commit }, payload) {
            commit('updateUserSupport', payload);
        },
        updateCustomLogo({ commit }, payload) {
            commit('updateCustomLogo', payload);
        },
        updateWhitelabel({ commit }, payload) {
            commit('updateWhitelabel', payload);
        },
        toggleSideNav(context) {
            context.commit('toggleSideNav');
        },
        toggleTopNav(context, payload) {
            context.commit('toggleTopNav', payload);
        },
        toggleUsersTable(context) {
            context.commit('toggleUsersTable');
        },
        toggleStreaming(context, payload) {
            context.commit('toggleStreaming', payload);
        },
        setVideoSrc(context, payload) {
            context.commit('setVideoSrc', payload);
        },
        setVideoSrc(context, payload) {
            context.commit('setVideoSrc', payload);
        },
        setEnablePreview(context, payload) {
            context.commit('setEnablePreview', payload);
        },
        showSnackbar({ commit }, payload) {
            commit('showSnackbar', payload);
        },
        block(context, payload) {
            context.commit('toggleBlock', payload);
        },
    },
});
