var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('btn-x-scroll-container',{attrs:{"containerId":'photo-scroll-container',"itemBaseId":'photo-',"itemQuerySelector":'.image-wrapper',"maxItemCount":this.tributeVideo.totalPhotos - 1},on:{"scroll-end-reached":_vm.handleScrollEndReached},scopedSlots:_vm._u([{key:"content",fn:function(){return [(
                    !_vm.tributeVideo.selectedTemplates[0] ||
                    (_vm.tributeVideo.selectedTemplates[0] && _vm.tributeVideo.selectedTemplates[0].title != 'No Theme')
                )?_c('div',{staticClass:"not-draggable",attrs:{"id":"photo-0"}},[_c('div',{staticClass:"image-wrapper"},[(_vm.tributeVideo.selectedTemplates[0])?_c('v-img',{staticClass:"image-base",attrs:{"src":_vm.handleThumbnail(_vm.tributeVideo.selectedTemplates[0].url),"lazy-src":_vm.handleThumbnail(_vm.tributeVideo.selectedTemplates[0].url),"eager":""}}):_c('v-alert',{staticClass:"d-flex align-items-center justify-content-center text-center",staticStyle:{"min-height":"100%","width":"100%","cursor":"pointer"},attrs:{"outlined":"","text":"","color":"warning"},on:{"click":function($event){return _vm.$emit('changeTab', 'intro')}}},[_c('div',[_vm._v("No theme selected!")]),_c('div',[_c('font-awesome-icon',{staticClass:"mt-2",staticStyle:{"font-size":"1.2rem"},attrs:{"icon":"fa-regular fa-circle-plus"}})],1)])],1)]):_vm._e(),_c('draggable',{staticClass:"d-flex",staticStyle:{"gap":"20px"},attrs:{"filter":'.not-draggable',"animation":"300"},on:{"change":_vm.handleChange},model:{value:(_vm.photos),callback:function ($$v) {_vm.photos=$$v},expression:"photos"}},[_vm._l((_vm.photos),function(item,index){return _c('div',{attrs:{"id":("photo-" + (_vm.tributeVideo.selectedTemplates[0] ? index + 1 : index))}},[_c('div',{staticClass:"image-wrapper draggable"},[(item.mediaType == 1)?_c('div',{staticStyle:{"height":"100%","object-fit":"cover","position":"relative"}},[_c('video',{staticClass:"gallery-video image-wrapper",staticStyle:{"object-fit":"cover","min-width":"100%","max-width":"100%","border-radius":"5px"},attrs:{"src":item.url}}),_c('v-chip',{staticClass:"px-2 video-tag",attrs:{"x-small":"","dark":"","color":"#ab0658"}},[_vm._v("VIDEO")])],1):_c('v-img',{staticClass:"image-base",attrs:{"src":item.mediaType === 0 || item.mediaType === 2
                                    ? _vm.handleImgSrc(item.url)
                                    : _vm.handleThumbnail(item.url),"lazy-src":item.mediaType === 0 || item.mediaType === 2
                                    ? _vm.handleImgSrc(item.url)
                                    : _vm.handleThumbnail(item.url),"eager":"","id":("photo-" + (item.id))}})],1)])}),_c('div',{staticStyle:{"width":"10px","border":"2px solid rgba(64, 64, 64, 0)"},attrs:{"id":"photo-summary-scroll"}})],2)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }